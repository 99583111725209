import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import type { SortDirection, SortParams, SortState } from './types.ts'

export const useSortQuery = <SortKey extends string, SortDir extends SortDirection>() => {
  const route = useRoute()
  const router = useRouter()

  const sortBy = ref(route.query.sortBy as SortKey | undefined)
  const sortDirection = ref(route.query.sortDirection as SortDir | undefined)

  watch(
    () => route.query,
    () => {
      sortBy.value = route.query.sortBy as SortKey | undefined
      sortDirection.value = route.query.sortDirection as SortDir | undefined
    },
  )

  function setSort(params: SortParams<SortKey, SortDir>) {
    const query = { ...route.query, sortBy: params.sortBy, sortDirection: params.sortDirection }
    router.push({ query })
  }

  function getSortState(key: SortKey): SortState<SortKey, SortDir> {
    let direction
    if (sortBy.value && sortBy.value === key) {
      direction = sortDirection.value
    }

    return {
      active: sortBy.value === key,
      sortBy: key,
      sortDirection: direction,
    }
  }

  return {
    sortBy,
    sortDirection,
    setSort,
    getSortState,
  }
}
