import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'

import { myOmrGraphqlUrl } from '@/config/env'

import { uploadFetch } from './uploadFetch'

const myomrHttpLink = createUploadLink({
  credentials: 'include',
  fetch: uploadFetch,
  uri: myOmrGraphqlUrl,
})

export default myomrHttpLink
