import { mdiFish, mdiFoodDrumstick, mdiLeaf, mdiSprout } from '@mdi/js'

import { BoothFoodTypeEnum } from '@/gql/myomr'

export default function getFoodTypeIcon(foodType: BoothFoodTypeEnum) {
  switch (foodType) {
    case BoothFoodTypeEnum.Fish:
      return mdiFish
    case BoothFoodTypeEnum.Meat:
      return mdiFoodDrumstick
    case BoothFoodTypeEnum.Vegan:
      return mdiLeaf
    case BoothFoodTypeEnum.Veggie:
      return mdiSprout
  }
}
