<template>
  <div
    v-if="uiStore.notifications.length"
    class="fixed bottom-4 left-1/2 transform -translate-x-1/2 tablet:max-w-[80%] pointer-events-none z-[1000]"
  >
    <MessageBanner
      v-for="notification in uiStore.notifications"
      :key="notification.id"
      role="alert"
      :type="notification.type"
      :headline="notification.headline"
      :message="notification.message"
    />
  </div>
</template>

<script setup lang="ts">
import { MessageBanner } from '@ramp106/omrjs-core-ui'

import { useUiStore } from '@/stores'

const uiStore = useUiStore()
</script>
