<template>
  <ConfirmModal persist hide-actions @close-dialog="closeModal">
    <template #title>
      <h1 class="text-headline-m p-6 pb-5">{{ typed_t('ticketForm.editTicketModal') }}</h1>
    </template>
    <template #content>
      <TicketForm
        :invitation-quota="props.invitationQuota"
        :ticket="props.ticket"
        :error-message="errorMessage"
        @close-dialog="closeModal"
        @submit-form="submitForm"
      />
    </template>
  </ConfirmModal>
</template>

<script setup lang="ts">
import { captureException } from '@sentry/vue'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { useUpdateTicketMutation, TicketEvent } from '@/gql/myomr'
import type { TicketQuery, InvitationQuotaQuery, DirectTicketAttributes } from '@/gql/myomr'
import { injectStrict } from '@/helpers/injectStrict'
import { showNotification } from '@/helpers/notificationHelper'
import typed_translation from '@/helpers/typed_translation'
import { reloadQuotaKey } from '@/symbols/reloadQuotaKey'
import { reloadTicketsKey } from '@/symbols/reloadTicketsKey'

const props = defineProps<{
  invitationQuota: InvitationQuotaQuery['invitationQuota']
  ticket: Ticket
}>()

const { t } = useI18n()
const typed_t = typed_translation('InvitationQuota', props.invitationQuota.kind)
const route = useRoute()
const router = useRouter()

const reloadQuota = injectStrict(reloadQuotaKey)
const reloadTickets = injectStrict(reloadTicketsKey)

const { mutate: updateTicket } = useUpdateTicketMutation()
const errorMessage = ref('')

type Ticket = NonNullable<TicketQuery['ticket']>

const closeModal = () => {
  router.push({ name: 'invitationQuota', query: route.query })
}

const submitForm = async function (formData: DirectTicketAttributes) {
  updateTicket({
    ticketId: props.ticket.id,
    ticketCreateAttributes: formData,
    events: [TicketEvent.Complete],
  })
    .then((response) => {
      if (response?.data?.updateDirectTicket?.errors?.length) {
        errorMessage.value = response.data.updateDirectTicket.errors.join(', ')
        showNotification(t('InvitationQuota.CrewQuota.unsuccessfulUpdateNotification'), 'error', 3000)
      } else {
        errorMessage.value = ''
        reloadQuota()
        reloadTickets()
        closeModal()
        showNotification(t('InvitationQuota.CrewQuota.successfulTicketUpdate'), 'success', 3000)
      }
    })
    .catch((e) => {
      captureException(e)
      showNotification(t('InvitationQuota.CrewQuota.unsuccessfulUpdateNotification'), 'error', 3000)
    })
}
</script>
