<template>
  <div class="timeline-clicker">
    <div class="video-wrapper">
      <div v-if="!videoPlaying" class="play">
        {{ t('EventForm.timelineClicker.play') }}
      </div>
      <video ref="video" :src="src" @click="togglePlayback"></video>
    </div>

    <div class="timeline-clicker__timeline" @click="moveToPosition">
      <div ref="timeline" class="timeline-clicker__timeline__line"></div>
      <div class="timeline-clicker__timeline__handle" :style="{ left: (videoCurrentTime / videoDuration) * 100 + '%' }"></div>
      <div class="timeline-clicker__timeline__timestamps">
        <div
          v-for="(marker, index) in agendaMarkers"
          :key="`agenda-${index}`"
          class="timeline-clicker__timeline__timestamps-marker timeline-clicker__timeline__timestamps-marker--top"
          :class="{
            active: selectedAgendaMarkers[0] && selectedAgendaMarkers[0].time == marker.time,
          }"
          :style="{ left: (marker.time / videoDuration) * 100 + '%' }"
          @click.stop="selectAgendaMarker({ item: marker })"
        >
          {{ Number(index) + 1 }}
        </div>
      </div>
      <div class="timeline-clicker__timeline__timestamps">
        <div
          v-for="(marker, index) in presentationMarkers"
          :key="`presentation-${index}`"
          class="timeline-clicker__timeline__timestamps-marker timeline-clicker__timeline__timestamps-marker--bottom"
          :class="{
            active: selectedPresentationMarkers[0] && selectedPresentationMarkers[0].time == marker.time,
          }"
          :style="{ left: (marker.time / videoDuration) * 100 + '%' }"
          @click.stop="selectPresentationMarker({ item: marker })"
        >
          {{ Number(index) + 1 }}
        </div>
      </div>
    </div>
    <div class="timeline-clicker__controls">
      <div>
        <TextInput v-model="videoCurrentTimeInput" @keydown.enter="setVideoTime" />
      </div>
      <template v-if="selectedAgendaMarkers.length == 0 && selectedPresentationMarkers.length == 0">
        <button class="btn-primary-purple-m" @click="createAgendaMarker">{{ t('EventForm.timelineClicker.createAgendaTimestamp') }}</button>
        <button class="btn-primary-purple-m" @click="createPresentationMarker">
          {{ t('EventForm.timelineClicker.createPresentationTimestamp') }}
        </button>
      </template>
      <button v-else class="btn-primary-purple-m" @click="moveMarker">{{ t('EventForm.timelineClicker.moveTimestamp') }}</button>
    </div>
    <div class="timeline-clicker__list">
      <h3>{{ t('EventForm.timelineClicker.agendaLabel') }}</h3>
      <TableLight class="mt-6 mb-6 w-full">
        <thead>
          <tr>
            <th class="text-start"></th>
            <th class="text-start">Number</th>
            <th class="text-start">Time</th>
            <th class="text-start">Name</th>
            <th class="text-start">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in agendaMarkers" :key="'agenda-' + index">
            <td>
              <input v-model="selectedAgendaMarkers" type="checkbox" :value="item" />
            </td>
            <td>{{ agendaMarkers.map((x) => x.time).indexOf(item.time) + 1 }}</td>
            <td>{{ formatTime(item.time) }}</td>
            <td>
              <TextInput
                v-model="item.text"
                class="list-text"
                @focus="selectAgendaMarker({ item, select: true })"
                @update:model-value="save"
              />
            </td>
            <td>
              <button class="btn-ghost-purple-m" @click="deleteAgendaTimestamp(item)">
                <SvgIcon :path="mdiDelete" :size="24" />
              </button>
            </td>
          </tr>
        </tbody>
      </TableLight>
      <h3 class="mt-2">
        {{ t('EventForm.timelineClicker.presentationLabel') }}
      </h3>
      <TableLight class="mt-6 mb-6 w-full">
        <thead>
          <tr>
            <th class="text-start"></th>
            <th class="text-start">Number</th>
            <th class="text-start">Time</th>
            <th class="text-start">Name</th>
            <th class="text-start">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in presentationMarkers" :key="'presentation-' + index">
            <td>
              <input v-model="selectedPresentationMarkers" type="checkbox" :value="item" />
            </td>
            <td>{{ presentationMarkers.map((x) => x.time).indexOf(item.time) + 1 }}</td>
            <td>{{ formatTime(item.time) }}</td>
            <td>
              <TextInput
                :v-model="item.text"
                class="list-text"
                @focus="selectPresentationMarker({ item, select: true })"
                @update:model-value="save"
              />
            </td>
            <td>
              <button class="btn-text-purple-m" @click="deletePresentationTimestamp(item)">
                <SvgIcon :path="mdiDelete" :size="24" />
              </button>
            </td>
          </tr>
        </tbody>
      </TableLight>
    </div>
  </div>
</template>

<script setup lang="ts">
import { mdiDelete } from '@mdi/js'
import { TextInput } from '@ramp106/omrjs-core-ui'
import { ref, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const toSeconds = (m: string) => {
  const parts = m.split(':')
  if (parts.length == 2) {
    return parseInt(parts[0]) * 60 + parseInt(parts[1])
  }
  if (parts.length == 3) {
    return parseInt(parts[0]) * 60 * 60 + parseInt(parts[1]) * 60 + parseInt(parts[2])
  }
}
const padZero = (number: number): string => {
  if (number < 10) return `0${number}`
  else return `${number}`
}
const toMinutes = (timecode: number): string => {
  const hours = Math.floor(timecode / 60 / 60)
  const minutes = padZero(Math.floor(timecode / 60))
  const seconds = padZero(Math.floor(timecode % 60))
  return `${hours}:${minutes}:${seconds}`
}
type Marker = { time: number; text: string }

const props = defineProps<{
  agendaTimestamps: string
  presentationTimestamps: string
  src: string
}>()

const emit = defineEmits<{
  (e: 'updateAgenda', value: string): void
  (e: 'updatePresentation', value: string): void
}>()

const videoPlaying = ref(false)
const agendaMarkers = ref<Marker[]>([])
const presentationMarkers = ref<Marker[]>([])
const videoDuration = ref(0)
const videoCurrentTime = ref(0)
const videoCurrentTimeInput = ref('0:00:00')
const video = ref<HTMLVideoElement>()
const timeline = ref<HTMLElement>()
const selectedAgendaMarkers = ref<{ time: string | number; text: string }[]>([])
const selectedPresentationMarkers = ref<{ time: string | number; text: string }[]>([])

onMounted(() => {
  parseTimestamps()
})

watch(
  () => [props.agendaTimestamps, props.presentationTimestamps],
  () => parseTimestamps(),
)

function parseTimestamps() {
  agendaMarkers.value = JSON.parse(props.agendaTimestamps).map((b: { time: string | number; text: string }) => {
    b.time = toSeconds(b.time as string) || 0
    return b
  })
  presentationMarkers.value = JSON.parse(props.presentationTimestamps).map((b: { time: string | number; text: string }) => {
    b.time = toSeconds(b.time as string) || 0
    return b
  })

  video.value?.addEventListener('loadedmetadata', () => {
    videoDuration.value = video.value?.duration || 0
  })
  video.value?.addEventListener('timeupdate', () => {
    videoCurrentTime.value = video.value?.currentTime || 0
    videoCurrentTimeInput.value = formatTime(video.value?.currentTime || 0)
  })
}

function save() {
  emit(
    'updateAgenda',
    JSON.stringify(
      agendaMarkers.value.map((b) => {
        return { ...b, time: toMinutes(b.time) }
      }),
    ),
  )
  emit(
    'updatePresentation',
    JSON.stringify(
      presentationMarkers.value.map((b) => {
        return { ...b, time: toMinutes(b.time) }
      }),
    ),
  )
}
function togglePlayback() {
  if (videoPlaying.value) {
    pauseVideo()
  } else {
    playVideo()
  }
}
function playVideo() {
  video.value?.play()
  videoPlaying.value = true
}

function pauseVideo() {
  video.value?.pause()
  videoPlaying.value = false
}

function moveToPosition(e: { offsetX: number }) {
  if (!timeline.value) return

  const percent = e.offsetX / timeline.value?.offsetWidth
  const videoRef = video.value
  if (videoRef) videoRef.currentTime = percent * videoDuration.value
}

function selectAgendaMarker({ item, select = false }: { item: Marker; select?: boolean }) {
  if (!item) return
  // delselect presentation markers
  selectedPresentationMarkers.value = []
  if (selectedAgendaMarkers.value[0] == undefined || selectedAgendaMarkers.value[0].time !== item.time) {
    // if its not currently selected select it
    const videoRef = video.value
    if (videoRef) videoRef.currentTime = item.time
    selectedAgendaMarkers.value = [item]
  } else if (!select) {
    // toggle selection
    selectedAgendaMarkers.value = []
  }
}
function selectPresentationMarker({ item, select = false }: { item: Marker; select?: boolean }) {
  if (!item) return
  // deselect
  selectedAgendaMarkers.value = []
  // is it currently selected?
  if (selectedPresentationMarkers.value[0] == undefined || selectedPresentationMarkers.value[0].time !== item.time) {
    ;(video.value as HTMLVideoElement).currentTime = Number(item.time)
    selectedPresentationMarkers.value = [item]
  } else if (!select) {
    selectedPresentationMarkers.value = []
  }
}
function createAgendaMarker() {
  if (!video.value) return
  // check if a marker at that time exists
  if (!agendaMarkers.value.find((b) => video.value && b.time == video.value.currentTime)) {
    const marker = { time: video.value.currentTime, text: '' }
    agendaMarkers.value = agendaMarkers.value.concat([marker])
    sortMarkers()
    save()
  }
}
function deleteAgendaTimestamp(item: Marker) {
  selectedAgendaMarkers.value = []
  agendaMarkers.value = agendaMarkers.value.filter((marker) => marker.time != item.time)
  sortMarkers()
  save()
}
function createPresentationMarker() {
  if (!video.value) return
  const marker = { time: video.value.currentTime, text: '' }
  presentationMarkers.value = presentationMarkers.value.concat([marker])
  sortMarkers()
  save()
}
function deletePresentationTimestamp(item: Marker) {
  selectedPresentationMarkers.value = []
  presentationMarkers.value = presentationMarkers.value.filter((marker) => marker.time !== item.time)
  save()
}
function sortMarkers() {
  agendaMarkers.value = agendaMarkers.value.sort((a, b) => a.time - b.time)
  presentationMarkers.value = presentationMarkers.value.sort((a, b) => a.time - b.time)
}
function formatTime(timecode: number): string {
  return toMinutes(timecode)
}

function setVideoTime() {
  const s = Number(videoCurrentTimeInput.value.split(':')[2])
  const m = Number(videoCurrentTimeInput.value.split(':')[1])
  const h = Number(videoCurrentTimeInput.value.split(':')[0])
  const timecode = s + m * 60 + h * 60 * 60
  if (video.value) video.value.currentTime = timecode
}

function moveMarker() {
  if (selectedAgendaMarkers.value[0] !== undefined) {
    const marker = agendaMarkers.value.find((b) => b.time == selectedAgendaMarkers.value[0].time)
    if (marker && video.value) marker.time = video.value.currentTime
  } else if (selectedPresentationMarkers.value[0] !== undefined) {
    const marker = presentationMarkers.value.find((b) => b.time == selectedPresentationMarkers.value[0].time)
    if (marker && video.value) marker.time = video.value.currentTime
  }
  sortMarkers()
  save()
}
</script>
<style scoped>
.video-wrapper {
  position: relative;
}

.play {
  position: absolute;
  z-index: 1;
  color: white;
  background: black;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 3px;
  opacity: 0.8;
  pointer-events: none;
}

video {
  cursor: pointer;
  width: 100%;
  position: relative;
}

.timeline-clicker__timeline {
  width: 100%;
  position: relative;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.timeline-clicker__timeline__line {
  width: 100%;
  height: 3px;
  background: black;
}

.timeline-clicker__timeline__handle {
  pointer-events: none;
  width: 10px;
  height: 17px;
  background: white;
  z-index: 5;
  position: absolute;
  top: 11px;
  transform: translate(-50%, -50%);
  border: 3px solid black;
  transition: all 0.2s ease-in-out;
}

.timeline-clicker__timeline__timestamps {
  height: 30px;
  position: absolute;
  width: 100%;
}

.timeline-clicker__timeline__timestamps-marker {
  position: absolute;
  z-index: 4;
  background: white;
  color: black;
  border: solid 3px black;
  border-radius: 50%;
  width: 30px;
  text-align: center;
  z-index: 1;
  transition: ease-in-out 0.2s all;
  cursor: pointer;
  user-select: none;
}

.timeline-clicker__timeline__timestamps-marker.active {
  border-color: #e91561;
}

.timeline-clicker__timeline__timestamps-marker--top {
  top: -100%;
  transform-origin: bottom center;
  transform: scale(0.7) translateX(-50%) translateY(-9px);
}

.timeline-clicker__timeline__timestamps-marker--top:hover {
  transform: scale(1) translateX(-10px) translateY(-7px);
}

.timeline-clicker__timeline__timestamps-marker--top::before {
  transition: ease-in-out 0.2s all;
  width: 0px;
  height: 0px;
  position: absolute;
  transform: translate(0%, -90%);
  left: 0;
  top: calc(100% + 18px);
  z-index: 0;
  border: 12px solid transparent;
  content: '';
  border-top-color: black;
  pointer-events: none;
}

.timeline-clicker__timeline__timestamps-marker--top.active::before {
  border-top-color: #e91561;
}

.timeline-clicker__timeline__timestamps-marker--bottom {
  transform-origin: top center;
  transform: scale(0.7) translateX(-50%) translateY(9px);
}

.timeline-clicker__timeline__timestamps-marker--bottom:hover {
  transform: scale(1) translateX(-10px) translateY(7px);
}

.timeline-clicker__timeline__timestamps-marker--bottom::before {
  transition: ease-in-out 0.2s all;
  pointer-events: none;
  width: 0px;
  height: 0px;
  position: absolute;
  transform: translate(0%, -90%);
  left: 0;
  top: 0;
  z-index: 0;
  border: 12px solid transparent;
  content: '';
  border-bottom-color: black;
}

.timeline-clicker__timeline__timestamps-marker--bottom.active::before {
  border-bottom-color: #e91561;
}

.timeline-clicker__controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timeline-clicker__list {
  margin-top: 10px;
}

.timeline-clicker__list li {
  display: grid;
  grid-template-columns: 50px 100px auto 60px;
  grid-template-areas: 'number time text delete';
  border: 2px solid transparent;
  border-radius: 3px;
  margin-bottom: 3px;
}

.timeline-clicker__list li.active {
  border: 2px solid black;
}

.timeline-clicker__list .list-number {
  grid-area: number;
  padding-top: 4px;
  margin-top: 4px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.timeline-clicker__list .list-time {
  grid-area: time;
  padding-top: 4px;
  margin-top: 4px;
  cursor: pointer;
  user-select: none;
  text-align: center;
}

.timeline-clicker__list .list-text {
  grid-area: text;
  padding-top: 0;
}

.timeline-clicker__list .list-delete {
  grid-area: delete;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
