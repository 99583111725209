<template>
  <div>
    <h1 class="mt-16">{{ t('BoothExtras.title') }}</h1>

    <ContentCard class="mt-6 pb-6">
      <template #header>
        <div class="my-6 flex flex-row justify-between">
          <h3>
            {{ t('BoothExtras.extrasDue', { dueDate }) }}
          </h3>

          <RouterLink
            v-if="props.booth.extras && props.booth.extras.length && canModifyExtras"
            :to="{ name: 'extras', params: { id: props.booth.id } }"
            class="btn-primary-purple-m text-white"
          >
            {{ props.booth.extras.length ? t('BoothExtras.editCTA') : t('BoothExtras.bookCTA') }}
          </RouterLink>
        </div>
      </template>

      <div v-if="props.booth.extras && props.booth.extras.length">
        <TableLight>
          <thead>
            <tr>
              <th class="text-left" width="50%">
                {{ t('BoothExtras.item') }}
              </th>
              <th class="text-right" width="20%">
                {{ t('BoothExtras.pricePerItem') }}
              </th>
              <th class="text-right" width="20%">
                {{ t('BoothExtras.amount') }}
              </th>
              <th class="text-right" width="10%">
                {{ t('BoothExtras.total') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="extra in extras" :key="extra.productId">
              <td class="text-left">
                <strong>{{ extra.name }}</strong>
                <RouterLink v-if="extra.fileName" class="p-0 ml-5 btn-ghost-purple-m" :to="extra.fileUrl" target="_blank">
                  <SvgIcon :path="mdiAttachment" :size="24" class="mr-2" />
                  {{ extra.fileName }}
                </RouterLink>

                <button v-if="extra.comment?.length" v-tooltip="extra.comment">
                  <SvgIcon :path="mdiComment" :size="24" class="ml-2" />
                </button>
              </td>
              <td class="text-right">{{ formatPrice(extra.price) }}</td>
              <td class="text-right">{{ extra.count }}</td>
              <td class="text-right">
                <strong>{{ formatPrice(extra.total) }}</strong>
              </td>
            </tr>

            <tr v-if="deliveryFee">
              <td class="text-left">
                {{ t('BoothExtras.deliveryFeeLineItem') }}
              </td>
              <td class="text-right">{{ formatPrice(deliveryFee) }}</td>
              <td class="text-right">1</td>
              <td class="text-right">{{ formatPrice(deliveryFee) }}</td>
            </tr>
            <tr>
              <td colspan="3" class="text-right">
                <strong>{{ t('BoothExtras.netoSum') }}</strong>
              </td>
              <td class="text-right primary--text larger-size">
                <strong>{{ formatPrice(totalPrice) }}</strong>
              </td>
            </tr>
          </tbody>
        </TableLight>
      </div>

      <div v-else class="text-center p-12">
        <template v-if="canModifyExtras">
          <p>
            <b>{{ t('BoothExtras.bookExtrasTitle') }}</b>
          </p>
          <p class="no-extras-paragraph ma-auto pt-5">
            {{ t('BoothExtras.bookExtrasBody', { dueDate }) }}
          </p>

          <RouterLink class="mt-5 btn-primary-mint-m text-white" :to="{ name: 'extras', params: { id: props.booth.id } }">
            {{ t('BoothExtras.bookCTA') }}
          </RouterLink>
        </template>
      </div>
    </ContentCard>
  </div>
</template>

<script setup lang="ts">
import { mdiAttachment, mdiComment } from '@mdi/js'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import type { BoothQuery } from '@/gql/myomr'
import { formatPrice } from '@/helpers/moneyHelper'
import { TableLight } from '@/ui/TableLight'

const { d, t } = useI18n()

const props = defineProps<{
  booth: Extract<BoothQuery['node'], { __typename: 'Booth' }>
}>()

const extras = computed(() => {
  if (!props.booth.extras) {
    return []
  }

  return (
    props.booth.extras
      .map((extra) => {
        const product = props.booth.products.find((product) => product.id === extra.productId)

        if (product) {
          return {
            productId: product.id,
            name: product.name,
            count: extra.count,
            price: extra.price,
            comment: extra.comment,
            deliveryFee: extra.deliveryFee,
            total: extra.count * extra.price,
            fileName: extra.fileName,
            fileUrl: extra.fileUrl,
          }
        } else {
          return null
        }
      })
      // TODO: Fix this
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .filter((extra) => extra) as any[]
  )
})

const deliveryFee = computed(() => {
  const fees = extras.value.map((extra) => extra.deliveryFee).filter((fee) => fee) as number[]

  return fees.reduce((sum, fee) => {
    return sum + fee
  }, 0)
})

const totalPrice = computed(() => {
  if (!extras.value) {
    return 0
  }

  return (
    extras.value.reduce((sum, extra) => {
      if (extra) {
        return sum + extra.count * extra.price
      }
      return sum
    }, 0) + deliveryFee.value
  )
})

const canModifyExtras = computed(() => {
  const date = props.booth.extrasDueDate

  return date && new Date() < new Date(date)
})

const dueDate = computed(() => {
  if (props.booth.extrasDueDate) {
    return d(props.booth.extrasDueDate, 'dateShort')
  }

  return null
})
</script>

<style lang="css" scoped>
.no-extras-paragraph {
  max-width: 600px;
}

.larger-size {
  font-size: 1.25em;
}
</style>
