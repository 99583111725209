<template>
  <ConfirmModal persist :title="title" :show-approve="false" @close-dialog="closedModal">
    <template #content>
      <div class="px-6 pb-4" v-html="bodyHtml"></div>
    </template>
  </ConfirmModal>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import type { News } from '@/gql/myomr'
import { getLocalizedAttribute } from '@/helpers/localizedAttribute'

const i18n = useI18n()

const props = defineProps<{
  newsItem: News
}>()

const emit = defineEmits(['closedModal'])

function closedModal() {
  return emit('closedModal')
}

const title = computed(() =>
  getLocalizedAttribute({
    de: props.newsItem.titleDe,
    en: props.newsItem.titleEn,
    fallback: '',
    locale: i18n.locale.value,
  }),
)

const bodyHtml = computed(() =>
  getLocalizedAttribute({
    de: props.newsItem.bodyHtmlDe,
    en: props.newsItem.bodyHtmlEn,
    fallback: '',
    locale: i18n.locale.value,
  }),
)
</script>
