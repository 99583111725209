<template>
  <div>
    <BackLink class="mb-4" :to="{ name: 'userList' }">{{ t('backToOverview') }}</BackLink>

    <UserForm
      :header="String(t('UserManagement.createUserHeader'))"
      :submit-label="String(t('UserManagement.CreateUser.CTAs.create'))"
      :user="{ email: '', roles: [] }"
      @submit="showDialog = true"
    >
      <template #submit="{ email, roleNames }">
        <CreateUserModal
          v-if="showDialog"
          :email="email"
          :role-names="roleNames"
          @close-modal="showDialog = false"
          @send-invitation="handleSendInvitation"
        />
      </template>
    </UserForm>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { type CreateUserMutationVariables, useCreateUserMutation } from '@/gql/myomr'
import { showNotification } from '@/helpers/notificationHelper'
import BackLink from '@/ui/BackLink.vue'

import UserForm from './UserForm.vue'

const router = useRouter()
const { t } = useI18n()

defineProps<{
  loading: boolean
}>()
const emit = defineEmits(['reloadUsers'])

const showDialog = ref(false)

const { mutate: createUserMutation } = useCreateUserMutation()

function handleSendInvitation({ email, roleNames, personalMessage }: CreateUserMutationVariables) {
  createUser({ email, roleNames, personalMessage })
}

const createUser = ({ email, roleNames, personalMessage }: CreateUserMutationVariables) => {
  createUserMutation({
    email: email,
    personalMessage: personalMessage,
    roleNames: roleNames,
  }).then((result) => {
    const errors = result?.data?.createUser?.errors || []
    if (errors.length) {
      showNotification(errors.join(), 'error')
      showDialog.value = false
    } else {
      showNotification(
        t('UserManagement.Notifications.successMessage', {
          email: result?.data?.createUser?.user?.email,
        }),
        'success',
      )
      reset()
      emit('reloadUsers')
      router.push({ name: 'userList' })
    }
  })
}

const reset = () => {
  showDialog.value = false
}
</script>

<style scoped>
.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
</style>
