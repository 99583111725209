<template>
  <div class="bg-white p-5">
    <p class="text-headline-m">
      {{ t('InvitationQuota.CSVUpload') }}
    </p>
    <p class="my-3 opacity-75">
      {{ t('EmailInvitationQuota.upload.body') }}
    </p>
    <div>
      <DropDown wrapper-class="w-full">
        <template #activator="{ open }">
          <button class="btn-secondary-purple-m uppercase mr-6" @click.prevent="open">
            <SvgIcon :path="mdiDownload" :size="18" class="mr-2" />
            {{ t('EmailInvitationQuota.upload.downloadTemplate') }}
          </button>
        </template>
        <template #default>
          <ul class="list-none p-0 m-0 divide-solid divide-y divide-grey-300">
            <li v-for="format in exportFormats" :key="`format-${format}`" class="text-left py-2 px-2 cursor-pointer hover:bg-grey-100">
              <a :href="templatePath(format)" class="text-black visited:text-black flex">
                {{ t(`exportFormats.${format}`) }}
              </a>
            </li>
          </ul>
        </template>
      </DropDown>
      <input
        ref="uploadButton"
        type="file"
        accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        class="hidden"
        @change="submit"
      />
      <button class="btn-primary-purple-m uppercase" @click.prevent="uploadButton?.click()">
        <SvgIcon :path="mdiUpload" :size="18" class="mr-2" />
        {{ t('EmailInvitationQuota.upload.ctaLabel') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { mdiDownload, mdiUpload } from '@mdi/js'
import { DropDown } from '@ramp106/omrjs-core-ui'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { supportedKinds } from '@/components/InvitationQuotaCsvUpload'
import { useUploadInviteesMutation, type InvitationQuota, InvitationQuotaTypeEnum } from '@/gql/myomr'
import { showNotification } from '@/helpers/notificationHelper'
import { exportFormats, type exportFormat } from '@/services/ApiService'

const { t } = useI18n()
const router = useRouter()

const uploadProgress = ref(0)
const file = ref<File>()
const uploadButton = ref<HTMLInputElement | null>()

const props = defineProps<{
  invitationQuota: InvitationQuota
  loading: boolean
}>()

const { mutate, onDone, onError } = useUploadInviteesMutation({
  context: {
    fetchOptions: {
      useUpload: true,
      onProgress: (progress: ProgressEvent) => {
        const loadedProgress = Math.round((progress.loaded / progress.total) * 100)
        uploadProgress.value = loadedProgress
      },
    },
  },
})

function submit(e: Event) {
  if (!(e.target instanceof HTMLInputElement)) return
  const target = e.target
  if (!target.files) return
  file.value = target.files[0]

  if (file.value) {
    return mutate({
      invitationQuotaId: props.invitationQuota.id,
      csvFile: file.value,
    })
  }
}

const templatePaths = {
  [InvitationQuotaTypeEnum.CodeQuota]: 'https://drive.google.com/file/d/1zJhXeKQlpzOxMGmeIAE8iEOKiytdEiEv/view',
  [InvitationQuotaTypeEnum.CrewQuota]: '/assets/files/upload-templates/crew-quota',
  [InvitationQuotaTypeEnum.ExhibitorQuota]: 'https://drive.google.com/file/d/1zJhXeKQlpzOxMGmeIAE8iEOKiytdEiEv/view',
  [InvitationQuotaTypeEnum.GuestQuota]: '/assets/files/upload-templates/guest-quota',
  [InvitationQuotaTypeEnum.PressQuota]: '/assets/files/upload-templates/press-quota',
}
const templatePath = computed(() => (format: exportFormat) => {
  const path = templatePaths[props.invitationQuota.kind]
  if (path.includes('upload-templates')) {
    return `${path}.${format}`
  } else {
    return path
  }
})

onDone((result) => {
  uploadProgress.value = 0
  const uploadId = result.data?.uploadInvitees?.csvUploadId
  if (uploadId)
    router.push({
      name: 'invitationQuotaCsvMappingForm',
      params: { uploadId },
    })
})
onError((error) => {
  uploadProgress.value = 0
  if (error) showNotification(error.message, 'error')
})

onMounted(() => {
  if (!supportedKinds.includes(props.invitationQuota.kind)) {
    router.push({ name: 'invitationQuota' })
    return
  }
})
</script>
