import { defineStore } from 'pinia'
import { ref } from 'vue'

import { getLocale, getValidLocale } from '@/helpers/locale'

import i18n from '../plugins/i18n'

export type UiNotificationType = 'info' | 'error' | 'warning' | 'success'
export type UiNotification = {
  id?: number
  type: UiNotificationType
  message: string
  headline?: string
}

let nextNotificationId = 1

export const persistKey = 'omr-manage-ui'
export const useUiStore = defineStore(
  'ui',
  () => {
    const locale = ref(getLocale())
    const notifications = ref<UiNotification[]>([])
    const showAccountSwitcher = ref(false)

    function addNotification(notification: UiNotification) {
      const newNotification = {
        ...notification,
        id: nextNotificationId++,
      }
      notifications.value.push(newNotification)
      return newNotification
    }
    function removeNotification(notificationToRemove: UiNotification) {
      notifications.value = notifications.value.filter((notification) => notification.id !== notificationToRemove.id)
    }
    function changeLocale(value: string) {
      const newLocale = getValidLocale(value, true)
      locale.value = newLocale!
      i18n.global.locale.value = newLocale!
      if (window && window.localStorage) {
        localStorage.setItem('locale', newLocale!)
      }
    }
    function setShowAccountSwitcher(value: boolean) {
      showAccountSwitcher.value = value
    }

    return {
      addNotification,
      changeLocale,
      locale,
      notifications,
      removeNotification,
      setShowAccountSwitcher,
      showAccountSwitcher,
    }
  },
  {
    persist: {
      key: persistKey,
      pick: ['locale'],
      storage: sessionStorage,
    },
  },
)
