import { onMounted, nextTick } from 'vue'
import { useRouter } from 'vue-router'

import { $can } from '@/services/roles'

function roleRedirect(pagePermission: string | string[], router: ReturnType<typeof useRouter>) {
  if (Array.isArray(pagePermission)) {
    let canAccessPage = false
    pagePermission.forEach((p) => {
      if ($can(p, { for: 'User' })) canAccessPage = true
    })
    if (canAccessPage) return
  } else if ($can(pagePermission, { for: 'User' })) return

  if ($can('viewDashboard', { for: 'User' })) return router.push('/dashboard')
  if ($can('manageUsers', { for: 'User' })) return router.push('/users')
  if ($can('viewSpeakers', { for: 'User' })) return router.push('/speakers')
  if ($can('viewEvents', { for: 'User' })) return router.push('/events')
  if ($can('viewCompany', { for: 'User' })) return router.push('/partner-company')
  if ($can('viewBooth', { for: 'User' })) return router.push('/booths')
  if ($can('viewProspectCatalog', { for: 'User' })) return router.push('/prospects')
  if ($can('viewLeads', { for: 'User' })) return router.push('/myleads')
}

export function useRoleRedirect(pagePermission: string | string[]) {
  const router = useRouter()
  onMounted(async () => {
    await nextTick()

    roleRedirect(pagePermission, router)
  })
}
