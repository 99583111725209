<template>
  <InvitationQuotaCsvMapping
    v-if="csvPreviewData"
    :invitation-quota="invitationQuota"
    :csv-preview-data="csvPreviewData"
    :saving="saving"
    @save-mapping="saveMapping"
  />
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import type { CsvMappingAttributes, InvitationQuota } from '@/gql/myomr'
import { useCsvPreviewDataQuery, useImportInviteesMutation } from '@/gql/myomr'
import { showNotification } from '@/helpers/notificationHelper'
import { reloadQuotaKey } from '@/symbols/reloadQuotaKey'

import InvitationQuotaCsvMapping from './InvitationQuotaCsvMapping.vue'

const props = defineProps<{
  invitationQuota: InvitationQuota
  loading: boolean
  uploadId: string
}>()

const router = useRouter()
const { t } = useI18n()

const reloadQuota = inject(reloadQuotaKey) as () => void

const { result, onError: onQueryError } = useCsvPreviewDataQuery({
  csvUploadId: props.uploadId,
  invitationQuotaId: props.invitationQuota.id,
})
const csvPreviewData = computed(() => result.value?.csvPreviewData)

const { mutate, loading: saving, onDone, onError: onMutationError } = useImportInviteesMutation()

const saveMapping = ({ mapping, hasHeaders }: { mapping: CsvMappingAttributes; hasHeaders: boolean }) => {
  mutate({
    invitationQuotaId: props.invitationQuota.id,
    csvUploadId: props.uploadId,
    hasHeaders,
    mapping,
  })
}

onDone(() => {
  const message = t(`EmailInvitationQuota.mapping.${props.invitationQuota.kind}.successful`) || t('EmailInvitationQuota.mapping.successful')
  showNotification(message, 'success')
  reloadQuota()

  router.push({ name: 'invitationQuota' })
})

onMutationError((error) => {
  if (error) showNotification(error.message, 'error')
})
onQueryError((error) => {
  if (error) showNotification(error.message, 'error')
})
</script>
