<template>
  <h1>{{ t('ProspectsCatalog.title', { eventName }) }}</h1>
  <p class="mt-4 text-body-m">{{ t('ProspectsCatalog.description') }}</p>
  <div>
    <template v-if="hasCompany">
      <BackLink class="mt-4" :to="{ name: 'prospectEvents' }">{{ t('backToOverview') }}</BackLink>

      <ContentCard class="mt-4 pt-6">
        <template #header>
          <TabBar>
            <NavTabBarItem
              v-if="props.selector == 'current'"
              :is-selected="route.matched.some(({ name }) => name === 'prospectsCatalog')"
              :to="{ name: 'prospectsCatalog' }"
            >
              {{ t('ProspectsCatalog.allVisitors') }}
            </NavTabBarItem>
            <NavTabBarItem :is-selected="route.matched.some(({ name }) => name === 'prospectLists')" :to="prospectListRoute">
              {{ t('ProspectsCatalog.myProspects') }}
            </NavTabBarItem>
          </TabBar>
        </template>

        <RouterView
          v-if="prospectsResult != null"
          :facet-items="facetItems"
          :filters="filters"
          :me="props.me"
          :page="page"
          :per-page="perPage"
          :period="props.period"
          :prospect-list-items="allProspectListItems"
          :prospect-lists="prospectLists"
          :prospects="prospectsResult?.prospects"
          :selector="props.selector"
          :slug="props.slug"
          :event="event"
          @create-prospect-item="createProspectItem"
          @delete-prospect-item="deleteProspectItem"
          @refetch-prospect-lists="refetchProspectLists"
          @reset-filters="resetFilters"
          @update-filters="updateFilters"
          @update:page="page = $event"
          @update:per-page="perPage = $event"
        />
      </ContentCard>
    </template>
    <AlertBox v-else type="warning" class="mt-6">
      {{ t('PartnerCompany.noPartnerCompany') }}
    </AlertBox>
  </div>
</template>

<script setup lang="ts">
import { ApolloError } from '@apollo/client/core'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import prospectFacetItems from '@/components/Prospects/prospectFacetItems'
import type { Filters, FilterName } from '@/components/Prospects/types'
import {
  useProspectsQuery,
  useCreateProspectItemMutation,
  useDeleteProspectItemMutation,
  type MeQuery,
  type ProspectListsQuery,
} from '@/gql/myomr'
import { useEventBySlugQuery } from '@/gql/myomr'
import { toPlainId } from '@/helpers/globalIdHelper'
import { getLocalizedAttribute } from '@/helpers/localizedAttribute'
import { showNotification } from '@/helpers/notificationHelper'
import { useRoleRedirect } from '@/services/roleRedirect'

useRoleRedirect('viewProspectCatalog')

const { t, locale } = useI18n()

const route = useRoute()

const props = defineProps<{
  listId?: string
  me: NonNullable<MeQuery['me']>
  period: string
  prospectListsError?: ApolloError
  prospectListsLoading: boolean
  prospectListsRefetch: () => void
  prospectListsResult: ProspectListsQuery
  selector?: string
  slug: string
}>()

const { result: eventInfoResult } = useEventBySlugQuery({ slug: route.params.slug as string })

const hasCompany = computed(() => !!props.me.partnerCompany)

const prospectListRoute = computed(() => {
  if (prospectLists.value.length) {
    return { name: 'prospectList', params: { listId: toPlainId(prospectLists.value[0].id) } }
  } else {
    return { name: 'prospectLists' }
  }
})

const allProspectLists = computed(() => props.prospectListsResult?.prospectLists?.nodes || [])
const prospectLists = computed(() => allProspectLists.value.filter((list) => list.event.slug == props.slug))

const event = computed(() => eventInfoResult.value?.eventBySlug)
const eventName = computed(() => {
  if (!event.value) return ''

  return getLocalizedAttribute({
    de: event.value.nameDe,
    en: event.value.nameEn,
    fallback: event.value.name || 'Event',
    locale: locale.value,
  })
})

function refetchProspectLists() {
  props.prospectListsRefetch()
}

const allProspectListItems = computed(() =>
  prospectLists.value.flatMap((list) => {
    return list.prospects.map((prospect) => ({ ...prospect, prospectListId: list.id }))
  }),
)

const { mutate: createProspectItemMutation } = useCreateProspectItemMutation()
async function createProspectItem({ prospectId, prospectListId }: { prospectId: string; prospectListId: string }) {
  if (props.me.partnerCompany) {
    const result = await createProspectItemMutation({ prospectListId: prospectListId, token: prospectId })
    if (result?.data?.createProspectItem?.errors?.length) {
      showNotification(result.data.createProspectItem.errors.join(', '), 'error', 3000)
    } else {
      showNotification(t('ProspectsCatalog.addToListNotification'), 'success', 3000)
    }
    refetchProspectLists()
  }
}

const { mutate: deleteProspectItemMutation } = useDeleteProspectItemMutation()
async function deleteProspectItem({ prospectId, prospectListId }: { prospectId: string; prospectListId: string }) {
  if (props.me.partnerCompany) {
    const result = await deleteProspectItemMutation({ prospectListId: prospectListId, token: prospectId })
    if (result?.data?.deleteProspectItem?.errors?.length) {
      showNotification(result.data.deleteProspectItem.errors.join(', '), 'error', 3000)
    } else {
      showNotification(t('ProspectsCatalog.removeFromListNotification'), 'success', 3000)
    }
    refetchProspectLists()
  }
}

const page = ref(1)
const perPage = ref(20)
const filters: Filters = {
  companyDepartments: ref<string[]>([]),
  companyFields: ref<string[]>([]),
  companyIndustries: ref<string[]>([]),
  companyJobLevels: ref<string[]>([]),
  companySizes: ref<string[]>([]),
  companyCountries: ref<string[]>([]),
  visitedEvents: ref<string[]>([]),
  positions: ref<string[]>([]),
  companyNames: ref<string[]>([]),
  available: ref<string[]>([]),
}

const companyNameSearchString = computed(() => filters.companyNames.value.join(' '))
const positionSearchString = computed(() => filters.positions.value.join(' '))

const availabilityValue = computed(() => {
  if (filters.available.value.length == 0 || filters.available.value.length == 2) return null
  if (filters.available.value.includes('available')) return true
  return false
})

const prospectsQueryVariables = computed(() => {
  return {
    page: page.value,
    perPage: perPage.value,
    companyName: companyNameSearchString.value,
    position: positionSearchString.value,
    visitedEvents: filters.visitedEvents.value,
    companySizes: filters.companySizes.value,
    companyCountries: filters.companyCountries.value,
    companyFields: filters.companyFields.value,
    companyIndustries: filters.companyIndustries.value,
    companyDepartments: filters.companyDepartments.value,
    companyJobLevels: filters.companyJobLevels.value,
    available: availabilityValue.value,
    eventSlug: route.params.slug as string,
  }
})

const { result: prospectsResult, refetch } = useProspectsQuery(prospectsQueryVariables, { fetchPolicy: 'no-cache' })

watch(
  [
    filters.companyDepartments,
    filters.companyFields,
    filters.companyIndustries,
    filters.companyJobLevels,
    filters.companyNames,
    filters.companySizes,
    filters.companyCountries,
    filters.positions,
    filters.visitedEvents,
    perPage,
  ],
  () => {
    page.value = 1
  },
)
watch([locale], () => refetch())

function updateFilters({ filterName, values }: { filterName: FilterName; values: string[] }) {
  filters[filterName].value = values
}

function resetFilters() {
  let filterName: keyof typeof filters
  for (filterName in filters) {
    filters[filterName].value = []
  }
}

const facetItems = computed(() => prospectFacetItems(prospectsResult.value?.prospects))
</script>
