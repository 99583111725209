<template>
  <div :class="`rounded-sm py-3 px-3 mb-3 text-base w-full border ${colorClasses}`" role="alert">
    <div class="w-full inline-flex items-start">
      <SvgIcon :path="path" :size="20" class="mr-3 mt-1" />
      <div class="w-full">
        <h3 v-if="slots.header" class="text-lg font-medium">
          <slot name="header"></slot>
        </h3>
        <div class="text-sm">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default { name: 'AlertBox' }
</script>

<script setup lang="ts">
import { mdiInformation, mdiAlert, mdiCheckCircle, mdiAlphaXCircle } from '@mdi/js'
import { computed, useSlots } from 'vue'

const slots = useSlots()

const props = defineProps<{
  type: 'danger' | 'info' | 'success' | 'warning'
  iconPath?: string
}>()

const iconPaths = {
  danger: mdiAlphaXCircle,
  info: mdiInformation,
  success: mdiCheckCircle,
  warning: mdiAlert,
}

const colors = {
  danger: 'bg-amaranth-100 text-amaranth-700',
  info: 'bg-victoria-100 text-victoria-700',
  success: 'bg-tailwind-100 text-tailwind-700',
  warning: 'bg-ripe-lemon-100 text-ripe-lemon-700',
}

const path = computed(() => props.iconPath || iconPaths[props.type])
const colorClasses = computed(() => colors[props.type])
</script>
