<template>
  <RouterView v-if="me" :me="me" />
</template>

<script lang="ts">
export default {
  name: 'AuthorizedArea',
  inheritAttrs: false,
  customOptions: {},
}
</script>

<script setup lang="ts">
import { computed } from 'vue'

import { useMeQuery } from '@/gql/myomr'
import initializePendo from '@/helpers/pendo'
import { useMeStore } from '@/stores'
const { setupCurrentUser } = useMeStore()
const { result, onResult } = useMeQuery()
onResult((queryResult) => {
  if (queryResult.loading) return
  if (queryResult?.data) {
    const data = queryResult.data
    setupCurrentUser(data)
    initializePendo(data?.me)
  }
})

const me = computed(() => result.value?.me)
</script>
