<template>
  <div>
    <AlertBox v-if="$cannot('manageUsers', { for: 'User' })" type="warning" class="mt-5">
      {{ t('PartnerCompany.noPartnerCompany') }}
    </AlertBox>
    <ContentCard v-else>
      <template #header>
        <div class="my-6 flex flex-row justify-between">
          <h2>{{ t('UserManagement.UserList.title') }}</h2>
          <RouterLink class="btn-primary-purple-m" :to="{ name: 'createUser' }">
            {{ t('UserManagement.UserList.createNewUserCTA') }}
          </RouterLink>
        </div>
      </template>
      <!-- User column -->
      <div v-if="props.loading" class="py-5 text-center">
        <ProgressBarCircular />
      </div>
      <template v-else>
        <UserItem v-for="user in props.users" :key="user.id" :user="user" :for-partner-company-id="currentPartnerCompanyId" />
      </template>

      <template #footer>
        <div class="text-right">
          <RouterLink class="btn-primary-purple-m" :to="{ name: 'createUser' }">
            {{ t('UserManagement.UserList.createNewUserCTA') }}
          </RouterLink>
        </div>
      </template>
    </ContentCard>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import type { User } from '@/gql/myomr'
import { useRoleRedirect } from '@/services/roleRedirect'
import { $cannot } from '@/services/roles'
import { useMeStore } from '@/stores'

import { default as UserItem } from './UserItem.vue'

useRoleRedirect('manageUsers')

const props = defineProps<{
  users: User[]
  loading: boolean
}>()

const { t } = useI18n()
const meStore = useMeStore()

const currentPartnerCompanyId = computed(() => meStore.currentPartnerCompanyId || '')
</script>
