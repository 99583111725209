import { computed, ref, watch } from 'vue'
import { useRoute, useRouter, type LocationQueryValue } from 'vue-router'

import { SurveyResponsesReminderStatusEnum, SurveyResponsesReplyStatusEnum } from '@/gql/reviews'

import { reviewsSortAndFilterParamsFromQuery } from './reviewsSortAndFilterParamsFromQuery'
import { ReviewInteractionType, TimeFrameOptionsEnum } from './types'

export const useRouterQueryFilters = () => {
  const route = useRoute()
  const router = useRouter()

  const hasNoActiveFilters = ref(false)
  const availableFilters = ['timeframe', 'rating', 'state', 'interaction']

  const queryParams = computed(() => reviewsSortAndFilterParamsFromQuery(route.query))
  const updated_since = ref()
  const updated_to = ref()
  const reminder_status = ref()
  const reply_status = ref()

  setReviewFilters()

  function setReviewFilters() {
    setTimeframeURLOptions(queryParams.value.timeframe)
    setReminderReplyURLOptions(queryParams.value.interaction)

    const query = {
      ...route.query,
      timeframe: queryParams.value.timeframe !== TimeFrameOptionsEnum.NONE ? queryParams.value.timeframe : undefined,
      rating: queryParams.value.ratings,
      state: queryParams.value.states,
      interaction: queryParams.value.interaction !== ReviewInteractionType.NONE ? queryParams.value.interaction : undefined,
    }
    router.push({ query })

    hasNoActiveFilters.value = !availableFilters.some((key) => key in route.query)
  }

  function setTimeframeURLOptions(timeframeValue: LocationQueryValue) {
    if (timeframeValue && timeframeValue !== TimeFrameOptionsEnum.NONE) {
      const referenceTime = new Date()
      updated_to.value = referenceTime.toISOString()

      switch (timeframeValue) {
        case '2':
          referenceTime.setMonth(referenceTime.getMonth() - 3)
          updated_since.value = referenceTime.toISOString()
          break
        case '3':
          referenceTime.setMonth(referenceTime.getMonth() - 6)
          updated_since.value = referenceTime.toISOString()
          break
        case '4':
          referenceTime.setFullYear(referenceTime.getFullYear() - 1)
          updated_since.value = referenceTime.toISOString()
          break
        case '5':
          referenceTime.setFullYear(referenceTime.getFullYear() - 1)
          updated_to.value = referenceTime.toISOString()
          updated_since.value = null
          break
        default:
          referenceTime.setDate(referenceTime.getDate() - 30)
          updated_since.value = referenceTime.toISOString()
          break
      }
    } else {
      updated_since.value = null
      updated_to.value = null
    }
  }

  function setReminderReplyURLOptions(interactionValue: string | null) {
    if (interactionValue === ReviewInteractionType.NONE) {
      reminder_status.value = null
      reply_status.value = null
    }

    switch (interactionValue) {
      case ReviewInteractionType.REMINDER_CHECK:
        reply_status.value = null
        reminder_status.value = [SurveyResponsesReminderStatusEnum.SentManual, SurveyResponsesReminderStatusEnum.SentAutomatic]
        break
      case ReviewInteractionType.REMINDER:
        reply_status.value = null
        reminder_status.value = [SurveyResponsesReminderStatusEnum.Blank]
        break
      case ReviewInteractionType.REPLY_CHECK:
        reminder_status.value = null
        reply_status.value = [SurveyResponsesReplyStatusEnum.Sent]
        break
      case ReviewInteractionType.REPLY:
        reminder_status.value = null
        reply_status.value = [SurveyResponsesReplyStatusEnum.Blank]
        break
      case ReviewInteractionType.REPLY_OUTDATED:
        reminder_status.value = null
        reply_status.value = [SurveyResponsesReplyStatusEnum.Outdated]
        break
    }
  }

  function resetAllFilters() {
    queryParams.value.timeframe = TimeFrameOptionsEnum.NONE
    updated_to.value = null
    updated_since.value = null

    queryParams.value.ratings = undefined
    queryParams.value.states = undefined

    queryParams.value.interaction = 'none'
    reply_status.value = null
    reminder_status.value = null

    setReviewFilters()
    hasNoActiveFilters.value = true
  }

  watch(
    () => route.query,
    () => {
      setReviewFilters()
    },
  )

  return {
    queryParams: queryParams,
    updated_since,
    updated_to,
    reminder_status,
    reply_status,
    hasNoActiveFilters,
    setReviewFilters,
    resetAllFilters,
    setTimeframeURLOptions,
    setReminderReplyURLOptions,
  }
}
