import { isNotNull } from '@ramp106/omrjs-common'
import { type RouteLocationNormalized } from 'vue-router'

import { RecommendationRatingKeyEnum, SurveyResponsesStateEnum } from '@/gql/reviews/index.ts'
import { checkEnumValue } from '@/helpers/checkEnumValue.ts'
import { getQueryStringArrayValue } from '@/helpers/getQueryStringArrayValue.ts'

import { ReviewInteractionType, TimeFrameOptionsEnum, type ReviewsSortAndFilter } from './types.ts'

export const reviewsSortAndFilterParamsFromQuery = (query: RouteLocationNormalized['query']): ReviewsSortAndFilter => {
  const { timeframe, rating, state, interaction } = query

  return {
    timeframe: checkEnumValue(timeframe, TimeFrameOptionsEnum, TimeFrameOptionsEnum.NONE),
    ratings: getQueryStringArrayValue(rating)
      .map((value) => checkEnumValue(value, RecommendationRatingKeyEnum, null))
      .filter(isNotNull),
    states: getQueryStringArrayValue(state)
      .map((value) => checkEnumValue(value, SurveyResponsesStateEnum, null))
      .filter(isNotNull),
    interaction: checkEnumValue(interaction, ReviewInteractionType, ReviewInteractionType.NONE),
  }
}
