<template>
  <tr>
    <td>
      <div class="pt-5">
        <b class="text-headline-xs font-weight-bold">
          {{ product.name }}
        </b>
        <p class="description py-3">{{ product.description }}</p>

        <!-- If item has download option -->
        <a v-if="product.attachmentUrl" :disabled="disabled" class="btn-text-mint-m mb-2" :href="product.attachmentUrl" target="_blank">
          {{ t('BoothExtras.downloadInfo') }}
        </a>

        <!-- If item has variants -->
        <template v-if="product.variants.length > 1">
          <div class="product-variant-select">
            <SelectInput v-model="variantId" label="Variants" :disabled="disabled">
              <option v-for="variant in product.variants" :key="variant.id" :value="variant.id">
                {{ variant.name }}
              </option>
            </SelectInput>
            <p v-if="!variantId && count > 0" class="error-message text-error-600">
              {{ t('BoothExtras.enterVariant') }}
            </p>
          </div>
        </template>

        <!-- If item has file upload -->
        <template v-if="product.fileUploadRequired">
          <FileUpload
            v-model="uploadedFile"
            :error="isAttachmentValid"
            :icon="mdiPaperclip"
            :label="(oldFileName ? t('BoothExtras.uploadFile') : t('BoothExtras.updateFile')) + (product.fileUploadRequired ? '*' : '')"
            class="mt-4 mb-2"
          />
          <p v-if="oldFileName">{{ oldFileName }}</p>
        </template>

        <template v-if="product.commentRequired">
          <TextInput
            v-model="commentField"
            :label="t('BoothExtras.commentLabel') + (product.commentRequired ? '*' : '')"
            :max-length="200"
            :error="isCommentValid ? t('validations.required').toString() : undefined"
          />
        </template>
      </div>
    </td>

    <td class="align-middle">
      <span v-if="selectedVariant" class="font-bold text-headline-xs">
        {{ formatPrice(selectedVariant.price) }}
      </span>
    </td>
    <td class="align-middle">
      <div class="flex">
        <button class="btn-secondary-purple-m" :disabled="!!cannotReduce" @click="update(-1)">
          <SvgIcon data-test-id="reduce-amount" :path="mdiMinus" :size="16" class="" />
        </button>
        &nbsp;&nbsp;
        <button class="btn-secondary-purple-m value-button">
          {{ count }}
        </button>
        &nbsp;&nbsp;
        <button class="btn-secondary-purple-m" :disabled="!!cannotIncrease" @click="update(1)">
          <SvgIcon data-test-id="increase-amount" :path="mdiPlus" :size="16" class="" />
        </button>
        <br />
      </div>
      <span v-if="product.maximum" class="text-caption font-weight-thin">{{ t('BoothExtras.maximum') }} {{ product.maximum }}</span>
    </td>
    <td class="text-right align-middle">
      <p v-if="selectedVariant" class="font-bold text-headline-xs">
        {{ formatPrice(selectedVariant.price * count) }}
      </p>

      <p v-if="product.deliveryFee" class="mt-1">
        {{ t('BoothExtras.deliveryFee') }}
        {{ formatPrice(product.deliveryFee) }}
      </p>
    </td>
  </tr>
</template>

<script lang="ts" setup>
import { mdiPaperclip, mdiPlus, mdiMinus } from '@mdi/js'
import { SelectInput, TextInput } from '@ramp106/omrjs-core-ui'
import { ref, watch, computed, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { Variant, Product } from '@/gql/myomr'
import { formatPrice } from '@/helpers/moneyHelper'

const props = defineProps<{
  product: Product
  count: number
  minimumValue: number
  selectedVariant?: Variant
  oldFileName?: string
  disabled?: boolean
  isReduceable?: boolean
  comment?: string
}>()

const emit = defineEmits(['update'])
const { t } = useI18n()

const uploadedFile = ref<File | null>(null)
const variantId: Ref<string | null> = ref(props.selectedVariant?.id || null)
const commentField: Ref<string> = ref(props.comment || '')

const cannotReduce = computed(() => {
  if (props.count > 0 && props.isReduceable) return false
  return props.count === 0 || props.count === props.minimumValue || props.disabled
})

const cannotIncrease = computed(() => {
  const isMaxed = !!(props.product.maximum && props.count >= props.product.maximum)
  return isMaxed || props.disabled
})

const isCommentValid = computed(() => {
  return commentField.value.length === 0 && props.product.commentRequired && props.count > 0
})

const isAttachmentValid = computed(() => {
  return props.product.fileUploadRequired && !uploadedFile.value && !props.oldFileName && props.count > 0
})

watch([variantId, uploadedFile, commentField], () => {
  update()
})

function update(change: number = 0): void {
  emit('update', props.product.id, props.count + change, variantId.value, uploadedFile.value, commentField.value)
}
</script>

<style>
.product-variant-select {
  max-width: 350px;
}
</style>
