<template>
  <div class="border-solid border-b-grey-300 border-b">
    <div>
      <TextInput
        v-for="lang in ALLOWED_LOCALES"
        :key="lang"
        v-model="v$.name[lang].$model"
        :placeholder="t('Reviews.product.edit.pricing.name.placeholder').toString()"
        :error="getValidationErrors(v$.name[lang])"
        :max-length="NAME_MAX_LENGTH"
      >
        <template #label>
          <div class="flex justify-between items-end">
            <span>{{ t('Reviews.product.edit.pricing.name.label') }}</span>
            <ChipBadge class="uppercase" rounded>{{ lang }}</ChipBadge>
          </div>
        </template>
      </TextInput>
    </div>
    <SelectInput
      v-model="pricingOption"
      :label="t('Reviews.product.edit.pricing.pricingOption.label').toString()"
      :placeholder="t('Reviews.product.edit.pricing.pricingOption.placeholder').toString()"
      :error="getValidationErrors(vPricingOptions$.pricingOption)"
    >
      <option value="price">{{ t('Reviews.product.edit.pricing.pricingOption.value.price') }}</option>
      <option value="text">{{ t('Reviews.product.edit.pricing.pricingOption.value.text') }}</option>
    </SelectInput>
    <template v-if="pricingOption === 'price'">
      <div class="flex">
        <div class="mr-4 w-28">
          <SelectInput
            :key="`currency-${currenciesOptions.length}`"
            v-model="v$.priceCurrency.$model"
            :label="t('Reviews.product.edit.pricing.priceCurrency.label').toString()"
            :placeholder="t('Reviews.product.edit.pricing.priceCurrency.placeholder').toString()"
            :error="getValidationErrors(v$.priceCurrency)"
          >
            <option v-for="currency in currenciesOptions" :key="currency.code" :value="currency.code">{{ currency.code }}</option>
          </SelectInput>
        </div>
        <TextInput
          v-model.number="v$.priceCents.$model"
          :label="t('Reviews.product.edit.pricing.priceCents.label').toString()"
          :error="getValidationErrors(v$.priceCents)"
          type="number"
        />
      </div>
      <CheckBox v-model="v$.startingPrice.$model" class="pb-4">
        {{ t('Reviews.product.edit.pricing.startingPrice.label') }}
      </CheckBox>
      <SelectInput
        :key="`subscription-modes-${subscriptionModes.length}`"
        v-model="v$.pricingSubscriptionModeId.$model"
        :label="t('Reviews.product.edit.pricing.pricingSubscriptionMode.label').toString()"
        :placeholder="t('Reviews.product.edit.pricing.pricingSubscriptionMode.placeholder').toString()"
        :error="getValidationErrors(v$.pricingSubscriptionModeId)"
      >
        <option v-for="mode in subscriptionModes" :key="mode.id" :value="mode.id">{{ translatedOptionName(mode, locale) }}</option>
      </SelectInput>
    </template>
    <SelectInput
      v-else-if="pricingOption === 'text'"
      :key="`text-option-${pricingTextOptions.length}`"
      v-model="v$.pricingTextOptionId.$model"
      :label="t('Reviews.product.edit.pricing.pricingTextOption.label').toString()"
      :placeholder="t('Reviews.product.edit.pricing.pricingTextOption.placeholder').toString()"
      :error="getValidationErrors(v$.pricingTextOptionId)"
    >
      <option v-for="option in pricingTextOptions" :key="option.id" :value="option.id">{{ translatedOptionName(option, locale) }}</option>
    </SelectInput>
    <TextInput
      v-for="lang in ALLOWED_LOCALES"
      :key="lang"
      v-model="v$.description[lang].$model"
      :error="getValidationErrors(v$.description[lang])"
      type="textarea"
      :rows="4"
      :max-length="DESCRIPTION_MAX_LENGTH"
    >
      <template #label>
        <div class="flex justify-between items-end">
          <span>{{ t('Reviews.product.edit.pricing.description.label') }}</span>
          <ChipBadge class="uppercase" rounded>{{ lang }}</ChipBadge>
        </div>
      </template>
    </TextInput>
    <div class="flex flex-col gap-6 my-6">
      <div v-for="(_, index) in formData.perkList" :key="index" class="relative px-4 pt-6 pb-2 border border-grey-300 rounded">
        <ChipBadge class="flex absolute -top-3.5 right-4" color="purple" rounded>
          <button class="mr-1" @click="deletePerk(index)"><MdiSvg :size="18" :path="mdiTrashCanOutline" /></button>
          <button :disabled="index === 0" class="mr-1" :class="{ 'text-purple-400': index === 0 }" @click="moveUpPerk(index)">
            <MdiSvg :size="18" :path="mdiArrowUp" />
          </button>
          <button
            :disabled="index === formData.perkList.length - 1"
            class="mr-1"
            :class="{ 'text-purple-400': index === formData.perkList.length - 1 }"
            @click="moveDownPerk(index)"
          >
            <MdiSvg :size="18" :path="mdiArrowDown" />
          </button>
        </ChipBadge>
        <PricingPerkInput
          v-for="lang in ALLOWED_LOCALES"
          :key="lang"
          v-model="formData.perkList[index][lang]"
          :index="index"
          :lang="lang"
          :required="lang === ALLOWED_LOCALES[0]"
        />
      </div>
    </div>
    <button v-if="formData.perkList.length < PERKS_LIMIT" class="btn-text-purple-m mb-4" @click.prevent="addPerk">
      <MdiSvg :path="mdiPlusCircleOutline" class="mr-2" />
      {{ t('Reviews.product.edit.pricing.actions.addPerk', formData.perkList.length + 1) }}
    </button>
  </div>
</template>
<script setup lang="ts">
import { mdiArrowDown, mdiArrowUp, mdiPlusCircleOutline, mdiTrashCanOutline } from '@mdi/js'
import { CheckBox, ChipBadge, MdiSvg, SelectInput, TextInput } from '@ramp106/omrjs-core-ui'
import { useVuelidate } from '@vuelidate/core'
import { computed, nextTick, reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { Currency } from '@/gql/reviews'
import { ALLOWED_LOCALES } from '@/helpers/translationsHelper'
import { buildTranslationFieldsRules, getValidationErrors } from '@/helpers/validationHelper'
import { required, requiredIf, numeric, maxLength, minLength } from '@/services/validators'

import type { PricingPlanFormData } from '../types'
import PricingPerkInput from './PricingPerkInput.vue'
import { translatedOptionName } from './translatedOptionName'
import type { TranslatedOption } from './types'

const NAME_MIN_LENGTH = 3
const NAME_MAX_LENGTH = 50
const DESCRIPTION_MAX_LENGTH = 200
const PERKS_LIMIT = 50

export interface PricingPlanFormProps {
  value: PricingPlanFormData
  pricingTextOptions: TranslatedOption[]
  subscriptionModes: TranslatedOption[]
  currenciesOptions: Currency[]
  pricingEnabled: boolean
}

const props = defineProps<PricingPlanFormProps>()
const emit = defineEmits(['input'])
const { t, locale } = useI18n()

const pricingOption = computed<'text' | 'price' | ''>({
  get() {
    if (typeof props.value.pricingSubscriptionModeId === 'string') {
      return 'price'
    }
    if (typeof props.value.pricingTextOptionId === 'string') {
      return 'text'
    }
    return ''
  },
  set(option) {
    switch (option) {
      case 'price':
        formData.pricingTextOptionId = null
        formData.pricingSubscriptionModeId = ''
        break
      case 'text':
        formData.pricingTextOptionId = ''
        formData.pricingSubscriptionModeId = null
        formData.priceCents = null
        formData.priceCurrency = null
        break
      default:
        formData.pricingTextOptionId = null
        formData.pricingSubscriptionModeId = null
    }
  },
})

const formData = reactive<PricingPlanFormData>({
  ...props.value,
  priceCents: props.value.priceCents ? props.value.priceCents / 100 : undefined,
  perkList: [...props.value.perkList],
})

const rules = computed(() => ({
  description: buildTranslationFieldsRules({ maxLength: maxLength(DESCRIPTION_MAX_LENGTH) }),
  name: {
    ...buildTranslationFieldsRules(
      {
        requiredIf: requiredIf(() => props.pricingEnabled),
        minLength: minLength(NAME_MIN_LENGTH),
        maxLength: maxLength(NAME_MAX_LENGTH),
      },
      [ALLOWED_LOCALES[0]],
    ),
    ...buildTranslationFieldsRules(
      {
        minLength: minLength(NAME_MIN_LENGTH),
        maxLength: maxLength(NAME_MAX_LENGTH),
      },
      ALLOWED_LOCALES.slice(1),
    ),
  },
  perkList: {},
  priceCents: { numeric, requiredIf: requiredIf(() => props.pricingEnabled && pricingOption.value === 'price') },
  priceCurrency: { requiredIf: requiredIf(() => props.pricingEnabled && pricingOption.value === 'price') },
  pricingSubscriptionModeId: { requiredIf: requiredIf(() => props.pricingEnabled && pricingOption.value === 'price') },
  pricingTextOptionId: { requiredIf: requiredIf(() => props.pricingEnabled && pricingOption.value === 'text') },
  startingPrice: {},
}))

const pricingOptionRules = computed(() => ({
  pricingOption: { required },
}))

const v$ = useVuelidate(rules, formData)
const vPricingOptions$ = useVuelidate(pricingOptionRules, { pricingOption })

function addPerk() {
  formData.perkList.push(ALLOWED_LOCALES.reduce((acc, locale) => ({ ...acc, [locale]: { name: '' } }), {}))
}

function deletePerk(index: number) {
  formData.perkList.splice(index, 1)
}

async function moveUpPerk(index: number) {
  if (index > 0) {
    // TODO: Find a better solution.
    const cacheArr = [...formData.perkList]
    // For some reason, without clearing the array and waiting for the next DOM update flush, elements are duplicated instead of replaced.
    formData.perkList = []
    await nextTick()
    const [removed] = cacheArr.splice(index, 1)
    cacheArr.splice(index - 1, 0, removed)
    formData.perkList = [...cacheArr]
  }
}

async function moveDownPerk(index: number) {
  if (index < formData.perkList.length - 1) {
    // TODO: Find a better solution.
    const cacheArr = [...formData.perkList]
    //  For some reason, without clearing the array and waiting for the next DOM update flush, elements are duplicated instead of replaced.
    formData.perkList = []
    await nextTick()
    const [removed] = cacheArr.splice(index, 1)
    cacheArr.splice(index + 1, 0, removed)
    formData.perkList = [...cacheArr]
  }
}

watch(
  () => formData,
  () => {
    emit('input', {
      ...formData,
      priceCents: formData.priceCents ? Math.round(formData.priceCents * 100) : undefined,
    })
  },
  { deep: true },
)
</script>
