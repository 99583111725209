import { computed, type Ref } from 'vue'

import { type UserPropertiesPreferencesQuery, type CompanyFieldsByIndustryQuery } from '@/gql/myomr'

export const salutationsMapper = function (result: Ref<UserPropertiesPreferencesQuery | undefined>) {
  return computed(() => {
    if (!result.value) return []
    return result.value.userPropertiesPreferences.salutations.map((item) => {
      return { text: item.name, value: item.enum }
    })
  })
}

export const titleOptions = [
  { text: 'Dr.', value: 'Dr.' },
  { text: 'Prof.', value: 'Prof.' },
  { text: 'Prof Dr.', value: 'Prof Dr.' },
  { text: 'Ing.', value: 'Ing.' },
  { text: 'Dipl. Ing.', value: 'Dipl. Ing.' },
  { text: 'Mag.', value: 'Mag.' },
]

export const jobLevelsMapper = function (result: Ref<UserPropertiesPreferencesQuery | undefined>) {
  return computed(() => {
    if (!result.value) return []
    return result.value.userPropertiesPreferences.jobLevels.map((item) => {
      return { text: item.name, value: item.enum }
    })
  })
}

export const companyDepartmentsMapper = function (result: Ref<UserPropertiesPreferencesQuery | undefined>) {
  return computed(() => {
    if (!result.value) return []
    return result.value.userPropertiesPreferences.companyDepartments.map((item) => {
      return { text: item.name, value: item.enum }
    })
  })
}

export const companySizesMapper = function (result: Ref<UserPropertiesPreferencesQuery | undefined>) {
  return computed(() => {
    if (!result.value) return []
    return result.value.userPropertiesPreferences.companySizes.map((item) => {
      return { text: item.name, value: item.enum }
    })
  })
}

export const companyTypesMapper = function (result: Ref<UserPropertiesPreferencesQuery | undefined>) {
  return computed(() => {
    if (!result.value) return []
    return result.value.userPropertiesPreferences.companyTypes.map((item) => {
      return { text: item.name, value: item.enum }
    })
  })
}

export const companyIndustriesMapper = function (result: Ref<UserPropertiesPreferencesQuery | undefined>) {
  return computed(() => {
    if (!result.value) return []
    return result.value.userPropertiesPreferences.companyIndustries.map((item) => {
      return { text: item.translation, value: item.id }
    })
  })
}

export const companyFieldsMapper = function (result: Ref<CompanyFieldsByIndustryQuery | undefined>) {
  return computed(() => {
    if (!result.value?.userPropertiesPreferences?.companyFieldsByIndustry) return []
    return result.value.userPropertiesPreferences.companyFieldsByIndustry.map((item) => {
      return { text: item.translation, value: item.id }
    })
  })
}

export const countriesMapper = function (result: Ref<UserPropertiesPreferencesQuery | undefined>) {
  return computed(() => {
    if (!result.value) return []
    return result.value.userPropertiesPreferences.countries.map((item) => {
      return { text: item.translations.en, value: item.code }
    })
  })
}
