import { showNotification } from '@/helpers/notificationHelper'
import i18n from '@/plugins/i18n'

export default function handleTicketMutationErrors(
  t: typeof i18n.global.t,
  errors: (string[] | undefined)[],
  errorKey: string,
  successKey: string,
) {
  let errorTicketsCount = 0
  let successTicketsCount = 0
  errors.forEach((errorOrUndefined) =>
    Array.isArray(errorOrUndefined) && errorOrUndefined.length ? errorTicketsCount++ : successTicketsCount++,
  )
  if (successTicketsCount) showNotification(t(successKey, successTicketsCount), 'success', 3000)
  if (errorTicketsCount) showNotification(t(errorKey, errorTicketsCount), 'error', 3000)
}
