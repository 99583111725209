<template>
  <!-- News section -->
  <div class="mt-10">
    <h2>News</h2>

    <div class="mt-5">
      <NewsItem v-for="newsItem in props.news" :key="newsItem.id" :news-item="newsItem" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { News } from '@/gql/myomr'

import NewsItem from './NewsItem.vue'

const props = defineProps<{
  news: News[]
}>()
</script>
