import type { Ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { BreadcrumbItem } from '@/components/PageBreadcrumbs.vue'
import { ReviewsRouteName } from '@/router/types.ts'

import { useBreadcrumb } from './useBreadcrumb.ts'

export const useReviewsRootBreadcrumb = (): Ref<BreadcrumbItem> => {
  const { t } = useI18n()

  return useBreadcrumb({ name: ReviewsRouteName.ReviewsProducts }, t('breadcrumbs.reviews.root'))
}
