<template>
  <ConfirmModal
    :title="t('AccountSwitcher.headline')"
    :description="t('AccountSwitcher.body')"
    hide-actions
    wrapper-class="max-w-2xl"
    @close-dialog="close"
  >
    <template #content>
      <div class="px-6 py-4">
        <div v-for="company in partnerCompanies" :key="company.id" class="mb-2">
          <div
            class="w-full"
            :class="{
              'btn-primary-mint-m': isCurrentPartnerCompany(company.id),
              'btn-secondary-mint-m': !isCurrentPartnerCompany(company.id),
            }"
            @click="switchTo(company.id)"
          >
            <span class="mx-auto">{{ company.displayName || company.name }}</span>
          </div>
        </div>
      </div>
    </template>
  </ConfirmModal>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { usePartnerCompaniesQuery } from '@/gql/myomr'
import type { PartnerCompany, MeQuery } from '@/gql/myomr'
import { toGlobalId } from '@/helpers/globalIdHelper'
import { useMeStore, useUiStore } from '@/stores'

const props = defineProps<{
  me: NonNullable<MeQuery['me']>
}>()

const { setCurrentPartnerCompanyGlobalId } = useMeStore()
const { setShowAccountSwitcher } = useUiStore()
const { t } = useI18n()

function isCurrentPartnerCompany(companyId: string) {
  return props.me?.partnerCompany && props.me.partnerCompany.id === companyId
}

const partnerCompanyIds = computed(() => {
  return props.me?.roles
    .filter((role) => role.resourceType === 'PartnerCompany' && role.resourceId)
    .map((role) => toGlobalId('PartnerCompany', role.resourceId as string))
    .filter((id, index, array) => array.indexOf(id) === index)
})

const { result } = usePartnerCompaniesQuery({ partnerCompanyIds: partnerCompanyIds.value })
const partnerCompanies = computed(() => (result.value?.nodes as PartnerCompany[]) || [])

const close = () => {
  setShowAccountSwitcher(false)
}

const switchTo = (id: string) => {
  setCurrentPartnerCompanyGlobalId(id)
  location.reload()
}
</script>
