import * as Sentry from '@sentry/vue'

import env from '@/config/env'

type ErrorParams = unknown[]

let errorHandler: {
  report: (message?: unknown, ...optionalParams: ErrorParams) => void
}

if (['staging', 'production'].includes(env)) {
  errorHandler = {
    report: function (...args: ErrorParams) {
      Sentry.captureException(args)
    },
  }
} else {
  errorHandler = { report: console.error }
}

export default errorHandler
