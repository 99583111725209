<template>
  <div class="pt-6 pb-10">
    <div v-if="review" class="grid grid-cols-3 gap-6 items-start">
      <div class="col-span-3">
        <MessageBanner v-if="message" :headline="message.headline" :message="message.text" :type="message.type">
          <button v-if="canSendReminder" class="btn-primary-white-s" :disabled="reminderDisabled" @click="$emit('send-reminder')">
            {{ t('Reviews.product.view.actions.sendReminder') }}
            <MdiSvg :path="mdiBell" size="16" class="ml-1" />
          </button>
        </MessageBanner>
      </div>
      <ReviewOverview class="col-span-1" :review="review" />
      <div class="col-span-2">
        <VideoReview v-if="review.video" class="col-span-2" :video-review="review.video" />
        <TextReviewQuotes
          v-else
          class="mb-10"
          :class="{
            'opacity-50': state === SurveyResponseState.ReDrafted,
          }"
          :review="review"
        />
        <ReplyItem
          v-if="!!reviewReply"
          :can-react-to-review="product.configFlags.reviewRepliesAllowed"
          :product-title="product.title"
          :review-reply="reviewReply"
          @delete-reply="$emit('delete-reply')"
          @edit-reply="$emit('edit-reply')"
        />
        <RemindersHistory
          v-if="surveyResponse.reminders.length > 0"
          :author-name="surveyResponse.reviewerFirstName || undefined"
          class="my-6"
          :reminders="surveyResponse.reminders"
        />
      </div>
    </div>
  </div>
  <WorkInProgressHint context="details" />
</template>

<script setup lang="ts">
import { mdiBell } from '@mdi/js'
import { MdiSvg, MessageBanner } from '@ramp106/omrjs-core-ui'
import { ReviewOverview, TextReviewQuotes, VideoReview } from '@ramp106/omrjs-reviews-ui'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import WorkInProgressHint from '@/components/ReviewsManagement/WorkInProgressHint.vue'
import { SurveyResponseState, useSurveyResponseState } from '@/composables/useSurveyResponseState.ts'
import type { ProductData } from '@/gql/reviews'

import RemindersHistory from './RemindersHistory.vue'
import ReplyItem from './ReplyItem.vue'
import type { SurveyResponseDetails } from './types'

const props = defineProps<{
  product: Pick<ProductData, 'id' | 'title' | 'configFlags'>
  surveyResponse: SurveyResponseDetails
  reminderDisabled?: boolean
}>()

defineEmits(['send-reminder', 'delete-reply', 'edit-reply'])

const { t } = useI18n()

const state = useSurveyResponseState(props.surveyResponse)

const review = computed(() => {
  const response = props.surveyResponse

  let recommendationScore
  if (
    [SurveyResponseState.Published, SurveyResponseState.Proposed, SurveyResponseState.Rejected, SurveyResponseState.ReDrafted].includes(
      state.value,
    )
  ) {
    recommendationScore = response.review?.recommendationScore
  }

  return {
    positive: response.review?.positive,
    negative: response.review?.negative,
    problems: response.review?.problems,
    anonymous: response.anonymous,
    companyField: response.reviewerIndustry,
    companyName: response.reviewerCompany,
    companyPosition: response.reviewerPosition || response.review?.companyPosition,
    companySize: response.reviewerCompanySize,
    id: response.id,
    incentive: !!response.incentive,
    publishedAt: response.publishedAt || response.firstPublishedAt || response.createdAt,
    reviewerFirstName: response.review?.reviewerFirstName,
    reviewerLastName: response.review?.reviewerLastName,
    recommendationScore,
    title: response.review?.title,
    product: { title: response.review?.product?.title || props.product.title },
    video: response.review?.video,
  }
})

const reviewReply = computed(() => props.surveyResponse.review?.reviewReply || null)

const message = computed(() => {
  switch (state.value) {
    case SurveyResponseState.Rejected:
      return {
        headline: t('Reviews.product.review.rejected.headline'),
        text: t('Reviews.product.review.rejected.text'),
        type: 'error' as const,
      }
    case SurveyResponseState.Drafted:
    case SurveyResponseState.Stale:
      return {
        headline: t('Reviews.product.review.draftedOrStale.headline'),
        text: t('Reviews.product.review.draftedOrStale.message', { authorName: review.value.reviewerFirstName }),
        type: 'info' as const,
      }
    case SurveyResponseState.ReDrafted:
      return {
        headline: t('Reviews.product.review.reDrafted.headline'),
        text: t('Reviews.product.review.reDrafted.message', { authorName: review.value.reviewerFirstName }),
        type: 'info' as const,
      }
    default:
      return null
  }
})

const canSendReminder = computed(
  () =>
    !props.reminderDisabled &&
    [SurveyResponseState.Drafted, SurveyResponseState.ReDrafted, SurveyResponseState.Rejected, SurveyResponseState.Stale].includes(
      state.value,
    ),
)
</script>
