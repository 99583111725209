<template>
  <div class="flex flex-row gap-10">
    <div class="grow-0">
      <span class="block text-body-l">{{ quotaAvailableText }}</span>
      <span class="block text-body-s">{{ t('InvitationQuota.available') }}</span>
    </div>

    <template v-if="invitationQuota.inviteeTracking">
      <div class="grow-0">
        <span class="block text-body-l">{{ invitationQuota.inviteeTracking.sentCount }}</span>
        <span class="block text-body-s">{{ t('InvitationQuota.sent') }}</span>
      </div>

      <div class="grow-0">
        <span class="block text-body-l">{{ invitationQuota.inviteeTracking.purchasedCount }}</span>
        <span class="block text-body-s">{{ t('InvitationQuota.purchased') }}</span>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import type { InvitationQuotaQuery } from '@/gql/myomr'

const { t } = useI18n()

const props = defineProps<{
  invitationQuota: InvitationQuotaQuery['invitationQuota']
}>()

const quotaAvailableText = computed(() => {
  return t('EventInvitations.quotaOf', {
    availableCount: props.invitationQuota.maxUsable - (props.invitationQuota.inviteeTracking?.purchasedCount || 0),
    maxUsable: props.invitationQuota.maxUsable,
  })
})
</script>
