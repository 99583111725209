<template>
  <ContentCard v-if="formData">
    <template #header>
      <h2 class="mt-6">{{ header }}</h2>

      <p class="my-4 text-body-m">
        {{ t('UserManagement.UserForm.description') }}
      </p>
    </template>

    <TextInput
      v-model="formData.email"
      type="email"
      class="form-width mt-6 mb-0"
      label="E-mail*"
      :disabled="isEditing"
      :helper-text="t('UserManagement.emailChangeWarning')"
      :error="getValidationErrors(v$.email)"
      required
      @update:model-value="v$.email.$validate"
    />

    <hr class="my-8" />

    <h2 class="mb-3">{{ t('UserManagement.assignRights') }}<span v-if="isCreating">*</span></h2>
    <p v-if="isCreating" class="mb-6">
      {{ t('UserManagement.rolesDescription') }}
    </p>
    <p v-if="isEditing" class="mb-6">
      {{ t('UserManagement.deleteUser.message') }}
    </p>

    <div class="grid grid-cols-3">
      <div v-for="roleName in availableRoleNames" :key="roleName" class="mb-6">
        <MultiCheckBox v-model="formData.roleNames" :value="roleName" class="text-grey-800">
          <label>
            {{ t(`UserManagement.Roles.${roleName}.label`) }}
          </label>
        </MultiCheckBox>
      </div>
    </div>
    <template #footer>
      <div class="text-left text-md-right">
        <RouterLink class="btn-text-purple-m mr-4" :to="{ name: 'userList' }">
          {{ t('cancel') }}
        </RouterLink>

        <slot name="submit" :email="formData.email" :role-names="formData.roleNames">
          <button class="btn-primary-purple-m" :disabled="isSubmitDisabled" @click="submit">
            {{ props.submitLabel }}
          </button>
        </slot>
      </div>
    </template>
  </ContentCard>
</template>
<script setup lang="ts">
import { TextInput } from '@ramp106/omrjs-core-ui'
import { useVuelidate } from '@vuelidate/core'
import { computed, reactive, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { RoleNameEnum, type UserQuery } from '@/gql/myomr'
import { getValidationErrors } from '@/helpers/validationHelper'
import { email, required, requiredIf } from '@/services/validators'
import { useMeStore } from '@/stores'

const { t } = useI18n()
const meStore = useMeStore()

export type UserType = { id?: string; email: string; roles: NonNullable<UserQuery['user']>['roles'] }

const props = defineProps<{
  header: string
  submitLabel: string
  user: UserType
}>()

const isEditing = computed(() => !!props.user.id)
const isCreating = computed(() => !props.user.id)

const formData = reactive<{ email: UserType['email']; roleNames: RoleNameEnum[] }>({
  email: '',
  roleNames: [],
})

const rules = {
  email: { required, email },
  roleNames: { requiredIfCreating: requiredIf(isCreating) },
}
const v$ = useVuelidate(rules, formData)

onMounted(() => initializeFormData())

watch(
  () => props.user,
  () => initializeFormData(),
)

function initializeFormData() {
  if (props.user) {
    formData.email = props.user.email
    formData.roleNames = props.user.roles.map((r) => r.name)
  }
}

const emit = defineEmits<{
  (e: 'submit', value: { roleNames: RoleNameEnum[]; email: string }): void
}>()

const isSubmitDisabled = computed(() => !formData.email || (isCreating.value && !formData.roleNames.length))

const selectableRoleNames = [
  RoleNameEnum.ManageDashboard,
  RoleNameEnum.ManageUserManagement,
  RoleNameEnum.ManageContentPages,
  RoleNameEnum.ManageEvents,
  RoleNameEnum.ManageSpeakers,
  RoleNameEnum.ManageTickets,
  RoleNameEnum.ManageCompanyProfile,
  RoleNameEnum.ManageBooths,
  RoleNameEnum.ManageLeadGeneration,
  RoleNameEnum.ManageMyLeads,
  RoleNameEnum.ManageReviewsManagement,
]

const availableRoleNames = computed(() => {
  const userRolesNames = meStore.currentRoles.map((r) => r.name)
  return selectableRoleNames.filter((r) => userRolesNames.includes(r))
})

function submit() {
  v$.value.$validate()
  if (!v$.value.$error) emit('submit', { roleNames: formData.roleNames, email: formData.email })
}
</script>

<style scoped>
.listing-container {
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.text-width {
  width: 100%;
  max-width: 678px;
}

.form-width {
  width: 100%;
  max-width: 450px;
}
</style>
