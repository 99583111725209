<template>
  <div class="flex flex-row gap-2 grow-0">
    <template v-if="props.currentActiveTab == 'activated'">
      <button
        v-if="showDeactivationFeature"
        :disabled="!props.selectedTicketIds.length"
        class="btn-secondary-black-m"
        @click="showConfirmationModal = 'deactivate'"
      >
        <SvgIcon :path="mdiCancel" :size="16" class="mr-2" />
        {{ t('InvitationQuota.CrewQuota.deactivateButton') }}
      </button>
      <DeactivateInternalTicketsModal
        v-if="showConfirmationModal == 'deactivate'"
        :ticket-ids="props.selectedTicketIds"
        @close-dialog="handleClose"
      />

      <button class="btn-secondary-error-m" :disabled="!canDeleteTickets" @click="showConfirmationModal = 'delete'">
        <SvgIcon :path="mdiDelete" :size="16" class="mr-2" />
        {{ t('InvitationQuota.CrewQuota.deleteButton') }}
      </button>
      <DeleteInternalTicketsModal
        v-if="showConfirmationModal == 'delete'"
        :ticket-ids="props.selectedTicketIds"
        :has-selected-swapped-tickets="hasSelectedSwappedTickets"
        @close-dialog="handleClose"
      />
    </template>

    <template v-if="props.currentActiveTab == 'requests'">
      <button
        class="btn-secondary-mint-m"
        :disabled="
          !props.selectedTicketIds.length || props.isLimitReached || isDeadlineReachedAndRelevantForQuota || selectedTicketLimitReached
        "
        @click="acceptTickets"
      >
        <SvgIcon :path="mdiCheck" :size="16" class="mr-2" />
        {{ t('InvitationQuota.CrewQuota.acceptButton') }}
      </button>

      <button class="btn-secondary-error-m" :disabled="!props.selectedTicketIds.length" @click="showConfirmationModal = 'deny'">
        <SvgIcon :path="mdiClose" :size="16" class="mr-2" />
        {{ t('InvitationQuota.CrewQuota.denyButton') }}
      </button>
      <DenyInternalTicketsModal v-if="showConfirmationModal == 'deny'" :ticket-ids="props.selectedTicketIds" @close-dialog="handleClose" />
    </template>

    <template v-if="props.currentActiveTab == 'deactivated'">
      <button
        :disabled="
          !props.selectedTicketIds.length || props.isLimitReached || isDeadlineReachedAndRelevantForQuota || selectedTicketLimitReached
        "
        class="btn-secondary-mint-m"
        @click="showConfirmationModal = 'activate'"
      >
        <SvgIcon :path="mdiCheck" :size="16" class="mr-2" />
        {{ t('InvitationQuota.CrewQuota.activateButton') }}
      </button>
      <ActivateInternalTicketsModal
        v-if="showConfirmationModal == 'activate'"
        :ticket-ids="props.selectedTicketIds"
        @close-dialog="handleClose"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { mdiDelete, mdiCancel, mdiCheck, mdiClose } from '@mdi/js'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAcceptTicketRequestMutation, type TicketsQuery, type InvitationQuotaQuery } from '@/gql/myomr'

import type { TabEnum } from './activeTab'
import handleTicketMutationErrors from './handleTicketMutationErrors'
import useQuotaConfig, { type InternalQuotaTypeEnum } from './quotaConfig'

const { t } = useI18n()

const { mutate: acceptTicketRequest } = useAcceptTicketRequestMutation()

const props = defineProps<{
  currentActiveTab: TabEnum
  invitationQuota: InvitationQuotaQuery['invitationQuota']
  isDeadlineReached: boolean
  isLimitReached: boolean | undefined
  selectedTicketIds: string[]
  tickets: TicketsQuery['tickets']
}>()

const emit = defineEmits<{
  (e: 'refetch', value: boolean): void
  (e: 'changeTicketSelection', value: string[]): void
}>()

const showConfirmationModal = ref<'activate' | 'deactivate' | 'delete' | 'deny' | false>(false)

const quotaConfig = useQuotaConfig(props.invitationQuota.kind as InternalQuotaTypeEnum)

const showDeactivationFeature = computed(() => quotaConfig.value.showDeactivationFeature)

const selectedTicketLimitReached = computed(() => {
  return props.invitationQuota.maxUsable - (props.invitationQuota.usableTicketsCount || 0) < props.selectedTicketIds.length
})

const isDeadlineReachedAndRelevantForQuota = computed(() => props.isDeadlineReached && quotaConfig.value.respectDeadline)

const hasSelectedSwappedTickets = computed(() =>
  (props.tickets.nodes || []).some((ticket) => ticket.swapped && props.selectedTicketIds.includes(ticket.id)),
)
const canDeleteTickets = computed(() => props.selectedTicketIds.length && !hasSelectedOnlySwappedTickets.value)
const hasSelectedOnlySwappedTickets = computed(
  () =>
    props.selectedTicketIds.length &&
    props.selectedTicketIds.every((ticketId) => {
      const ticket = props.tickets.nodes?.find((ticket) => ticket.id === ticketId)
      return ticket?.swapped
    }),
)

function handleClose(hasChangedTickets: boolean) {
  if (hasChangedTickets) emit('changeTicketSelection', [])
  showConfirmationModal.value = false
}

async function acceptTickets() {
  const responses = await Promise.allSettled(props.selectedTicketIds.map((ticketId) => acceptTicketRequest({ ticketId })))
  const errorsMap = responses.map((response) => {
    if (response.status === 'fulfilled' && response.value?.data?.acceptTicketRequest?.errors) {
      return response.value.data.acceptTicketRequest.errors
    }
  })
  handleTicketMutationErrors(
    t,
    errorsMap,
    'InvitationQuota.CrewQuota.errorTicketAcceptNotification',
    'InvitationQuota.CrewQuota.successTicketAcceptNotification',
  )
  refetch()
}
const refetch = (showSuccessModal = false) => emit('refetch', showSuccessModal)
</script>
