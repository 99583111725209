<template>
  <TabBar>
    <NavTabBarItem
      :disabled="disabled"
      :is-selected="route.meta?.tabId === ReviewsRouteTabs.Details"
      :to="{ name: ReviewsRouteName.ReviewsProductViewDetails }"
    >
      {{ t('Reviews.product.view.tab.details') }}
    </NavTabBarItem>
    <NavTabBarItem
      :disabled="disabled"
      :is-selected="route.meta?.tabId === ReviewsRouteTabs.GlobalFeatures"
      :to="{ name: ReviewsRouteName.ReviewsProductViewGlobalFeatures }"
    >
      {{ t('Reviews.product.view.tab.globalFeatures') }}
    </NavTabBarItem>
    <NavTabBarItem
      :disabled="disabled"
      :is-selected="route.meta?.tabId === ReviewsRouteTabs.Pricing"
      :to="{ name: ReviewsRouteName.ReviewsProductViewPricing }"
    >
      {{ t('Reviews.product.view.tab.pricing') }}
    </NavTabBarItem>
    <NavTabBarItem
      :disabled="disabled"
      :is-selected="route.meta?.tabId === ReviewsRouteTabs.Assets"
      :to="{ name: ReviewsRouteName.ReviewsProductViewAssets }"
    >
      {{ t('Reviews.product.view.tab.assets') }}
    </NavTabBarItem>
    <NavTabBarItem
      :disabled="disabled"
      :is-selected="route.meta?.tabId === ReviewsRouteTabs.Reviews"
      :to="{ name: ReviewsRouteName.ReviewsProductViewReviews }"
    >
      {{ t('Reviews.product.view.tab.reviews') }}
    </NavTabBarItem>
    <NavTabBarItem
      :disabled="disabled"
      :is-selected="route.meta?.tabId === ReviewsRouteTabs.Preferences"
      :to="{ name: ReviewsRouteName.ReviewsProductViewPreferences }"
    >
      {{ t('Reviews.product.view.tab.preferences') }}
    </NavTabBarItem>
  </TabBar>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { ReviewsRouteName, ReviewsRouteTabs } from '@/router/types'
import { TabBar, NavTabBarItem } from '@/ui/Tabs'

const { t } = useI18n()
const route = useRoute()

const disabled = computed(() => {
  return route.name?.toString().startsWith(ReviewsRouteName.ReviewsProductEdit)
})
</script>
