<template>
  <LabelValue :label="t('Reviews.product.view.contact.label').toString()">
    <div class="grid grid-cols-2 gap-6">
      <div>
        <div class="text-body-m-bold mb-1">
          {{ t('Reviews.product.view.contact.primary.label') }}
        </div>
        <div class="text-body-s text-grey-600 mb-4">
          {{ t('Reviews.product.view.contact.primary.hint') }}
        </div>
        <span data-testid="cta-primary-checkbox">
          <CheckBox v-model="v$.ctaPrimaryEnabled.$model" class="pb-4">
            {{ t('Reviews.product.edit.contact.ctaPrimaryEnabled.label') }}
          </CheckBox>
        </span>
      </div>
      <div class="bg-grey-100 px-10 py-8 mb-6">
        <p class="text-body-m-bold text-grey-800 pb-4">
          {{
            formData.ctaPrimaryEnabled
              ? t('Reviews.product.view.contact.primary.preview.headline')
              : t('Reviews.product.view.contact.primary.preview.headlineHidden')
          }}
        </p>
        <SlideTransition>
          <button v-if="formData.ctaPrimaryEnabled" class="btn-primary-yellow-m" data-testid="cta-primary-preview-label">
            {{ t('Reviews.product.view.contact.primary.preview.label') }}
          </button>
        </SlideTransition>
      </div>
    </div>

    <div v-for="lang in ALLOWED_LOCALES" :key="lang" class="grid grid-cols-2 gap-6 mb-6">
      <div>
        <div class="flex justify-between">
          <div class="text-body-m-bold">
            {{ t('Reviews.product.view.contact.secondary.label') }}
          </div>
          <ChipBadge class="uppercase" rounded>{{ lang }}</ChipBadge>
        </div>
        <div
          v-if="!product.configFlags.ctaSecondaryEditable"
          class="text-body-s text-grey-600 mb-4"
          v-html="t('Reviews.product.view.contact.secondary.notSupported').toString()"
        />
        <TextInput
          v-model="v$.ctaSecondaryLabel[lang].$model"
          :disabled="!product.configFlags.ctaSecondaryEditable"
          :label="t('Reviews.product.edit.contact.ctaSecondary.btnLabel.label').toString()"
          :placeholder="t('Reviews.product.edit.contact.ctaSecondary.btnLabel.placeholder').toString()"
          :error="getValidationErrors(v$.ctaSecondaryLabel[lang])"
          :data-testid="`cta-secondary-label-${lang}`"
          :max-length="BTN_LABEL_MAX_LENGTH"
        />
        <TextInput
          v-model="v$.ctaSecondaryUrl[lang].$model"
          :class="{ '-mb-1': !getValidationErrors(v$.ctaSecondaryUrl[lang]) }"
          :disabled="!product.configFlags.ctaSecondaryEditable"
          dense
          :label="t('Reviews.product.edit.contact.ctaSecondary.btnUrl.label').toString()"
          :placeholder="t('Reviews.product.edit.contact.ctaSecondary.btnUrl.placeholder').toString()"
          :error="getValidationErrors(v$.ctaSecondaryUrl[lang])"
          :data-testid="`cta-secondary-url-${lang}`"
        />
      </div>
      <div class="bg-grey-100 px-10 py-8">
        <p class="text-body-m-bold text-grey-800 pb-4">{{ t('Reviews.product.view.contact.secondary.preview.headline') }}</p>
        <BtnLink
          class="btn-secondary-black-m"
          :class="{ '!text-black': product.configFlags.ctaSecondaryEditable }"
          :to="ctaSecondaryPreviewUrl[lang]"
          target="_blank"
          :disabled="!product.configFlags.ctaSecondaryEditable"
          :data-testid="`cta-secondary-btn-preview-${lang}`"
        >
          {{ formData.ctaSecondaryLabel[lang] || t('Reviews.product.view.contact.secondary.preview.label') }}
        </BtnLink>
      </div>
    </div>
  </LabelValue>
</template>

<script setup lang="ts">
import { BtnLink, CheckBox, ChipBadge, SlideTransition, TextInput } from '@ramp106/omrjs-core-ui'
import { useVuelidate } from '@vuelidate/core'
import { computed, reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { ProductCtaFormData } from '@/components/ReviewsManagement'
import { type ProductData } from '@/gql/reviews'
import { ALLOWED_LOCALES } from '@/helpers/translationsHelper'
import { buildTranslationFieldsRules, getValidationErrors } from '@/helpers/validationHelper'
import { requiredIf, maxLength, minLength, url } from '@/services/validators'
import LabelValue from '@/ui/LabelValue.vue'

const BTN_LABEL_MIN_LENGTH = 3
const BTN_LABEL_MAX_LENGTH = 20
const props = defineProps<{
  product: Pick<ProductData, 'configFlags' | 'ctaSecondaryLabelTranslationsMap' | 'ctaSecondaryUrlTranslationsMap'>
}>()
const emit = defineEmits(['change'])

const { t } = useI18n()

const formData = reactive<ProductCtaFormData>({
  ctaPrimaryEnabled: props.product.configFlags.ctaPrimaryActive,
  ctaSecondaryLabel: { ...props.product.ctaSecondaryLabelTranslationsMap },
  ctaSecondaryUrl: { ...props.product.ctaSecondaryUrlTranslationsMap },
})

const rules = computed(() => ({
  ctaPrimaryEnabled: {},
  ctaSecondaryLabel: {
    ...buildTranslationFieldsRules(
      {
        requiredIf: requiredIf(() => !!formData.ctaSecondaryUrl[ALLOWED_LOCALES[0]]),
        maxLength: maxLength(BTN_LABEL_MAX_LENGTH),
        minLength: minLength(BTN_LABEL_MIN_LENGTH),
      },
      [ALLOWED_LOCALES[0]],
    ),
    ...buildTranslationFieldsRules(
      {
        maxLength: maxLength(BTN_LABEL_MAX_LENGTH),
        minLength: minLength(BTN_LABEL_MIN_LENGTH),
      },
      ALLOWED_LOCALES.slice(1),
    ),
  },
  ctaSecondaryUrl: {
    ...buildTranslationFieldsRules(
      {
        requiredIf: requiredIf(() => !!formData.ctaSecondaryLabel[ALLOWED_LOCALES[0]]),
        url,
      },
      [ALLOWED_LOCALES[0]],
    ),
    ...buildTranslationFieldsRules({ url }, ALLOWED_LOCALES.slice(1)),
  },
}))

const v$ = useVuelidate(rules, formData)

const fallbackLang = ALLOWED_LOCALES[0]

const ctaSecondaryPreviewUrl = computed(() => {
  const fallbackUrl = formData.ctaSecondaryUrl[fallbackLang] || ''
  return ALLOWED_LOCALES.reduce(
    (acc, lang) => {
      acc[lang] = props.product.configFlags.ctaSecondaryEditable ? formData.ctaSecondaryUrl[lang] || fallbackUrl : undefined
      return acc
    },
    {} as Record<string, string | undefined>,
  )
})

watch(
  () => formData,
  () => {
    emit('change', formData)
  },
  { deep: true },
)
</script>
