<template>
  <div id="partnerCompanyDetails">
    <div class="mt-8">
      <h4>{{ t('PartnerCompany.logo') }}</h4>
      <img v-if="partnerCompany.logoUrl" class="max-w-[400px]" :src="partnerCompany.logoUrl" />
    </div>

    <div class="mt-8">
      <h4>{{ t('PartnerCompany.webLogo') }}</h4>
      <img v-if="partnerCompany.webLogoUrl" class="max-w-[400px]" :src="partnerCompany.webLogoUrl" />
    </div>

    <div class="mt-8">
      <h4>{{ t('PartnerCompany.name') }}</h4>
      {{ partnerCompany.displayName }}
    </div>

    <div v-if="partnerCompany.website" class="mt-8">
      <h4>{{ t('PartnerCompany.website') }}</h4>
      <a :href="partnerCompany.website">
        {{ partnerCompany.website }}
      </a>
    </div>

    <div class="mt-8">
      <h4>{{ t('PartnerCompany.descriptionDe') }}</h4>
      {{ partnerCompany.descriptionDe }}
    </div>

    <div class="mt-8">
      <h4>{{ t('PartnerCompany.descriptionEn') }}</h4>
      {{ partnerCompany.descriptionEn }}
    </div>

    <div class="mt-8">
      <h4>{{ t('PartnerCompany.interests') }}</h4>
      <div class="chips-list">
        <div v-for="interest in partnerCompany.interests" :key="interest.id" class="chip" label color="primary">
          {{ interest.text }}
        </div>
      </div>
    </div>

    <div v-if="partnerCompany.hasFoodBooths" class="mt-8">
      <h4>{{ t('PartnerCompany.foodTypes') }}</h4>
      <div class="chips-list">
        <div v-for="foodType in partnerCompany.foodTypes" :key="`foodType-${foodType}`" class="chip" label color="primary">
          <SvgIcon :path="getFoodTypeIcon(foodType)" class="mr-1" />
          {{ foodType }}
        </div>
      </div>
    </div>

    <hr class="mt-8" />

    <h4>{{ t('PartnerCompany.socialMediaLinks') }}</h4>

    <div v-for="sm in socialMedia" :key="sm.linkType" class="v-card__block">
      <h4>{{ t(`PartnerCompany.${sm.linkType}`) }}</h4>
      <a :href="sm.link">{{ sm.link }}</a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import type { PartnerCompany, PartnerCompanyFragment } from '@/gql/myomr'
import getFoodTypeIcon from '@/helpers/getFoodTypeIcon'

const { t } = useI18n()

const props = defineProps<{
  partnerCompany: PartnerCompany
}>()

const socialMediaLinkTypes: (keyof PartnerCompanyFragment)[] = ['twitter', 'facebook', 'youtube', 'instagram', 'linkedinUrl']

const socialMedia = computed(() => {
  return socialMediaLinkTypes
    .map((linkType) => {
      const link = props.partnerCompany[linkType] as string | null
      if (link && link.length) return { linkType, link }
    })
    .flatMap((link) => (link ? [link] : []))
})
</script>

<style scoped>
a {
  font-weight: 600;
  text-decoration: none;
}

h4 {
  margin-bottom: 10px;
  color: #000 !important;
  font-size: 16px;
  font-weight: 700;
}
</style>
