<template>
  <ContentCard :loading="loadingUpdateProduct">
    <template #header>
      <ReviewsProductTabs />
    </template>

    <template #subheader>
      <ContentCardSubheader :back-to="{ name: ReviewsRouteName.ReviewsProductViewPricing }">
        {{ t('Reviews.product.edit.pricingTitle', { productName: product.title }) }}
        <template #actions>
          <RouterLink
            class="btn-secondary-purple-m mr-2"
            :to="{ name: ReviewsRouteName.ReviewsProductViewPricing, params: { productId: product.id } }"
          >
            {{ t('Reviews.product.action.cancel') }}
          </RouterLink>
          <button class="btn-primary-purple-m" @click="onSubmit">{{ t('Reviews.product.action.save') }}</button>
        </template>
      </ContentCardSubheader>
    </template>
    <div class="py-6">
      <div class="mb-6">
        <RadioButton v-model="v$.noPricingPlans.$model" class="mr-4" :value="true">
          {{ t('Reviews.product.edit.pricing.noPricingPlans.yes') }}
        </RadioButton>
        <RadioButton v-model="v$.noPricingPlans.$model" :value="false">
          {{ t('Reviews.product.edit.pricing.noPricingPlans.no') }}
        </RadioButton>
      </div>
      <div v-if="formData.noPricingPlans" class="grid grid-cols-2 gap-6">
        <div>
          <TextInput v-for="lang in ALLOWED_LOCALES" :key="lang" v-model="v$.noPricingPlansHint[lang].$model" :rows="5" type="textarea">
            <template #label>
              <div class="flex justify-between items-end">
                <span>{{ t('Reviews.product.edit.pricing.noPricingPlansHint.label') }}</span>
                <ChipBadge class="uppercase" rounded>{{ lang }}</ChipBadge>
              </div>
            </template>
          </TextInput>
        </div>
        <MessageBanner
          class="h-fit mt-7"
          :headline="t('Reviews.product.edit.pricing.noPricingPlansHint.info.headline').toString()"
          :message="t('Reviews.product.edit.pricing.noPricingPlansHint.info.message').toString()"
          type="info"
        />
      </div>
      <div>
        <div class="text-body-m-bold pb-4">{{ t('Reviews.product.edit.pricing.pricingOptions.label') }}</div>
        <CheckBox v-model="v$.showDisclaimer.$model" class="pb-4 w-full">
          {{ t('Reviews.product.edit.pricing.showDisclaimer.label') }}
        </CheckBox>
        <div class="flex gap-x-1 mb-4 -mt-2 -ml-0.5 text-grey-800">
          <MdiSvg class="inline-block text-purple-600" :path="mdiInformationOutline" />{{
            t('Reviews.product.edit.pricing.disclaimer.info.message')
          }}
        </div>
        <SlideTransition>
          <div v-if="formData.showDisclaimer" class="grid grid-cols-2 gap-6 pb-2">
            <div v-for="lang in ALLOWED_LOCALES" :key="lang">
              <TextInput
                v-model="v$.disclaimerText[lang].$model"
                class="mb-4"
                :placeholder="t('Reviews.product.edit.pricing.disclaimerText.placeholder').toString()"
                :error="getValidationErrors(v$.disclaimerText[lang])"
                :max-length="DISCLAIMER_MAX_LENGTH"
              >
                <template #label>
                  <div class="flex justify-between items-end">
                    <span>{{ t('Reviews.product.edit.pricing.disclaimerText.label') }}</span>
                    <ChipBadge class="uppercase" rounded>{{ lang }}</ChipBadge>
                  </div>
                </template>
              </TextInput>
              <TextInput
                v-model="v$.disclaimerUrl[lang].$model"
                :placeholder="t('Reviews.product.edit.pricing.disclaimerUrl.placeholder').toString()"
                :error="getValidationErrors(v$.disclaimerUrl[lang])"
                type="url"
              >
                <template #label>
                  <div class="flex justify-between items-end">
                    <span>{{ t('Reviews.product.edit.pricing.disclaimerUrl.label') }}</span>
                    <ChipBadge class="uppercase" rounded>{{ lang }}</ChipBadge>
                  </div>
                </template>
              </TextInput>
            </div>
          </div>
        </SlideTransition>
        <CheckBox v-model="v$.freeDemo.$model" class="pb-4 w-full">
          {{ t('Reviews.product.edit.pricing.freeDemo.label') }}
        </CheckBox>
        <CheckBox v-model="v$.freeTrial.$model" class="pb-4 w-full">
          {{ t('Reviews.product.edit.pricing.freeTrial.label') }}
        </CheckBox>
        <SlideTransition>
          <div v-if="formData.freeTrial" class="grid grid-cols-2 gap-6">
            <SelectInput
              v-model="v$.trialUnit.$model"
              :label="t('Reviews.product.edit.pricing.trialUnit.label').toString()"
              :placeholder="t('Reviews.product.edit.pricing.trialUnit.placeholder').toString()"
              :error="getValidationErrors(v$.trialUnit)"
            >
              <option v-for="period in productOptions.trialUnits" :key="period" :value="period">
                {{ t(`Reviews.product.edit.pricing.trialUnit.${period}`) }}
              </option>
            </SelectInput>
            <TextInput
              v-model.number="v$.trialPeriod.$model"
              :error="getValidationErrors(v$.trialPeriod)"
              :label="t('Reviews.product.edit.pricing.trialPeriod.label').toString()"
              :placeholder="t('Reviews.product.edit.pricing.trialPeriod.placeholder').toString()"
            />
          </div>
        </SlideTransition>
      </div>
      <div v-if="!formData.noPricingPlans">
        <div class="text-body-m-bold pb-4">{{ t('Reviews.product.edit.pricing.pricingPlans.label') }}</div>
        <ExpandableView
          v-for="(plan, index) in formData.pricingPlans"
          :key="index"
          ref="pricingPlansRef"
          class="pb-2 transition-opacity duration-75 ease-in-out"
          :disabled="plan._destroy"
        >
          <template #name>{{
            plan.name[ALLOWED_LOCALES[0]] || t('Reviews.product.edit.pricing.pricingPlans.name', { number: index + 1 })
          }}</template>
          <template #actions>
            <button v-if="!plan._destroy" class="btn-text-black-m" @click.stop="deletePricingPlan(index)">
              <span>{{ t('Reviews.product.edit.pricing.actions.deletePlan') }}</span>
              <MdiSvg class="ml-1" :size="18" :path="mdiTrashCanOutline" />
            </button>
            <button v-else class="btn-text-black-m" @click.stop="undoDeletePricingPlan(index)">
              <span>{{ t('Reviews.product.edit.pricing.actions.undoDeletePlan') }}</span>
              <MdiSvg class="ml-1" :size="18" :path="mdiUndo" />
            </button>
            <button class="btn-text-black-m ml-3" :disabled="index === 0" @click.stop="moveUp(index)">
              <MdiSvg :size="18" :path="mdiArrowUp" />
            </button>
            <button class="btn-text-black-m ml-3" :disabled="index === formData.pricingPlans.length - 1" @click.stop="moveDown(index)">
              <MdiSvg :size="18" :path="mdiArrowDown" />
            </button>
          </template>
          <div class="grid grid-cols-2 gap-6">
            <PricingPlanForm
              :key="index"
              :value="formData.pricingPlans[index]"
              :currencies-options="productOptions.currencies"
              :pricing-text-options="productOptions.pricingTextOptions"
              :subscription-modes="productOptions.pricingSubscriptionModes"
              :pricing-enabled="!formData.noPricingPlans"
              @input="formData.pricingPlans[index] = $event"
            />
            <div class="bg-grey-100">
              <PricingPlanCardPreview
                v-for="lang in ALLOWED_LOCALES"
                :key="lang"
                :lang="lang"
                :preview-name="t('Reviews.product.edit.pricing.pricingPlans.name', { number: index + 1 }).toString()"
                :pricing="formData.pricingPlans[index]"
                :currencies-options="productOptions.currencies"
                :pricing-text-options="productOptions.pricingTextOptions"
                :subscription-modes="productOptions.pricingSubscriptionModes"
              />
            </div>
          </div>
        </ExpandableView>

        <button v-if="formData.pricingPlans.length < PRICING_PLANS_LIMIT" class="mt-2 btn-primary-purple-m" @click.prevent="addPricingPlan">
          <MdiSvg :path="mdiPlusCircleOutline" class="mr-2" />
          {{ t('Reviews.product.edit.pricing.actions.addPlan', formData.pricingPlans.length + 1) }}
        </button>
      </div>
    </div>
  </ContentCard>
</template>
<script setup lang="ts">
import { mdiTrashCanOutline, mdiPlusCircleOutline, mdiUndo, mdiArrowUp, mdiArrowDown, mdiInformationOutline } from '@mdi/js'
import { MessageBanner, RadioButton, TextInput, MdiSvg, CheckBox, SelectInput, SlideTransition, ChipBadge } from '@ramp106/omrjs-core-ui'
import { useVuelidate, type ValidationArgs } from '@vuelidate/core'
import { ref, computed, reactive, watch, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import ReviewsProductTabs from '@/components/ReviewsManagement/ReviewsProductTabs.vue'
import type { ProductData, TranslationAttributes } from '@/gql/reviews'
import { ProductTrialUnitEnum, useReviewsProductOptionsQuery, useUpdateReviewsProductWithPricingPlansMutation } from '@/gql/reviews'
import { showNotification } from '@/helpers/notificationHelper'
import {
  ALLOWED_LOCALES,
  mapTranslationsArrToObj,
  mapPerksTranslations,
  mapPerksFormDataToPerksTranslations,
} from '@/helpers/translationsHelper'
import { buildTranslationFieldsRules, getValidationErrors } from '@/helpers/validationHelper'
import { ReviewsRouteName } from '@/router/types'
import { requiredIf, url, maxLength, minLength } from '@/services/validators'
import { reloadReviewsProductKey } from '@/symbols/reloadReviewsProductKey'
import ContentCard from '@/ui/ContentCard.vue'
import ContentCardSubheader from '@/ui/ContentCardSubheader.vue'
import ExpandableView from '@/ui/ExpandableView.vue'

import PricingPlanForm from './PricingPlanForm.vue'
import type { PricingPlanFormData } from '../types'
import PricingPlanCardPreview from './PricingPlanCardPreview.vue'

interface FormData {
  freeDemo: boolean | undefined
  freeTrial: boolean | undefined
  trialUnit: ProductTrialUnitEnum | undefined
  trialPeriod: number | undefined
  showDisclaimer: boolean
  disclaimerText: Record<string, string>
  disclaimerUrl: Record<string, string>
  noPricingPlans: boolean
  noPricingPlansHint: Record<string, string>
  pricingPlans: PricingPlanFormData[]
}

const DISCLAIMER_MIN_LENGTH = 3
const DISCLAIMER_MAX_LENGTH = 120
const PRICING_PLANS_LIMIT = 20

const props = defineProps<{
  product: ProductData
}>()

const { t } = useI18n()
const router = useRouter()
const reloadReviewsProduct = inject(reloadReviewsProductKey) as () => void
const pricingPlansRef = ref<(typeof ExpandableView)[] | undefined>()

const { result: productOptionsQuery } = useReviewsProductOptionsQuery({ clientId: 'reviews' })

const productOptions = computed(() => {
  const trialUnits = [ProductTrialUnitEnum.Day, ProductTrialUnitEnum.Week, ProductTrialUnitEnum.Month, ProductTrialUnitEnum.Year]
  if (productOptionsQuery.value?.manage?.options) {
    return {
      pricingTextOptions: productOptionsQuery.value.manage.options.pricingTextOptions.slice(0).sort((a, b) => a.name.localeCompare(b.name)),
      pricingSubscriptionModes: productOptionsQuery.value.manage.options.pricingSubscriptionModes
        .slice(0)
        .sort((a, b) => a.name.localeCompare(b.name)),
      currencies: productOptionsQuery.value.manage.options.currencies.slice(0).sort((a, b) => a.name.localeCompare(b.name)),
      trialUnits,
    }
  }

  return {
    pricingTextOptions: [],
    pricingSubscriptionModes: [],
    currencies: [],
    trialUnits,
  }
})

const formData = reactive<FormData>({
  freeDemo: props.product.freeDemo ?? false,
  freeTrial: props.product.freeTrial ?? false,
  trialUnit: props.product.trialUnit ?? undefined,
  trialPeriod: props.product.trialPeriod ?? undefined,
  showDisclaimer: props.product.showDisclaimer ?? false,
  disclaimerText: { ...props.product.disclaimerTextTranslationsMap },
  disclaimerUrl: { ...props.product.disclaimerUrlTranslationsMap },
  noPricingPlans: props.product.noPricingPlans ?? false,
  noPricingPlansHint: { ...props.product.noPricingPlansHintTranslationsMap },
  pricingPlans: [
    ...props.product.pricingPlans.map((plan, index) => ({
      _destroy: undefined,
      id: plan.id,
      description: { ...mapTranslationsArrToObj(plan.descriptionTranslations) },
      name: { ...mapTranslationsArrToObj(plan.nameTranslations) },
      perkList: mapPerksTranslations(plan.perksTranslations),
      priceCents: plan.priceCents ?? undefined,
      priceCurrency: plan.priceCurrency ?? undefined,
      pricingSubscriptionModeId: plan.pricingSubscriptionModeId ?? undefined,
      pricingTextOptionId: plan.pricingTextOptionId ?? undefined,
      startingPrice: plan.startingPrice,
      position: plan.position ?? index,
    })),
  ].sort((a, b) => a.position - b.position),
})

const rules = computed<ValidationArgs<FormData>>(() => ({
  freeDemo: {},
  freeTrial: {},
  trialUnit: { requiredIf: requiredIf(() => !!formData.freeTrial) },
  trialPeriod: { requiredIf: requiredIf(() => !!formData.freeTrial) },
  showDisclaimer: {},
  disclaimerText: {
    ...buildTranslationFieldsRules(
      {
        minLength: minLength(DISCLAIMER_MIN_LENGTH),
        maxLength: maxLength(DISCLAIMER_MAX_LENGTH),
        requiredIf: requiredIf(() => formData.showDisclaimer),
      },
      [ALLOWED_LOCALES[0]],
    ),
    ...buildTranslationFieldsRules(
      {
        minLength: minLength(DISCLAIMER_MIN_LENGTH),
        maxLength: maxLength(DISCLAIMER_MAX_LENGTH),
      },
      ALLOWED_LOCALES.slice(1),
    ),
  },
  disclaimerUrl: {
    ...buildTranslationFieldsRules(
      {
        url,
        requiredIf: requiredIf(() => formData.showDisclaimer),
      },
      [ALLOWED_LOCALES[0]],
    ),
    ...buildTranslationFieldsRules({ url }, ALLOWED_LOCALES.slice(1)),
  },
  noPricingPlans: {},
  noPricingPlansHint: buildTranslationFieldsRules({}),
  pricingPlans: {},
}))

const v$ = useVuelidate(rules, formData)

function collapseAllPricingPlans() {
  if (pricingPlansRef.value) {
    pricingPlansRef.value.forEach((ref) => ref.close())
  }
}

function animatePositionChange(index: number) {
  collapseAllPricingPlans()
  pricingPlansRef.value?.[index].$el.classList.add('opacity-10')
  setTimeout(() => {
    formData.pricingPlans.sort((a, b) => a.position - b.position)
    pricingPlansRef.value?.[index].$el.classList.remove('opacity-10')
  }, 300)
}

function addPricingPlan() {
  formData.pricingPlans.push({
    _destroy: undefined,
    description: ALLOWED_LOCALES.reduce((acc, locale) => ({ ...acc, [locale]: '' }), {}),
    id: undefined,
    name: ALLOWED_LOCALES.reduce((acc, locale) => ({ ...acc, [locale]: '' }), {}),
    perkList: [ALLOWED_LOCALES.reduce((acc, locale) => ({ ...acc, [locale]: { name: '' } }), {})],
    priceCents: undefined,
    priceCurrency: undefined,
    pricingSubscriptionModeId: undefined,
    pricingTextOptionId: undefined,
    startingPrice: false,
    position: formData.pricingPlans.length,
  })
  collapseAllPricingPlans()
}

function deletePricingPlan(index: number) {
  if (formData.pricingPlans[index].id) {
    formData.pricingPlans[index]['_destroy'] = true
  } else {
    formData.pricingPlans.splice(index, 1)
  }
}

function undoDeletePricingPlan(index: number) {
  if (formData.pricingPlans[index].id) {
    formData.pricingPlans[index]['_destroy'] = undefined
  }
}

function moveUp(index: number) {
  if (index === 0) {
    return
  }
  formData.pricingPlans[index - 1].position = formData.pricingPlans[index - 1].position + 1
  formData.pricingPlans[index].position = formData.pricingPlans[index].position - 1
  animatePositionChange(index - 1)
  animatePositionChange(index)
}

function moveDown(index: number) {
  if (index === formData.pricingPlans.length - 1) {
    return
  }
  formData.pricingPlans[index].position = formData.pricingPlans[index].position + 1
  formData.pricingPlans[index + 1].position = formData.pricingPlans[index + 1].position - 1
  animatePositionChange(index)
  animatePositionChange(index + 1)
}

watch(
  () => formData.pricingPlans.length,
  (newLength, oldLength) => {
    if (newLength > oldLength) {
      setTimeout(() => {
        if (!pricingPlansRef.value) {
          return
        }
        const lastRef = pricingPlansRef.value[pricingPlansRef.value.length - 1]
        if (lastRef) {
          lastRef.open()
          lastRef.$el.scrollIntoView({ behavior: 'smooth' })
        }
      }, 50)
    }
  },
)

const {
  mutate: mutateProductWithPricingPlans,
  loading: loadingUpdateProduct,
  onDone,
  onError,
} = useUpdateReviewsProductWithPricingPlansMutation({
  clientId: 'reviews',
})

async function onSubmit() {
  v$.value.$touch()
  if (v$.value.$invalid) {
    showNotification(t('Reviews.product.notifications.invalidData'), 'error')
    return
  }

  const freeTrial = formData.freeTrial
  let trialPeriod = undefined
  let trialUnit = undefined

  if (freeTrial !== undefined) {
    trialPeriod = freeTrial ? formData.trialPeriod : null
    trialUnit = freeTrial ? formData.trialUnit : null
  }

  const noPricingPlansHintTranslations: TranslationAttributes[] = []
  const disclaimerTextTranslations: TranslationAttributes[] = []
  const disclaimerUrlTranslations: TranslationAttributes[] = []

  ALLOWED_LOCALES.forEach((locale) => {
    noPricingPlansHintTranslations.push({ locale, value: formData.noPricingPlansHint[locale] })
    disclaimerTextTranslations.push({ locale, value: formData.disclaimerText[locale] })
    disclaimerUrlTranslations.push({ locale, value: formData.disclaimerUrl[locale] })
  })

  const pricingPlansAttributes = formData.pricingPlans.map((plan) => ({
    _destroy: plan._destroy,
    descriptionTranslations: ALLOWED_LOCALES.map((locale) => ({ locale, value: plan.description[locale] })),
    id: plan.id,
    nameTranslations: ALLOWED_LOCALES.map((locale) => ({ locale, value: plan.name[locale] })),
    priceCents: plan.priceCents,
    priceCurrency: plan.priceCurrency,
    pricingSubscriptionModeId: plan.pricingSubscriptionModeId,
    pricingTextOptionId: plan.pricingTextOptionId,
    startingPrice: plan.startingPrice,
    position: plan.position,
    perksTranslations: mapPerksFormDataToPerksTranslations(plan.perkList),
  }))

  await mutateProductWithPricingPlans({
    id: props.product.id,
    attributes: {
      noPricingPlans: formData.noPricingPlans,
      noPricingPlansHintTranslations,
      showDisclaimer: formData.showDisclaimer,
      disclaimerTextTranslations,
      disclaimerUrlTranslations,
      freeDemo: formData.freeDemo,
      freeTrial,
      trialPeriod,
      trialUnit,
    },
    pricing: { pricingPlansAttributes, productId: props.product.id },
  })
}

onDone(() => {
  reloadReviewsProduct()
  showNotification(t('Reviews.product.notifications.savedSuccessfully', { name: props.product.title }), 'success')
  router.push({ name: ReviewsRouteName.ReviewsProductViewPricing, params: { productId: props.product.id } })
})

onError((error) => showError(error))

const showError = (error: Error) => {
  if (error) showNotification(error.message, 'error')
}
</script>
