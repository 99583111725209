<template>
  <div class="flex">
    <div v-if="prospectLists" class="w-[200px] pt-7">
      <div v-for="item of prospectLists" :key="item.id" class="pb-6">
        <RouterLink
          :to="{ name: 'prospectList', params: { listId: toPlainId(item.id) } }"
          class="text-black"
          :class="{ 'font-bold': isActiveList(item.id) }"
        >
          {{ item.title }}
        </RouterLink>
      </div>
      <RouterLink :to="{ query: { modal: 'newProspectList' } }" class="btn-text-black-s text-black mb-7">
        <SvgIcon :path="mdiPlusCircleOutline" :size="16" />
        <span class="ml-1">{{ t('ProspectsCatalog.createList') }}</span>
      </RouterLink>
    </div>
    <RouterView
      :facet-items="props.facetItems"
      :me="props.me"
      :prospect-lists="props.prospectLists"
      @create-prospect-item="emit('createProspectItem', $event)"
      @delete-prospect-item="emit('deleteProspectItem', $event)"
      @refetch-prospect-lists="emit('refetchProspectLists')"
    />
    <NewProspectListModal
      v-if="route.query['modal'] == 'newProspectList'"
      :event="event"
      :me="props.me"
      @refetch-prospect-lists="emit('refetchProspectLists')"
    />
  </div>
</template>

<script setup lang="ts">
import { mdiPlusCircleOutline } from '@mdi/js'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import type { ProspectFacetItems } from '@/components/Prospects/prospectFacetItems'
import type { Event, MeQuery } from '@/gql/myomr'
import { toPlainId } from '@/helpers/globalIdHelper'

import type { ProspectList } from './types'
const { t } = useI18n()

const route = useRoute()

const props = defineProps<{
  facetItems: ProspectFacetItems
  me: NonNullable<MeQuery['me']>
  prospectLists: ProspectList[]
  slug: string
  event: Event
}>()

const isActiveList = (listId: string) => toPlainId(listId) === route.params.listId

const emit = defineEmits<{
  (e: 'createProspectItem', value: { prospectId: string; prospectListId: string }): void
  (e: 'deleteProspectItem', value: { prospectId: string; prospectListId: string }): void
  (e: 'refetchProspectLists'): void
}>()
</script>
