<template>
  <ModalDialog
    ref="modal"
    data-testid="review-reply-dialog"
    persistent
    max-height="95vh"
    max-width="648px"
    :title="t('Reviews.reviewReplyDialog.title')"
    @closed="$emit('closed')"
  >
    <div class="text-body-s mb-6">{{ t('Reviews.reviewReplyDialog.text') }}</div>
    <MessageBanner class="my-6" :message="t('Reviews.sendReplyDialog.text')" type="info" />
    <div class="text-body-m-bold pb-1">
      <span v-if="v$.personalized.$model">{{
        t('Reviews.product.review.reply.sectionTitleWithAuthor', { authorName: senderName, productTitle })
      }}</span>
      <span v-else>{{ t('Reviews.product.review.reply.sectionTitle', { productTitle }) }}</span>
    </div>
    <div class="mb-4">
      <CheckBox v-model="v$.personalized.$model">
        {{ t('Reviews.reviewReplyDialog.personalizedCheckbox.label') }}
      </CheckBox>
    </div>
    <RichInput
      ref="richInput"
      :key="inputKey"
      v-model="v$.reply.$model"
      :error="getValidationErrors(v$.reply)"
      hide-controls
      plain-text-only
      @focusin="onFocusIn"
      @focusout="onFocusOut"
    />

    <div class="flex justify-between">
      <CheckBox v-model="v$.notifyReviewer.$model">{{ t('Reviews.reviewReplyDialog.informReviewerCheckbox.label') }}</CheckBox>
      <button class="btn-primary-purple-m" :disabled="loading || v$.$invalid" @click="onSubmit">
        {{ t('Reviews.reviewReplyDialog.send') }}
      </button>
    </div>
  </ModalDialog>
</template>
<script setup lang="ts">
import { CheckBox, ModalDialog, RichInput, nl2br, MessageBanner } from '@ramp106/omrjs-core-ui'
import { reviewsPaths } from '@ramp106/omrjs-routing'
import { useVuelidate, type ValidationArgs } from '@vuelidate/core'
import { storeToRefs } from 'pinia'
import TurnDown from 'turndown'
import { computed, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useUpsertReviewsProductReviewReplyMutation } from '@/gql/reviews'
import { showNotification } from '@/helpers/notificationHelper'
import { getValidationErrors } from '@/helpers/validationHelper'
import { required, maxLength, minLength } from '@/services/validators'
import { useMeStore } from '@/stores'

import type { SurveyResponseReviewReply } from './types'

type FormData = {
  reply: string
  personalized: boolean
  notifyReviewer: boolean
}

const REPLY_MIN_LENGTH = 2
const REPLY_MAX_LENGTH = 1200

const props = defineProps<{
  reviewId: string
  reviewReply: SurveyResponseReviewReply
  productSlug: string
  productTitle: string
  reviewerFirstName?: string
}>()

defineEmits(['closed'])

const meStore = useMeStore()
const { me } = storeToRefs(meStore)
const senderName = computed(() => props.reviewReply?.senderName?.split(' ')[0] || me.value?.firstName || '')
const modal = ref<InstanceType<typeof ModalDialog> | null>(null)
const richInput = ref<InstanceType<typeof RichInput> | null>(null)
const inputKey = ref(1)
const focused = ref(false)
const { t, locale } = useI18n()
const { loading, mutate: upsertReviewReply } = useUpsertReviewsProductReviewReplyMutation({ clientId: 'reviews' })

const formData = reactive<FormData>({
  reply: props.reviewReply?.reply || '',
  personalized: props.reviewReply?.personalized || false,
  notifyReviewer: !props.reviewReply,
})

const rules = computed<ValidationArgs<FormData>>(() => ({
  reply: {
    required,
    minLength: minLength(REPLY_MIN_LENGTH),
    maxLength: maxLength(REPLY_MAX_LENGTH),
  },
  personalized: {},
  notifyReviewer: {},
}))

const v$ = useVuelidate(rules, formData)

function open() {
  resetFormData()
  modal.value?.open()
}
function close() {
  modal.value?.close()
}

function onFocusIn() {
  focused.value = true
}

function onFocusOut() {
  setTimeout(() => (focused.value = false), 100)
}

function resetFormData() {
  formData.reply = props.reviewReply?.reply || ''
  formData.personalized = props.reviewReply?.personalized || false
  formData.notifyReviewer = !props.reviewReply
}

async function onSubmit() {
  const isFormValid = await v$.value.$validate()
  if (!isFormValid) {
    showNotification(t('Reviews.reviewReplyDialog.invalidForm'), 'error')
    return
  }

  const turnDown = new TurnDown({
    emDelimiter: '_',
    linkStyle: 'inlined',
    headingStyle: 'atx',
  })

  try {
    await upsertReviewReply({
      reply: turnDown.turndown(nl2br(formData.reply)),
      reviewId: props.reviewId,
      notifyReviewer: formData.notifyReviewer,
      personalized: formData.personalized,
    })
    let notificationText: string
    const reviewsPDPUrl = reviewsPaths.product({ locale: locale.value, slug: props.productSlug })
    if (props.reviewerFirstName) {
      notificationText = t('Reviews.reviewReplyDialog.successWithAuthor', {
        reviewerFirstName: props.reviewerFirstName,
        reviewsProductUrl: reviewsPDPUrl,
      })
    } else {
      notificationText = t('Reviews.reviewReplyDialog.successWithoutAuthor', { reviewsProductUrl: reviewsPDPUrl })
    }
    showNotification(notificationText, 'success', 10000)
    close()
  } catch (_e) {
    showNotification(t('Reviews.reviewReplyDialog.serverError'), 'error', 10000)
  }
}

defineExpose({ open, close })
</script>
