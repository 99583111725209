<template>
  <div>
    <h3 v-if="loading">Loading...</h3>
    <RouterView v-if="booth" :booth="booth" :booth-id="boothId" />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { watch, computed } from 'vue'
import { useRouter } from 'vue-router'

import { useBoothQuery, type BoothsQuery, type Booth } from '@/gql/myomr'
import { toGlobalId } from '@/helpers/globalIdHelper'
import { useMeStore } from '@/stores'

const router = useRouter()

const props = defineProps<{
  booths: BoothsQuery['booths']['nodes']
  boothId: string
}>()

const meStore = useMeStore()
const { currentPartnerCompanyGlobalId } = storeToRefs(meStore)

const boothId = computed(() => toGlobalId('Booth', props.boothId))

const { result, loading } = useBoothQuery({ boothId: boothId.value })

const booth = computed<Booth>(() => result.value?.node as Booth)

function comparePartnerCompanies() {
  if (!booth.value || !currentPartnerCompanyGlobalId.value) return
  if (currentPartnerCompanyGlobalId.value !== booth.value?.partnerCompany?.id) {
    router.push({ name: 'booths' })
  }
}

watch([booth, currentPartnerCompanyGlobalId], () => comparePartnerCompanies())
</script>
