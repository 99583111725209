<template>
  <div class="wrapper">
    <DropDownSelect class="w-full">
      <template #activator="{ open }">
        <button
          class="group btn-text-black-s w-48 py-2 my-3 h-9 relative flex justify-between border-solid border"
          :class="{ 'border-amaranth-600 text-amaranth-800': error, 'border-grey-500': !error }"
          @click.prevent="open"
        >
          <div>
            <span
              class="absolute"
              :class="{
                'left-3 top-2': props.value === null || props.value === undefined,
                'left-2 -top-3 bg-white text-label-s  px-1': !(props.value === null || props.value === undefined),
              }"
            >
              {{ t(name) }}
            </span>
            <span v-if="props.value !== null && props.value !== undefined" class="absolute left-3 top-2 text-black text-body-m">
              {{ getItemText }}
            </span>
          </div>
          <div>
            <SvgIcon
              v-if="props.value !== null && props.value !== undefined"
              :path="mdiClose"
              class="opacity-0 group-hover:opacity-100"
              :size="20"
              @click="selectionChanged(null, open)"
            />
            <SvgIcon :path="mdiMenuDown" class="mr-2" :size="22" />
          </div>
        </button>
      </template>
      <template #default="{ close }">
        <ul class="list-none p-0 m-0 w-[175px]">
          <li
            v-for="item in itemsForSelect"
            :key="item.text"
            :class="{ 'cursor-events-none': item.disabled }"
            class="text-center py-2 px-2 cursor-pointer hover:bg-grey-100"
            @click="item.disabled ? '' : selectionChanged(item.value, close)"
          >
            <span :class="{ 'text-black': !item.disabled, 'text-grey-700': item.disabled }">{{ item.text }}</span>
          </li>
        </ul>
      </template>
    </DropDownSelect>

    <div v-if="tooltip">
      <SvgIcon
        v-tooltip="{ content: tooltip, html: true, placement: 'right' }"
        :path="mdiInformationOutline"
        :size="24"
        class="text-tailwind"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { mdiClose, mdiMenuDown, mdiInformationOutline } from '@mdi/js'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{
  value?: number | null
  name: string
  itemsForSelect: Array<{ text: string; value: number | null; disabled: boolean }>
  required: boolean
  error: boolean
}>()

const emit = defineEmits<{
  (e: 'mappingChanged', value: number | null): void
}>()

function selectionChanged(value: number | null, callback: () => void) {
  callback()
  emit('mappingChanged', value)
}

const getItemText = computed(() => {
  const foundItem = props.itemsForSelect.find((item) => {
    return item.value === props.value
  })
  if (!foundItem) return 'undefined'
  return foundItem.text
})

const tooltip = computed(() => {
  if (props.name == 'gender') {
    return `
      <h4>${t('EmailInvitationQuota.mapping.tips.gender.male_values')}:</h4>
      <ul>
        <li>m</li>
        <li>male</li>
        <li>Herr</li>
        <li>männlich</li>
      </ul>
      <br>
      <h4>${t('EmailInvitationQuota.mapping.tips.gender.female_values')}:</h4>
      <ul>
        <li>f</li>
        <li>female</li>
        <li>Frau</li>
        <li>weiblich</li>
      </ul>
    `
  } else if (props.name == 'cc') {
    return t('EmailInvitationQuota.mapping.tips.cc')
  } else if (props.name == 'externalId') {
    return t('EmailInvitationQuota.mapping.tips.externalId')
  }

  return null
})
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

:deep(body) {
  background: rgb(239, 241, 243);
}
</style>
