<template>
  <div class="w-52 shrink-0 border-grey-300 border-r -ml-6">
    <RouterLink
      v-for="item in props.sidebarItems"
      :key="item.label"
      v-slot="{ isActive }"
      :to="item.route"
      class="p-4 border-l-4 block border-transparent"
    >
      <span class="uppercase font-semibold text-xs" :class="isActive ? 'text-black' : 'text-grey-800'">
        {{ item.label }}
      </span>
    </RouterLink>
  </div>
</template>

<script lang="ts">
export type SidebarItem = {
  label: string
  route: RouteLocationNamedRaw
}
</script>

<script setup lang="ts">
import { type RouteLocationNamedRaw } from 'vue-router'

const props = defineProps<{
  sidebarItems: SidebarItem[]
}>()
</script>

<style scoped>
.router-link-active {
  @apply border-purple-600;
}
</style>
