import { createI18n, type I18nOptions } from 'vue-i18n'

import { getLocale, defaultLocale } from '@/helpers/locale'

import deMessages from '../locales/de.json'
import enMessages from '../locales/en.json'
import deOmrUiMessages from '../locales/omr-ui.de.json'
import enOmrUiMessages from '../locales/omr-ui.en.json'

const datetimeFormats = {
  de: {
    ddmmyy: {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    },
    dateShort: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
    dateLong: {
      month: 'short',
      day: 'numeric',
      weekday: 'short',
    },
    dateTimeShort: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    timeOnly: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    },
    weekday: {
      weekday: 'long',
    },
  },
  en: {
    ddmmyy: {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    },
    dateShort: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
    dateLong: {
      month: 'short',
      day: 'numeric',
      weekday: 'short',
    },
    dateTimeShort: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    timeOnly: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    },
    weekday: {
      weekday: 'long',
    },
  },
} as I18nOptions['datetimeFormats']

export default createI18n({
  legacy: false,
  warnHtmlMessage: false,
  locale: getLocale(),
  fallbackLocale: defaultLocale,
  messages: {
    en: { ...enMessages, ...enOmrUiMessages },
    de: { ...deMessages, ...deOmrUiMessages },
  },
  datetimeFormats,
})
