<template>
  <ProductFeatureValue :name="name" :icon="icon" :value="value">
    <div>
      <template v-for="allowedValue in allowedValues" :key="allowedValue">
        <!-- @vue-ignore vue-tsc is mixing checkbox prop value with model -->
        <RadioButton
          v-model="statusValue"
          class="w-full mb-1 rounded-full px-1"
          :class="{
            [bgColor]: value === allowedValue,
          }"
          :value="allowedValue"
        >
          {{ t(`Reviews.product.edit.globalFeatures.status.${camelCase(allowedValue)}`) }}
        </RadioButton>
      </template>
    </div>
  </ProductFeatureValue>
</template>
<script setup lang="ts">
import { RadioButton } from '@ramp106/omrjs-core-ui'
import { type ProductGlobalFeaturesTypes } from '@ramp106/omrjs-reviews-ui'
import { camelCase } from 'lodash'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import ProductFeatureValue from './ProductFeatureValue.vue'

const props = defineProps<{
  name: string
  icon: string
  value: ProductGlobalFeaturesTypes.FeatureStatus
  allowedValues: NonNullable<ProductGlobalFeaturesTypes.FeatureStatus>[]
}>()

const emit = defineEmits<{
  (e: 'change', status: ProductGlobalFeaturesTypes.FeatureStatus): void
}>()

const { t } = useI18n()

const statusValue = ref(props.value)

const bgColor = computed(() => {
  if (statusValue.value === 'yes') {
    return 'bg-green-100'
  }
  if (statusValue.value === 'yes_conditional') {
    return 'bg-yellow-100'
  }
  return 'bg-grey-100'
})

watch(
  () => props.value,
  (newVal) => {
    if (newVal) {
      statusValue.value = newVal
    }
  },
)

watch(statusValue, (newStatusValue) => {
  emit('change', newStatusValue)
})
</script>
