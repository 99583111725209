<template>
  <div :style="{ maxWidth: maxWidth }">
    <div v-if="asset">
      <div>
        <slot v-if="slots.preview" name="preview" :content-type="asset.contentType" :url="asset.url" />
        <AssetPreview v-else :aspect-ratio="aspectRatio" :content-type="asset.contentType" :disabled="disabled" :url="asset.url" />
      </div>
      <button class="btn-text-black-s my-4" :disabled="disabled" @click="triggerAssetPicker">
        <MdiSvg :path="mdiCached" :size="18" class="mr-2" />
        {{ t('MediaUpload.replaceAsset') }}
      </button>
    </div>
    <MediaAspectRatio v-else :aspect-ratio="aspectRatio">
      <div
        class="h-full bg-grey-100 rounded border-grey-500 border flex flex-col justify-center text-center items-center p-2"
        :class="{ 'opacity-75': disabled, 'cursor-pointer': !disabled }"
        @click="!disabled ? fileInput.click() : undefined"
      >
        <MdiSvg :path="disabled ? mdiUploadOff : mdiUpload" :size="18" />
        <p class="text-button-l my-2">{{ t('MediaUpload.uploadAsset') }}</p>
        <p v-for="(uploaderHint, index) in uploaderHints" :key="index">{{ uploaderHint }}</p>
      </div>
    </MediaAspectRatio>
    <div
      v-if="error || (hint && asset)"
      class="border-t pt-2 text-label-s"
      :class="{
        'mt-4': !asset,
        'border-t-black': hint && !error,
        'text-grey-700': hint && !error,
        'border-t-error': error,
        'text-error': error,
      }"
    >
      <span>{{ error ? error : hint }}</span>
    </div>
    <input
      ref="fileInput"
      class="hidden"
      type="file"
      data-testid="file-upload-input"
      :accept="props.accept"
      @focus="focus"
      @blur="blur"
      @change="handleInputChange"
    />
  </div>
</template>

<script setup lang="ts">
import { mdiUpload, mdiUploadOff, mdiCached } from '@mdi/js'
import { MediaAspectRatio, MdiSvg } from '@ramp106/omrjs-core-ui'
import { computed, ref, useSlots } from 'vue'
import { useI18n } from 'vue-i18n'

import AssetPreview from '@/ui/AssetPreview.vue'

type Asset = {
  contentType: string
  url: string
}

const slots = useSlots()

const props = withDefaults(
  defineProps<{
    aspectRatio?: string
    accept: string
    disabled?: boolean
    maxWidth?: string
    hint?: string
    shortHint?: string | string[]
    error?: string
    previewAsset?: Asset
    hidePreview?: boolean
  }>(),
  {
    disabled: false,
    hidePreview: false,
    maxWidth: undefined,
    aspectRatio: '1:1',
    hint: undefined,
    shortHint: undefined,
    error: undefined,
    previewAsset: undefined,
  },
)

const emit = defineEmits<{
  (e: 'upload', value: File): void
  (e: 'blur'): void
  (e: 'focus'): void
}>()

const { t } = useI18n()
const fileInput = ref()
const fileAsset = ref<Asset | null>(null)

const asset = computed(() => {
  if (fileAsset.value) {
    return {
      contentType: fileAsset.value.contentType,
      url: fileAsset.value.url,
    }
  }

  if (props.previewAsset) {
    return {
      contentType: props.previewAsset.contentType,
      url: props.previewAsset.url,
    }
  }
  return null
})

const uploaderHints = computed(() => {
  if (props.shortHint) {
    return typeof props.shortHint === 'string' ? [props.shortHint] : props.shortHint
  }
  return [
    props.accept
      ?.split(',')
      .map((type) => `${type.split('/')[1]}`)
      .join(', '),
  ]
})

function triggerAssetPicker() {
  fileInput.value.click()
}

function handleInputChange() {
  if (fileInput.value?.files && fileInput.value.files[0]) {
    if (fileAsset.value?.url) {
      URL.revokeObjectURL(fileAsset.value.url)
    }

    const file: File = fileInput.value.files[0]
    if (!props.hidePreview) {
      fileAsset.value = {
        contentType: file.type,
        url: URL.createObjectURL(file),
      }
    }
    emit('upload', file)
  }
}

function blur() {
  emit('blur')
}

function focus() {
  emit('focus')
}

function reset() {
  fileInput.value.value = ''
  fileAsset.value = null
}

defineExpose({ reset })
</script>
