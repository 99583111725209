<template>
  <div class="flex gap-4 pt-4 pb-8">
    <div>
      <DropDownSelect>
        <template #activator="{ open, showDropDown }">
          <button
            class="flex gap-1 p-2 border border-solid rounded items-center text-body-s"
            :class="queryParams.timeframe !== TimeFrameOptionsEnum.NONE || showDropDown ? 'filter-selected' : 'filter-default'"
            @click.prevent="open"
          >
            {{ t('Reviews.product.view.filters.timeframe.filtername') }}
            <SvgIcon :path="mdiChevronDown" :size="24" />
          </button>
        </template>
        <template #default="{ close }">
          <ul class="options-list list-none p-0 m-0 w-80">
            <div class="py-2 px-3 bg-grey-200 font-bold">
              {{ t('Reviews.product.view.filters.timeframe.headline') }}
              <SvgIcon :path="mdiClose" class="absolute right-3 top-2 cursor-pointer" :size="20" @click.prevent="close" />
            </div>
            <li v-for="option in timeframeOptions" :key="option.value">
              <label class="block cursor-pointer peer">
                <input
                  v-model="queryParams.timeframe"
                  type="radio"
                  :value="option.value"
                  name="timeframe-option"
                  class="hidden peer absolute pointer-events-none"
                  @change="setReviewFilters"
                />
                <span class="block peer-checked:hidden hover:bg-grey-100 py-2 px-3">
                  {{ option.text }}
                </span>
                <span class="hidden peer-checked:block hover:bg-grey-100 py-2 px-3">
                  <span class="text-purple-700">{{ option.text }}</span>
                  <SvgIcon :path="mdiCheck" class="pl-4"></SvgIcon>
                </span>
              </label>
            </li>
          </ul>
        </template>
      </DropDownSelect>
    </div>
    <div>
      <ReviewsFilterMultiSelect
        v-model="queryParams.ratings"
        :options="ratingOptions"
        :label="t('Reviews.product.view.filters.rating.filtername')"
        @update:model-value="setReviewFilters"
      >
        <template #header="{ close }">
          <div class="py-2 px-2 bg-grey-200 font-bold w-48">
            {{ t('Reviews.product.view.filters.rating.headline') }}
            <SvgIcon :path="mdiClose" class="absolute right-3 top-2 cursor-pointer" :size="20" @click.prevent="close" />
          </div>
        </template>
      </ReviewsFilterMultiSelect>
    </div>
    <div>
      <ReviewsFilterMultiSelect
        v-model="queryParams.states"
        :options="stateOptions"
        :label="t('Reviews.product.view.filters.state.filtername')"
        @update:model-value="setReviewFilters"
      >
        <template #header="{ close }">
          <div class="py-2 px-2 bg-grey-200 font-bold w-48">
            {{ t('Reviews.product.view.filters.state.headline') }}
            <SvgIcon :path="mdiClose" class="absolute right-3 top-2 cursor-pointer" :size="20" @click.prevent="close" />
          </div>
        </template>
      </ReviewsFilterMultiSelect>
    </div>
    <div>
      <DropDownSelect>
        <template #activator="{ open, showDropDown }">
          <button
            class="flex gap-1 p-2 border border-solid rounded items-center text-grey-700 text-body-s border-grey-300"
            :class="queryParams.interaction !== ReviewInteractionType.NONE || showDropDown ? 'filter-selected' : 'filter-default'"
            @click.prevent="open"
          >
            {{ t('Reviews.product.view.filters.interactionOptions.filtername') }}
            <SvgIcon :path="mdiChevronDown" :size="24" />
          </button>
        </template>
        <template #default="{ close }">
          <ul class="options-list list-none p-0 m-0 w-96">
            <div class="py-2 px-3 bg-grey-200 font-bold">
              {{ t('Reviews.product.view.filters.interactionOptions.headline') }}
              <SvgIcon :path="mdiClose" class="absolute right-3 top-2 cursor-pointer" :size="20" @click.prevent="close" />
            </div>
            <li v-for="option in interactionOptions" :key="option.value">
              <label class="block cursor-pointer peer">
                <input
                  v-model="queryParams.interaction"
                  type="radio"
                  :value="option.value"
                  name="interaction-status"
                  class="hidden peer absolute pointer-events-none"
                  @change="setReviewFilters"
                />
                <span class="hidden peer-checked:flex hover:bg-grey-100 py-2 px-3 h-full w-full inline-flex items-center">
                  <span
                    :class="{
                      'pr-2': option.interactionState,
                      'pr-9': !option.interactionState,
                    }"
                    ><ReviewInteractionStatusIcon :type="option.interactionState || 'null'"
                  /></span>
                  <span class="text-purple-700">{{ option.text }}</span>
                  <SvgIcon :path="mdiCheck" class="pl-4"></SvgIcon>
                </span>
                <span class="flex peer-checked:hidden hover:bg-grey-100 py-2 px-3 h-full w-full inline-flex items-center">
                  <span
                    :class="{
                      'pr-2': option.interactionState,
                      'pr-9': !option.interactionState,
                    }"
                    ><ReviewInteractionStatusIcon :type="option.interactionState || 'null'"
                  /></span>
                  <span>{{ option.text }}</span>
                </span>
              </label>
            </li>
          </ul>
        </template>
      </DropDownSelect>
    </div>
    <button class="font-bold" :class="hasNoActiveFilters ? 'text-grey-400' : ''" :disabled="hasNoActiveFilters" @click="resetAllFilters()">
      <SvgIcon :path="mdiClose" :size="20" class="relative top-1" /> {{ t('Reviews.product.view.filters.clearAllButton') }}
    </button>
  </div>
</template>
<script setup lang="ts">
import { mdiChevronDown, mdiClose, mdiCheck } from '@mdi/js'
import { useI18n } from 'vue-i18n'

import { useRouterQueryFilters } from '@/components/ReviewsManagement/Reviews/useRouterQueryReviewsFilters'
import { SurveyResponseState } from '@/composables/useSurveyResponseState'
import { RecommendationRatingKeyEnum, SurveyResponsesStateEnum } from '@/gql/reviews'

import ReviewsFilterMultiSelect from './ReviewsFilterMultiSelect.vue'
import { ReviewInteractionType, TimeFrameOptionsEnum } from './types'

const { t } = useI18n()

const { queryParams, hasNoActiveFilters, setReviewFilters, resetAllFilters } = useRouterQueryFilters()

const timeframeOptions = [
  { text: t('Reviews.product.view.filters.timeframeOptions.all'), value: TimeFrameOptionsEnum.NONE },
  { text: t('Reviews.product.view.filters.timeframeOptions.optionOne'), value: TimeFrameOptionsEnum.LAST_30_DAYS },
  { text: t('Reviews.product.view.filters.timeframeOptions.optionTwo'), value: TimeFrameOptionsEnum.LAST_3_MONTH },
  { text: t('Reviews.product.view.filters.timeframeOptions.optionThree'), value: TimeFrameOptionsEnum.LAST_6_MONTH },
  { text: t('Reviews.product.view.filters.timeframeOptions.optionFour'), value: TimeFrameOptionsEnum.LAST_12_MONTH },
  { text: t('Reviews.product.view.filters.timeframeOptions.optionFive'), value: TimeFrameOptionsEnum.OLDER_THAN_12_MONTH },
]

const ratingOptions = [
  { text: '1.0', value: RecommendationRatingKeyEnum.Bad, renderType: 'ratingStars' },
  { text: '2.0', value: RecommendationRatingKeyEnum.Worse, renderType: 'ratingStars' },
  { text: '3.0', value: RecommendationRatingKeyEnum.Good, renderType: 'ratingStars' },
  { text: '4.0', value: RecommendationRatingKeyEnum.Better, renderType: 'ratingStars' },
  { text: '5.0', value: RecommendationRatingKeyEnum.Best, renderType: 'ratingStars' },
]

const stateOptions = [
  {
    text: t('Reviews.product.view.table.statusLabel.published'),
    value: SurveyResponsesStateEnum.Published,
    renderType: 'stateBadges',
    responseState: SurveyResponseState.Published,
  },
  {
    text: t('Reviews.product.view.table.statusLabel.drafted'),
    value: SurveyResponsesStateEnum.Drafted,
    renderType: 'stateBadges',
    responseState: SurveyResponseState.Drafted,
  },
  {
    text: t('Reviews.product.view.table.statusLabel.reDrafted'),
    value: SurveyResponsesStateEnum.InRevision,
    renderType: 'stateBadges',
    responseState: SurveyResponseState.ReDrafted,
  },
  {
    text: t('Reviews.product.view.table.statusLabel.stale'),
    value: SurveyResponsesStateEnum.Stale,
    renderType: 'stateBadges',
    responseState: SurveyResponseState.Stale,
  },
  {
    text: t('Reviews.product.view.table.statusLabel.rejected'),
    value: SurveyResponsesStateEnum.Rejected,
    renderType: 'stateBadges',
    responseState: SurveyResponseState.Rejected,
  },
  {
    text: t('Reviews.product.view.table.statusLabel.proposed'),
    value: SurveyResponsesStateEnum.Proposed,
    renderType: 'stateBadges',
    responseState: SurveyResponseState.Proposed,
  },
]

const interactionOptions = [
  {
    text: t('Reviews.product.view.filters.interactionOptions.all'),
    value: ReviewInteractionType.NONE,
    renderType: 'interactionStatus',
    interactionState: null,
  },
  {
    text: t('Reviews.product.view.filters.interactionOptions.optionOne'),
    value: ReviewInteractionType.REMINDER_CHECK,
    renderType: 'interactionStatus',
    interactionState: ReviewInteractionType.REMINDER_CHECK,
  },
  {
    text: t('Reviews.product.view.filters.interactionOptions.optionTwo'),
    value: ReviewInteractionType.REMINDER,
    renderType: 'interactionStatus',
    interactionState: ReviewInteractionType.REMINDER,
  },
  {
    text: t('Reviews.product.view.filters.interactionOptions.optionThree'),
    value: ReviewInteractionType.REPLY_CHECK,
    renderType: 'interactionStatus',
    interactionState: ReviewInteractionType.REPLY_CHECK,
  },
  {
    text: t('Reviews.product.view.filters.interactionOptions.optionFour'),
    value: ReviewInteractionType.REPLY,
    renderType: 'interactionStatus',
    interactionState: ReviewInteractionType.REPLY,
  },
  {
    text: t('Reviews.product.view.filters.interactionOptions.optionFive'),
    value: ReviewInteractionType.REPLY_OUTDATED,
    renderType: 'interactionStatus',
    interactionState: ReviewInteractionType.REPLY_OUTDATED,
  },
]
</script>

<style scoped>
.filter-selected {
  @apply text-grey-700 border-grey-600;
}
.filter-default {
  @apply text-grey-500 border-grey-300 disabled:text-grey-400 disabled:border-grey-200;
}
</style>
