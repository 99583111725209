<template>
  <TextInput
    v-model="v$.name.$model"
    :error="getValidationErrors(v$.name)"
    :max-length="PERK_NAME_MAX_LENGTH"
    :placeholder="t('Reviews.product.edit.pricing.perk.placeholder').toString()"
    @blur="v$.name.$touch"
  >
    <template #label>
      <span class="flex justify-between">
        <span>{{ t('Reviews.product.edit.pricing.perk.label', { number: index + 1 }) }}</span>
        <ChipBadge class="uppercase" rounded>{{ lang }}</ChipBadge>
      </span>
    </template>
  </TextInput>
</template>

<script setup lang="ts">
import { ChipBadge, TextInput } from '@ramp106/omrjs-core-ui'
import { useVuelidate } from '@vuelidate/core'
import { computed, reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { getValidationErrors } from '@/helpers/validationHelper'
import { maxLength, minLength, requiredIf } from '@/services/validators'

const PERK_NAME_MIN_LENGTH = 3
const PERK_NAME_MAX_LENGTH = 80

const props = defineProps<{
  lang: string
  index: number
  required: boolean
  modelValue?: { name: string } | null
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: { name: string }): void
}>()

const { t } = useI18n()

const formData = reactive<{ name: string }>({
  name: props.modelValue?.name ?? '',
})

const rules = computed(() => ({
  name: {
    requiredIf: requiredIf(() => props.required),
    maxLength: maxLength(PERK_NAME_MAX_LENGTH),
    minLength: minLength(PERK_NAME_MIN_LENGTH),
  },
}))

const v$ = useVuelidate(rules, formData)

watch(
  () => props.modelValue,
  (newVal) => {
    if (newVal?.name) {
      formData.name = newVal.name
    }
  },
)

watch(
  () => formData,
  () => {
    emit('update:modelValue', formData)
  },
  { deep: true },
)
</script>
