<template>
  <tr v-if="ticket" :key="`ticket_${ticket?.id}`" class="cursor-pointer hover:text-tailwind">
    <td class="pr-2 border-b border-grey-300">
      <div class="flex items-center">
        <CheckBox
          :model-value="!!props.selectedTicketIds.includes(ticket.id)"
          class="text-grey-800"
          data-test-id="toggle-invitee-checkbox"
          @update:model-value="() => emit('toggleTicketChecked', props.ticket.id)"
        />
      </div>
    </td>
    <td class="py-3 pr-2 border-b border-grey-300" @click.stop="showDetails()">
      {{ ticket.email }}
    </td>
    <td class="py-3 pr-2 border-b border-grey-300" @click.stop="showDetails()">
      {{ ticket.firstName }}
    </td>
    <td class="py-3 pr-2 border-b border-grey-300" @click.stop="showDetails()">
      {{ ticket.lastName }}
    </td>
    <td class="py-3 pr-2 border-b border-grey-300" @click.stop="showDetails()">
      <span>
        {{ ticket.companyName }}
      </span>
    </td>
    <td v-if="props.showStatus" class="py-3 border-b border-grey-300" @click.stop="showDetails()">
      <div v-if="ticket.aasmState == 'unlocked'" class="chip bg-red text-white">
        {{ t('Ticket.state.invalid') }}
      </div>
      <div v-else class="chip" :class="statusColor">
        {{ ticket.swapped ? t('Ticket.state.swapped') : t('Ticket.state.valid') }}
      </div>
    </td>
    <td class="py-3 pl-2 border-b border-grey-300" @click.stop="showDetails()">
      {{ ticketDate }}
    </td>
  </tr>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { type TicketConnection } from '@/gql/myomr'

import { isTicketRequest } from './ticketHelpers'

const { t, d } = useI18n()
const route = useRoute()
const router = useRouter()

const props = defineProps<{
  showStatus: boolean
  selectedTicketIds: string[]
  ticket: TicketConnection['nodes'][0]
}>()
const emit = defineEmits<{
  (e: 'toggleTicketChecked', value: string): void
}>()

const ticketDate = computed(() => d(props.ticket.createdAt, 'dateShort'))

const statusColor = computed(() => {
  switch (props.ticket.swapped) {
    case true:
      return 'bg-green text-white'
    default:
      return 'bg-blue-100 text-blue-900'
  }
})

function showDetails() {
  if (isTicketRequest(props.ticket)) {
    emit('toggleTicketChecked', props.ticket.id)
  } else {
    router.push({ name: 'invitationQuotaTicketDetails', params: { ticketId: props.ticket.identifier }, query: route.query })
  }
}
</script>
