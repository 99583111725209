/* eslint-disable @typescript-eslint/no-explicit-any */
const parseHeaders = (rawHeaders: string) => {
  const headers = new Headers()
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ')
  preProcessedHeaders.split(/\r?\n/).forEach((line: any) => {
    const parts = line.split(':')
    const key = parts.shift().trim()
    if (key) {
      const value = parts.join(':').trim()
      headers.append(key, value)
    }
  })
  return headers
}

type AbortHandler = XMLHttpRequest['abort']
type UploadFetchRequestInit = RequestInit & {
  useUpload: boolean
  onProgress: (ev: ProgressEvent) => void
  onAbortPossible?: (abortHandler: AbortHandler) => void
}

export const customFetch = (input: string | URL, init: UploadFetchRequestInit) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.withCredentials = true
    xhr.onload = () => {
      const opts: any = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
      }
      opts.url = 'responseURL' in xhr ? xhr.responseURL : opts.headers.get('X-Request-URL')
      const body = 'response' in xhr ? xhr.response : (xhr as any).responseText
      resolve(new Response(body, opts))
    }
    xhr.onerror = () => {
      reject(new TypeError('Network request failed'))
    }
    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'))
    }
    xhr.open(init.method || '', input, true)

    Object.keys(init.headers as Headers).forEach((key) => {
      const headerValue = init.headers ? (init.headers[key as keyof HeadersInit] as string) : ''
      xhr.setRequestHeader(key, headerValue)
    })

    xhr.setRequestHeader('Access-Control-Allow-Origin', '*')

    if (xhr.upload) {
      xhr.upload.onprogress = init.onProgress
    }

    if (init.onAbortPossible) {
      init.onAbortPossible(() => xhr.abort())
    }

    xhr.send(init.body as XMLHttpRequestBodyInit | Document | null | undefined)
  })

export const uploadFetch = (input: string | URL, init: UploadFetchRequestInit) => {
  if (init.useUpload) {
    return customFetch(input, init)
  }
  return fetch(input, init)
}
