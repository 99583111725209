<template>
  <InternalQuota
    :counts="counts"
    :current-active-tab="currentActiveTab"
    :filter-status="filterStatus"
    :invitation-quota="props.invitationQuota"
    :is-limit-reached="props.isLimitReached"
    :per-page="perPage"
    :search-query="searchQuery"
    :tickets="result?.tickets"
    @refetch="refetchData"
    @update:pagination-query-variables="updateQueryVars($event)"
    @update:per-page="updatePerPage($event)"
    @update:search-query="updateSearchQuery($event)"
    @update:filter-status="updateFilterStatus($event)"
  >
    <slot></slot>
  </InternalQuota>
</template>

<script setup lang="ts">
import { computed, provide, reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import type { PaginationQueryVariables } from '@/components'
import {
  useTicketsQuery,
  type InvitationQuotaQuery,
  TicketAasmStateEnum,
  TicketOrderAttributesEnum,
  TicketSourceEnum,
  OrderDirectionEnum,
} from '@/gql/myomr'
import { showNotification } from '@/helpers/notificationHelper'
import { reloadTicketsKey } from '@/symbols/reloadTicketsKey'

import { sourceByTab, activatedStateByTab, getAasmStateByTabAndFilterStatus, swappedStateByFilterStatus } from './dynamicQueryVars'
import { type FilterStatusOption } from './filters'

import { getCurrentActiveTab, ticketCounts } from '.'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const searchQuery = computed<string | undefined>(() => (route.query.searchQuery ? String(route.query.searchQuery) : undefined))
const filterStatus = computed<FilterStatusOption>(() => (route.query.filter ? (String(route.query.filter) as FilterStatusOption) : ''))

const props = defineProps<{
  invitationQuota: InvitationQuotaQuery['invitationQuota']
  isLimitReached: boolean
  loading: boolean
}>()

const currentActiveTab = getCurrentActiveTab(route)
watch(currentActiveTab, () => counts.refetch())

const aasmState = computed<TicketAasmStateEnum[]>(() => getAasmStateByTabAndFilterStatus(currentActiveTab.value, filterStatus.value))
const activated = computed<boolean>(() => activatedStateByTab[currentActiveTab.value])
const after = computed<string | undefined>(() => (route.query.after ? String(route.query.after) : undefined))
const before = computed<string | undefined>(() => (route.query.before ? String(route.query.before) : undefined))
const first = computed<number | undefined>(() => Number(route.query.first) || (last.value == undefined ? 10 : undefined))
const last = computed<number | undefined>(() => Number(route.query.last) || undefined)
const perPage = computed<number>(() => first.value || last.value || 10)
const source = computed<TicketSourceEnum[]>(() => sourceByTab[currentActiveTab.value])
const swapped = computed<boolean | undefined>(() => swappedStateByFilterStatus[filterStatus.value])

const queryVars = reactive({
  aasmState: aasmState,
  activated: activated,
  after: after,
  before: before,
  first: first,
  invitationQuotaId: props.invitationQuota.id,
  last: last,
  orderBy: TicketOrderAttributesEnum.CreatedAt,
  orderDirection: OrderDirectionEnum.Desc,
  search: searchQuery,
  source: source,
  swapped: swapped,
})
const { result, refetch } = useTicketsQuery(() => queryVars, { fetchPolicy: 'no-cache' })

function refetchQuotaAndCounts() {
  refetch()
  counts.refetch()
}

provide(reloadTicketsKey, refetchQuotaAndCounts)

function updatePerPage(newPerPage: number) {
  router.push({
    query: {
      ...route.query,
      first: newPerPage == 10 ? undefined : String(newPerPage),
      last: undefined,
      after: undefined,
      before: undefined,
    },
  })
}

function updateQueryVars(newQueryVars: PaginationQueryVariables) {
  router.push({
    query: {
      ...route.query,
      first: newQueryVars.first ? String(newQueryVars.first) : undefined,
      last: newQueryVars.last ? String(newQueryVars.last) : undefined,
      after: newQueryVars.after,
      before: newQueryVars.before,
    },
  })
}

function updateSearchQuery(newSearchQuery: string) {
  router.push({
    query: {
      ...route.query,
      first: undefined,
      last: undefined,
      after: undefined,
      before: undefined,
      searchQuery: newSearchQuery || undefined,
    },
  })
}

function updateFilterStatus(newFilterStatus: string | undefined) {
  router.push({
    query: {
      ...route.query,
      first: undefined,
      last: undefined,
      after: undefined,
      before: undefined,
      filter: newFilterStatus || undefined,
    },
  })
}

const refetchData = async (showSuccessModal: boolean) => {
  try {
    counts.refetch()
    await refetch()
    if (showSuccessModal) showNotification(t('EmailInvitationQuota.refetchDataMessage'), 'success')
  } catch (_e) {
    if (showSuccessModal) showNotification(t('EmailInvitationQuota.refetchDataErrorMessage'), 'success')
  }
}

const counts = ticketCounts(props.invitationQuota.id)
</script>
