<template>
  <div class="mb-9 mt-7 bg-white">
    <h2>{{ title }}</h2>
    <p v-if="description" class="pt-4 mb-0 text-body-m leading-7">
      {{ description }}
    </p>
    <button v-if="document.documentUrl" class="btn-secondary-black-m mt-4 mb-4" @click="goToUrl(document.documentUrl || '')">
      <SvgIcon :path="mdiDownload" :size="16" class="mr-2" />
      {{ t('Documents.downloadButtonText') }}
    </button>
    <br />
    <CheckBox
      v-if="document.requireAcknowledge"
      :model-value="isAcknowledged"
      :disabled="isAcknowledged"
      @update:model-value="handleAcknowledgedChanged"
    >
      {{ t('Documents.acknowledgeLabel') }}
    </CheckBox>
    <input
      ref="uploadButton"
      type="file"
      accept="application/zip, application/pdf, image/jpg, image/jpeg, image/png"
      class="hidden"
      @change="reUpload"
    />
    <div v-if="document.requireUpload" lazy-validation>
      <input
        ref="reUploadInput"
        class="hidden"
        type="file"
        accept="application/zip, application/pdf, image/jpg, image/jpeg, image/png"
        @input="reUpload"
      />
      <template v-if="isUploaded && currentBoothAcknowledgement">
        <div
          v-for="(uploadUrl, index) in currentBoothAcknowledgement.uploadsUrls"
          :key="index"
          class="flex bg-tailwind-100 border justify-between border-tailwind-500 mt-4 p-2 w-3/4"
        >
          <SvgIcon :path="mdiAttachment" :rotate="true" :size="25" class="text-tailwind-500 mr-2" />
          <span class="w-full text-tailwind-500">{{ extractFileName(uploadUrl) }}</span>
          <SvgIcon :path="mdiDelete" class="cursor-pointer text-black" :size="24" @click="openDialog(uploadUrl)" />
        </div>
      </template>
      <template v-if="isUploading">
        <div class="flex bg-white border justify-between border-grey-300 border-b-0 mt-4 p-2">
          <SvgIcon :path="mdiAttachment" :rotate="true" :size="25" class="text-black" />
          <span class="w-full">{{ t('Documents.fileUploading') }}</span>
        </div>
        <div class="w-full bg-grey-200 h-1">
          <div class="bg-victoria h-1" :style="{ width: `${uploadProgress}%` }"></div>
        </div>
      </template>
    </div>
    <button v-if="document.requireUpload" class="btn-primary-purple-m mt-6" @click="uploadButton?.click()">
      <SvgIcon :path="mdiUpload" class="mr-2 text-white" />
      <span v-if="currentBoothAcknowledgement && !!currentBoothAcknowledgement.uploadsUrls.length">
        {{ t('Documents.uploadAnotherFile') }}
      </span>
      <span v-else>
        {{ t('Documents.uploadFile') }}
      </span>
      <span> </span>
    </button>
    <ConfirmModal
      v-if="showModal"
      wrapper-class="max-w-4xl"
      :title="`Delete file`"
      :description="`Are you sure you want to delete the uploaded file? You will not be able to restore it, but you can upload it again.`"
      :approve="t('Documents.modalAcceptButton')"
      :cancel="t('Documents.modalCancelButton')"
      @close-dialog="closeDialog"
    />
  </div>
</template>

<script setup lang="ts">
import { mdiDownload, mdiAttachment, mdiDelete, mdiUpload } from '@mdi/js'
import * as Sentry from '@sentry/vue'
import type { ValuesType } from 'utility-types'
import { ref, computed, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import {
  type DocumentsQuery,
  useDeleteAcknowledgementUploadMutation,
  useCreateAcknowledgementMutation,
  useUpdateAcknowledgementMutation,
} from '@/gql/myomr'
import { toTypeAndId } from '@/helpers/globalIdHelper'
import { getLocalizedAttribute } from '@/helpers/localizedAttribute'
import { showNotification } from '@/helpers/notificationHelper'

export type SingleDocument = ValuesType<DocumentsQuery['documents']['nodes']>

const i18n = useI18n()
const t = i18n.t

const props = defineProps<{
  document: SingleDocument
  gid: string
}>()

const emit = defineEmits(['refetch'])

const file = ref<File | null>()
const reUploadInput = ref<HTMLInputElement | null>()
const uploadButton = ref<HTMLInputElement | null>()
const isAcknowledged = ref(false)

const showModal = ref(false)
const urlMarkedForDeletion = ref('')
function closeDialog(doDeleteDocument: boolean) {
  showModal.value = false
  if (doDeleteDocument) deleteDocument(urlMarkedForDeletion.value)
}

function goToUrl(url: string) {
  window.location.href = url
}

function openDialog(uploadUrl: string) {
  urlMarkedForDeletion.value = uploadUrl
  showModal.value = true
}

function reUpload(e: Event) {
  if (!(e.target instanceof HTMLInputElement)) return
  const target = e.target
  if (!target.files) return
  file.value = target.files[0]
  processUpload()
}

function handleAcknowledgedChanged(value: boolean) {
  if (value) {
    acknowledge()
  }
}

const boothId = computed(() => toTypeAndId(props.gid).id)
const boothType = computed(() => toTypeAndId(props.gid).type)

function checkIsAcknowledged() {
  if (!boothType.value || !boothId.value || !props.document.docAcknowledgements) return false
  return props.document.docAcknowledgements.some((doc) => {
    isAcknowledged.value = doc.signableId === boothId.value
  })
}

onMounted(() => checkIsAcknowledged())

watch(
  () => props.document,
  () => checkIsAcknowledged(),
  { deep: true },
)

const currentBoothAcknowledgement = computed(() => {
  if (!isAcknowledged.value || !props.document.docAcknowledgements) return null
  const currentBoothIndex = props.document.docAcknowledgements.findIndex((doc) => {
    return doc.signableId === boothId.value
  })
  if (currentBoothIndex < 0) return null
  return props.document.docAcknowledgements[currentBoothIndex]
})

const isUploaded = computed(() => {
  if (!props.document.docAcknowledgements) return false
  return props.document.docAcknowledgements.findIndex((doc) => {
    return doc.signableId === boothId.value && !!doc.uploadsUrls.length
  }) !== -1
    ? true
    : false
})

function extractFileName(fileUrl: string) {
  let fullFileName = fileUrl.split('/').pop() || ''
  fullFileName = fullFileName.split('?').shift() || fullFileName

  // Simplify filename display.
  // e.g. this: "2-d-Health-and-Safety.SalesViewer-GmbH.IMG_4953.2024-04-16-08-34-04.jpg"
  // to this: "IMG_4953.jpg"
  const regexp = /^[^.]{1,}\.[^.]{1,}\.([^.]{1,})\.[^.]{1,}\.(\w{3,4})$/i
  const match = fullFileName.match(regexp)

  if (match) {
    const originalFileName = match[1]
    const ext = match[2]
    return `${originalFileName}.${ext}`
  } else {
    return fullFileName
  }
}

const title = computed(() =>
  getLocalizedAttribute({
    de: props.document.titleDe,
    en: props.document.titleEn,
    fallback: '',
    locale: i18n.locale.value,
  }),
)

const description = computed(() =>
  getLocalizedAttribute({
    de: props.document.descriptionDe,
    en: props.document.descriptionEn,
    fallback: '',
    locale: i18n.locale.value,
  }),
)

const { mutate: createAcknowledgement } = useCreateAcknowledgementMutation()
async function acknowledge() {
  createAcknowledgement({
    resourceId: props.gid,
    documentId: props.document.id,
  }).then(() => emit('refetch'))
}

const uploadedAckId = ref('')

async function processUpload() {
  if (isAcknowledged.value) {
    await upload()
  } else {
    createAcknowledgement({
      resourceId: props.gid,
      documentId: props.document.id,
    }).then((result) => {
      const ackId = result?.data?.createAcknowledgement?.docAcknowledgement?.id || null
      if (ackId) uploadedAckId.value = ackId
      upload()
    })
  }
}

const { mutate: deleteAcknowledgementUpload } = useDeleteAcknowledgementUploadMutation()
async function deleteDocument(uploadUrl: string) {
  if (!currentBoothAcknowledgement.value) return false
  try {
    await deleteAcknowledgementUpload({
      docAcknowledgementId: currentBoothAcknowledgement.value.id,
      uploadUrl: uploadUrl,
    })
    urlMarkedForDeletion.value = ''
    emit('refetch')
  } catch (_e) {
    showNotification(t('Speakers.notifications.serverIssue'), 'error')
  }
}

const uploadProgress = ref(0)
const { mutate: updateAcknowledgement, loading: isUploading } = useUpdateAcknowledgementMutation({
  context: {
    fetchOptions: {
      useUpload: true,
      onProgress: (progress: ProgressEvent) => {
        uploadProgress.value = Math.round((progress.loaded / progress.total) * 100)
      },
    },
  },
})
async function upload() {
  const ackId = uploadedAckId.value || currentBoothAcknowledgement.value?.id
  if (!ackId) return false
  updateAcknowledgement({
    docAcknowledgementId: ackId,
    document: file.value,
  })
    .then(() => {
      uploadProgress.value = 0
    })
    .catch((e) => {
      showNotification(t('Speakers.notifications.serverIssue'), 'error')
      uploadProgress.value = 0
      Sentry.captureException(e)
    })
    .finally(() => {
      file.value = null
      emit('refetch')
      const button = uploadButton.value
      if (button) button.value = ''
    })
}
</script>
