<template>
  <div>
    <BackLink class="mt-4 mb-6" :to="{ name: 'EventAttendees' }">{{ t('back') }}</BackLink>
    <div class="bg-white p-5">
      <p class="text-headline-m">
        {{ t('Applicants.manualApplicantUpload.title') }}
      </p>
      <p class="my-3">
        {{ t('Applicants.manualApplicantUpload.description') }}
      </p>
      <div class="mt-7">
        <DropDown wrapper-class="w-full">
          <template #activator="{ open }">
            <button class="btn-primary-purple-m uppercase mr-6" @click.prevent="open">
              <SvgIcon :path="mdiDownload" :size="18" class="mr-2" />
              {{ t('Applicants.manualApplicantUpload.downloadCTA') }}
            </button>
          </template>
          <template #default>
            <ul class="list-none p-0 m-0 divide-solid divide-y divide-grey-300">
              <li v-for="format in exportFormats" :key="`format-${format}`" class="text-left py-2 px-2 cursor-pointer hover:bg-grey-100">
                <a :href="downloadUrl(format)" class="text-black visited:text-black flex">
                  {{ t(`exportFormats.${format}`) }}
                </a>
              </li>
            </ul>
          </template>
        </DropDown>
        <input
          ref="uploadButton"
          type="file"
          accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          class="hidden"
          @change="updateAttendees"
        />
        <button class="btn-primary-purple-m uppercase" @click.prevent="uploadButton?.click()">
          <SvgIcon :path="mdiUpload" :size="18" class="mr-2" />
          {{ t('Applicants.manualApplicantUpload.uploadCTA') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { mdiDownload, mdiUpload } from '@mdi/js'
import { DropDown } from '@ramp106/omrjs-core-ui'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import type { EventQuery } from '@/gql/myomr'
import { useUpdateAttendeesStateFromCsvMutation } from '@/gql/myomr'
import { toTypeAndId } from '@/helpers/globalIdHelper'
import { showNotification } from '@/helpers/notificationHelper'
import { eventApplicantsDownloadUrl, exportFormats, type exportFormat } from '@/services/ApiService'

const router = useRouter()
const { locale, t } = useI18n()

const uploadProgress = ref(0)
const file = ref<File>()
const uploadButton = ref<HTMLInputElement | null>()

export interface UpdateCsvAttendeeValue {
  approved: number
  rejected: number
  failed: number
}

const { mutate, onDone, onError } = useUpdateAttendeesStateFromCsvMutation({
  context: {
    fetchOptions: {
      useUpload: true,
      onProgress: (progress: ProgressEvent) => {
        const loadedProgress = Math.round((progress.loaded / progress.total) * 100)
        uploadProgress.value = loadedProgress
      },
    },
  },
})

const emit = defineEmits<{
  (e: 'updateCsvAttendeeNotification', value: UpdateCsvAttendeeValue): void
}>()

const props = defineProps<{
  event: EventQuery['eventById']
}>()

const downloadUrl = computed(() => {
  const id = props.event && toTypeAndId(props.event.id)['id']
  if (id != null) {
    return (format: exportFormat) => eventApplicantsDownloadUrl(id, locale.value, format)
  } else {
    return () => ''
  }
})

function updateAttendees(e: Event) {
  if (!(e.target instanceof HTMLInputElement)) return
  const target = e.target
  if (!target.files) return
  file.value = target.files[0]

  if (!file.value) return

  mutate({
    eventId: props.event.id,
    csvFile: file.value,
  })
  return
}

onDone((result) => {
  uploadProgress.value = 0
  const resultData = result?.data?.updateAttendeesStateFromCsv
  if (!resultData) return
  emit('updateCsvAttendeeNotification', {
    approved: resultData.accepted || 0,
    rejected: resultData.denied || 0,
    failed: resultData.failed || 0,
  })
  router.push({ name: 'EventAttendees' })
})

onError((error) => {
  console.error(error)
  uploadProgress.value = 0
  if (error) showNotification(error.message, 'error')
})
</script>
