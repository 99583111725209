<template>
  <div class="py-6">
    <ReviewsProductReviewsFilters />
    <ReviewsTable :loading="loading" :product-id="product.id" :survey-responses="result?.manage?.surveyResponses?.results || []" />
    <PaginationControl
      v-if="hasReviews"
      :page="pagination.page"
      :per-page="pagination.perPage"
      :total="pagination.total"
      show-range
      show-total
      show-pagination="always"
      @update:page="updatePage"
      @update:per-page="updatePerPage"
    />
    <WorkInProgressHint context="reviews" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useRouterQueryFilters } from '@/components/ReviewsManagement/Reviews/useRouterQueryReviewsFilters'
import WorkInProgressHint from '@/components/ReviewsManagement/WorkInProgressHint.vue'
import { useRouterQueryPagination } from '@/composables/useRouterQueryPagination'
import { type ProductData, SurveyResponsesSortByEnum, SortDirectionEnum, useReviewsProductSurveyResponsesQuery } from '@/gql/reviews'
import { useSortQuery } from '@/ui/TableLight'

import ReviewsTable from './ReviewsTable.vue'
import PaginationControl from '../../PaginationControl.vue'

const props = defineProps<{
  product: ProductData
}>()

const { page, perPage, updatePage, updatePerPage } = useRouterQueryPagination()
const { sortBy, sortDirection } = useSortQuery<SurveyResponsesSortByEnum, SortDirectionEnum>()
const { queryParams, updated_since, updated_to, reminder_status, reply_status } = useRouterQueryFilters()

const variables = computed(() => ({
  slug: props.product.slug,
  page: page.value,
  perPage: perPage.value,
  sortBy: sortBy.value,
  sortDirection: sortDirection.value,
  filters: {
    updatedSince: updated_since.value,
    updatedTo: updated_to.value,
    rating: queryParams.value.ratings,
    state: queryParams.value.states,
    reminderStatus: reminder_status.value,
    replyStatus: reply_status.value,
  },
}))

const { loading, result } = useReviewsProductSurveyResponsesQuery(variables, { clientId: 'reviews' })

const hasReviews = computed(
  () => result?.value?.manage?.surveyResponses?.results && result?.value?.manage?.surveyResponses?.results.length > 0,
)

const pagination = computed(() => ({
  page: result.value?.manage?.surveyResponses?.pagination.currentPage ?? page.value,
  perPage: result.value?.manage?.surveyResponses?.pagination.perPage ?? perPage.value,
  total: result.value?.manage?.surveyResponses?.pagination.totalCount ?? result.value?.manage?.surveyResponses?.results.length ?? 0,
}))
</script>
