import { ApolloClient } from '@apollo/client/core'

import { apolloClientOptions } from './apolloClientOptions'
import genericErrorLink from './genericErrorLink'
import localeLink from './localeLink'
import partnerCompanyLink from './partnerCompanyLink'
import { reviewsHttpLink } from './reviewsHttpLink'

export const reviewsApolloClient = new ApolloClient({
  ...apolloClientOptions,
  link: localeLink.concat(partnerCompanyLink).concat(genericErrorLink).concat(reviewsHttpLink),
  devtools: {
    enabled: process.env.NODE_ENV === 'development',
  },
})
