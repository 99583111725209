<template>
  <div class="card p-0 relative">
    <div v-if="hasHeader" class="px-6 border-b border-grey-300">
      <slot name="header"></slot>
    </div>
    <div v-if="hasSubheader" class="px-6 border-b border-grey-300">
      <slot name="subheader"></slot>
    </div>
    <div :class="{ 'px-6': !noPadding, '': noPadding }">
      <slot></slot>
    </div>
    <div v-if="hasFooter" class="p-6 border-t border-grey-300">
      <slot name="footer"></slot>
    </div>
    <div v-if="loading" class="absolute top-0 left-0 w-full h-full flex justify-center items-center backdrop-opacity-50 bg-white/70">
      <CircleSpinner /> <span v-if="!!progress" class="ml-3 text-body-l">{{ progress }}%</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CircleSpinner } from '@ramp106/omrjs-core-ui'
import { computed, useSlots } from 'vue'
const slots = useSlots()

defineProps<{
  noPadding?: boolean
  loading?: boolean
  progress?: number
}>()

const hasHeader = computed(() => {
  return !!slots['header']
})

const hasSubheader = computed(() => {
  return !!slots['subheader']
})

const hasFooter = computed(() => {
  return !!slots['footer']
})
</script>
