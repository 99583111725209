import { useUiStore } from '@/stores'
import type { UiNotification, UiNotificationType } from '@/stores/ui'

type Notification = Omit<UiNotification, 'id'> & { timeout?: number }

const DEFAULT_TIMEOUT = 5000

function showNotification(message: string, type: UiNotificationType, timeout?: number): UiNotification
function showNotification(notification: Notification): UiNotification
function showNotification(messageOrNotification: string | Notification, type?: UiNotificationType, timeout?: number): UiNotification {
  const uiStore = useUiStore()

  let payload: UiNotification
  let notificationTimeout: number | undefined

  if (typeof messageOrNotification === 'string') {
    payload = {
      type: type as UiNotificationType,
      message: messageOrNotification,
    }
    notificationTimeout = timeout
  } else {
    payload = {
      type: messageOrNotification.type,
      message: messageOrNotification.message,
      headline: messageOrNotification.headline,
    }
    notificationTimeout = messageOrNotification.timeout
  }

  const notification = uiStore.addNotification(payload)
  window.setTimeout(() => uiStore.removeNotification(notification), notificationTimeout || DEFAULT_TIMEOUT)

  return notification
}
export { showNotification }
