<template>
  <hr id="divider" />
</template>

<style scoped>
#divider {
  width: 100%;
  height: 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
