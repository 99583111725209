<template>
  <ContentCard :loading="loadingUpdateProduct">
    <template #header>
      <ReviewsProductTabs />
    </template>
    <template #subheader>
      <ContentCardSubheader :back-to="{ name: ReviewsRouteName.ReviewsProductViewGlobalFeatures }">
        {{ t('Reviews.product.edit.globalFeaturesTitle', { productName: product.title }) }}
        <template #actions>
          <RouterLink
            class="btn-secondary-purple-m mr-2"
            :to="{ name: ReviewsRouteName.ReviewsProductViewGlobalFeatures, params: { productId: product.id } }"
          >
            {{ t('Reviews.product.action.cancel') }}
          </RouterLink>
          <button class="btn-primary-purple-m" @click="onSubmit">{{ t('Reviews.product.action.save') }}</button>
        </template>
      </ContentCardSubheader>
    </template>
    <div>
      <div class="flex text-grey-800 mt-6">
        <MdiSvg class="mr-1" :path="mdiInformationOutline" />
        <div class="text-body-m">{{ t('Reviews.product.edit.globalFeaturesHint') }}</div>
      </div>
      <ProductGlobalFeaturesProvider
        class="grid grid-cols-3 gap-6 my-6"
        :product="{
          cloud: product.cloud,
          anytimeSupport: product.anytimeSupport,
          onPremise: product.onPremise,
          germanSupport: product.germanSupport,
          germanLocation: product.germanLocation,
          euLocation: product.euLocation,
          euStandardContract: product.euStandardContract,
          euCompanyAffiliated: product.euCompanyAffiliated,
          freePlanAvailable: product.freePlanAvailable,
          freeTrial: product.freeTrial,
        }"
      >
        <template #default="{ dataPrivacy, setupAndSupport, versions }">
          <ProductFeaturesGroup
            :global-features="formData"
            :items="setupAndSupport.items"
            :title="setupAndSupport.title"
            @change="updateGlobalFeature"
          />
          <ProductFeaturesGroup
            :global-features="formData"
            :items="dataPrivacy.items"
            :title="dataPrivacy.title"
            @change="updateGlobalFeature"
          />
          <ProductVersionsGroup :items="versions.items" :title="versions.title" />
        </template>
      </ProductGlobalFeaturesProvider>
    </div>
  </ContentCard>
</template>
<script setup lang="ts">
import { mdiInformationOutline } from '@mdi/js'
import { MdiSvg } from '@ramp106/omrjs-core-ui'
import { ProductGlobalFeaturesProvider, type ProductGlobalFeaturesTypes } from '@ramp106/omrjs-reviews-ui'
import { inject, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import type { ProductById } from '@/gql/reviews'
import {
  ProductAnytimeSupportEnum,
  ProductCloudEnum,
  ProductEuCompanyAffiliatedEnum,
  ProductEuLocationEnum,
  ProductEuStandardContractEnum,
  ProductGermanLocationEnum,
  ProductGermanSupportEnum,
  ProductOnPremiseEnum,
  useUpdateReviewsProductMutation,
} from '@/gql/reviews'
import { showNotification } from '@/helpers/notificationHelper'
import { ReviewsRouteName } from '@/router/types'
import { reloadReviewsProductKey } from '@/symbols/reloadReviewsProductKey'
import ContentCard from '@/ui/ContentCard.vue'
import ContentCardSubheader from '@/ui/ContentCardSubheader.vue'

import ProductVersionsGroup from './/ProductVersionsGroup.vue'
import ReviewsProductTabs from './../ReviewsProductTabs.vue'
import ProductFeaturesGroup from './ProductFeaturesGroup.vue'
import type { ReviewsProductGlobalFeaturesEditFormData } from '../types'

const props = defineProps<{
  product: ProductById
}>()

const { t } = useI18n()
const router = useRouter()
const reloadReviewsProduct = inject(reloadReviewsProductKey) as () => void

const {
  mutate: mutateProduct,
  loading: loadingUpdateProduct,
  onDone,
  onError,
} = useUpdateReviewsProductMutation({
  clientId: 'reviews',
})

const formData = reactive<ReviewsProductGlobalFeaturesEditFormData>({
  cloud: props.product.cloud || null,
  onPremise: props.product.onPremise || null,
  anytimeSupport: props.product.anytimeSupport || null,
  germanSupport: props.product.germanSupport || null,
  germanLocation: props.product.germanLocation || null,
  euLocation: props.product.euLocation || null,
  euCompanyAffiliated: props.product.euCompanyAffiliated || null,
  euStandardContract: props.product.euStandardContract || null,
})

function updateGlobalFeature(value: { key: string; value: ProductGlobalFeaturesTypes.FeatureStatus }) {
  formData[value.key] = value.value
}

async function onSubmit() {
  await mutateProduct({
    id: props.product.id,
    attributes: {
      cloud: formData.cloud as ProductCloudEnum,
      onPremise: formData.onPremise as ProductOnPremiseEnum,
      anytimeSupport: formData.anytimeSupport as ProductAnytimeSupportEnum,
      germanSupport: formData.germanSupport as ProductGermanSupportEnum,
      germanLocation: formData.germanLocation as ProductGermanLocationEnum,
      euLocation: formData.euLocation as ProductEuLocationEnum,
      euCompanyAffiliated: formData.euCompanyAffiliated as ProductEuCompanyAffiliatedEnum,
      euStandardContract: formData.euStandardContract as ProductEuStandardContractEnum,
    },
  })
}

onDone(() => {
  reloadReviewsProduct()
  showNotification(t('Reviews.product.notifications.savedSuccessfully', { name: props.product.title }), 'success')
  router.push({ name: ReviewsRouteName.ReviewsProductViewGlobalFeatures, params: { productId: props.product.id } })
})

onError((error) => showNotification(error.message, 'error'))
</script>
