<template>
  <div>
    <div class="text-body-m-bold">
      {{ title }}
    </div>
    <div class="text-body-s">
      {{ description }}
    </div>
    <div class="text-input-base flex items-center gap-2.5 mt-2.5" :class="v$.inputValue.$error ? 'border-error' : 'border-default'">
      <MdiSvg :path="mdiEmailOutline" class="text-grey-700" size="20" />
      <div class="grow flex flex-wrap items-center gap-1">
        <ChipBadge
          v-for="(item, index) in emailAddresses"
          :key="`${item}-${index}`"
          class="flex-none"
          color="purple"
          size="m"
          :type="selectedChip === index ? 'solid' : 'default'"
        >
          {{ item }}
          <button class="ml-1 -mr-1" @click="removeEmail(index)">
            <MdiSvg :path="mdiClose" size="12" />
          </button>
        </ChipBadge>
        <input
          ref="input"
          v-model="inputValue"
          type="text"
          class="grow min-w-40 outline outline-0"
          @keydown="handleKeydown"
          @blur="addEmailFromInput"
        />
      </div>
    </div>
    <InputAssistiveText :error="getValidationErrors(v$.inputValue)" />
  </div>
</template>

<script setup lang="ts">
import { mdiClose, mdiEmailOutline } from '@mdi/js'
import { ChipBadge, InputAssistiveText, MdiSvg } from '@ramp106/omrjs-core-ui'
import { useVuelidate } from '@vuelidate/core'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { getValidationErrors, uniqueEmailRule } from '@/helpers/validationHelper.ts'
import { email } from '@/services/validators'

const { t } = useI18n()
const props = defineProps<{
  title: string
  description: string
  modelValue: string[]
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string[]): void
}>()

const emailAddresses = ref<string[]>([...props.modelValue])
const inputValue = ref('')
const selectedChip = ref<number | null>(null)

const rules = {
  inputValue: {
    email,
    uniqueEmail: uniqueEmailRule(t, { existingEmails: emailAddresses.value }),
  },
}

const v$ = useVuelidate(rules, { inputValue })

const removeEmail = (index: number) => {
  emailAddresses.value.splice(index, 1)
  emit('update:modelValue', emailAddresses.value)
  selectedChip.value = null
}

const addEmailFromInput = () => {
  inputValue.value = inputValue.value.trim()
  if (inputValue.value) {
    v$.value.inputValue.$touch()
    if (!v$.value.inputValue.$error) {
      emailAddresses.value.push(inputValue.value)
      emit('update:modelValue', emailAddresses.value)
      inputValue.value = ''
    }
  }
}

const handleKeydown = (e: KeyboardEvent) => {
  if (e.key === ' ' || e.key === 'Enter') {
    e.preventDefault()
    addEmailFromInput()
  } else if (e.key === 'Backspace') {
    if (!inputValue.value) {
      if (selectedChip.value === null && emailAddresses.value.length > 0) {
        selectedChip.value = emailAddresses.value.length - 1
      } else if (selectedChip.value !== null) {
        removeEmail(selectedChip.value)
      }
    } else {
      selectedChip.value = null
    }
  } else if (e.key === 'Escape') {
    inputValue.value = ''
    selectedChip.value = null
  } else {
    selectedChip.value = null
  }
}

watch(
  () => inputValue.value,
  (newValue) => {
    if (newValue === '') {
      v$.value.inputValue.$reset()
    }
  },
)
</script>

<style scoped>
.text-input-base {
  @apply w-full p-2;
  @apply bg-white dark:bg-black;
  @apply text-black dark:text-white;
  @apply rounded-[4px] border border-solid;
  @apply focus:border-grey-900 focus:outline-0 dark:focus:border-white;
}

.border-default {
  @apply disabled:border-grey-500;
  @apply border-grey dark:border-grey-900;
}

.border-error {
  @apply border-red placeholder-shown:border-red;
  @apply dark:border-red-200 dark:placeholder-shown:border-red-200;
}
</style>
