<template>
  <PageBreadcrumbs :items="breadcrumbs" />
  <RouterView :product="product" />
</template>
<script setup lang="ts">
import PageBreadcrumbs from '@/components/PageBreadcrumbs.vue'
import { useReviewsProductBreadcrumbs } from '@/composables/breadcrumbs/useReviewsProductBreadcrumbs'
import type { ProductById } from '@/gql/reviews'

const props = defineProps<{
  product: ProductById
}>()

const breadcrumbs = useReviewsProductBreadcrumbs(props.product)
</script>
