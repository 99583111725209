<template>
  <ConfirmModal hide-actions wrapper-class="max-w-2xl" @close-dialog="emit('closeDialog')">
    <template #title></template>
    <template #description>
      <div class="pt-8 pb-4 px-8">
        <div class="border-b border-grey-300 pb-5 flex justify-between">
          <div class="pr-8">
            {{ t('attendee.statusHelp.description') }}
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div class="pb-8 px-8">
        <div v-for="chipItem in chipData" :key="chipItem.name" class="inline-flex w-full py-2 justify-between items-center">
          <div :class="chipItem.class">{{ t(`attendee.${chipItem.name}`) }}</div>
          <p class="w-[78%]">
            {{ t(`attendee.statusDescriptions.${chipItem.name}`) }}
          </p>
        </div>
      </div>
    </template>
  </ConfirmModal>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { getStatusColor } from './statusHelper.tailwind'

const { t } = useI18n()

const emit = defineEmits(['closeDialog'])
const chipData = [
  { name: 'requested', class: getStatusColor('requested', false) },
  { name: 'denied', class: getStatusColor('denied', false) },
  { name: 'accepted', class: getStatusColor('accepted', false) },
  { name: 'acceptedSuccessor', class: getStatusColor('accepted', true) },
]
</script>
