<template>
  <div class="flex flex-col gap-y-10 py-6">
    <PreferenceItem
      :items="product.vendorEmailAddresses || []"
      :description="t('Reviews.product.view.preferences.vendorEmails.description')"
      :title="t('Reviews.product.view.preferences.vendorEmails.title')"
    />
    <PreferenceItem
      :items="product.contactFormRecipients || []"
      :description="t('Reviews.product.view.preferences.contactEmails.description')"
      :title="t('Reviews.product.view.preferences.contactEmails.title')"
    />
    <PreferenceItem
      :items="product.pageVisitReportEmails || []"
      :description="t('Reviews.product.view.preferences.pageVisitReportEmails.description')"
      :title="t('Reviews.product.view.preferences.pageVisitReportEmails.title')"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import PreferenceItem from '@/components/ReviewsManagement/Preferences/PreferenceItem.vue'
import type { ProductById } from '@/gql/reviews'

defineProps<{
  product: ProductById
}>()

const { t } = useI18n()
</script>
