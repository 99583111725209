import { provideApolloClients } from '@vue/apollo-composable'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'

import { CustomerType, Locale, useManageContentConfigsQuery } from '@/gql/hygraph/hygraph.generated'
import type { TagListTypeEnum } from '@/gql/myomr'
import { getLocale } from '@/helpers/locale'
import { hygraphApolloClient } from '@/plugins/vue-apollo'
import { useMeStore, useUiStore } from '@/stores'

const containsAny = (list: TagListTypeEnum[], toContainAnyFromThis: CustomerType[]) => {
  return !!toContainAnyFromThis.map((i) => i as string).find((i) => list.map((l) => l as string).includes(i))
}

export const useContentPages = () => {
  const uiStore = useUiStore()
  const { locale } = storeToRefs(uiStore)
  const requestLocale = ref<'en' | 'de'>(locale.value ?? getLocale())

  provideApolloClients({ hygraph: hygraphApolloClient })
  const { refetch, result } = useManageContentConfigsQuery(
    { locales: [requestLocale.value == 'en' ? Locale.En : Locale.De] },
    { clientId: 'hygraph' },
  )

  const { me } = storeToRefs(useMeStore())

  const ready = computed(() => !!result.value?.manageContentConfigs)
  const configs = computed(() => {
    return result.value?.manageContentConfigs ?? []
  })

  const sidebarItems = computed(
    () =>
      result.value?.manageContentConfigs
        .filter((cp) => cp.loggedInSlug?.length && cp.menuTitle?.length)
        .filter((cp) => (cp.customerTypes?.length ? containsAny(me.value?.partnerCompany?.tagList ?? [], cp.customerTypes) : true))
        .map((cp) => {
          return { to: '/content/' + cp.loggedInSlug, title: cp.menuTitle }
        }) ?? [],
  )

  const getContentSlug = computed(() => {
    return (slug: string, isLoggedIn: boolean) => {
      if (!ready.value) return 'NA'
      return (
        configs.value.find((c) => {
          if (!isLoggedIn) return c.anonymousSlug == slug
          else return c.anonymousSlug == slug || c.loggedInSlug == slug
        })?.contentSlug || '404'
      )
    }
  })

  watch(
    () => locale.value,
    (newLocale) => {
      if (newLocale != requestLocale.value) {
        requestLocale.value = newLocale
        refetch({ locales: [requestLocale.value == 'en' ? Locale.En : Locale.De] })
      }
    },
  )

  return {
    sidebarItems,
    getContentSlug,
    configs,
  }
}
