import * as Sentry from '@sentry/vue'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { authority, requiredProfileScopes } from '@/config/env'
import { type Role, type MeQuery, ProfileScopeEnum } from '@/gql/myomr'
import { toTypeAndId } from '@/helpers/globalIdHelper'
import { redirectToLogin } from '@/helpers/loginHelper'
import type { ID } from '@/types'

import { useUiStore } from './ui'

const isProfileComplete = function (profileScopes: string[]) {
  return requiredProfileScopes.every((scope) => profileScopes?.includes(scope))
}

export const persistKey = 'omr-manage-me'
export const useMeStore = defineStore(
  'me',
  () => {
    const uiStore = useUiStore()
    const currentPartnerCompanyGlobalId = ref<ID | undefined>(undefined)
    const roles = ref<Role[]>([])
    const boothsVisible = ref(false)
    const hasPremiumBooths = ref(false)

    const currentPartnerCompanyId = computed<string>(() =>
      currentPartnerCompanyGlobalId.value ? (toTypeAndId(currentPartnerCompanyGlobalId.value)['id'] as string) : '',
    )
    const currentRoles = computed(() => {
      if (!currentPartnerCompanyId.value) {
        const AllowedNoPartnerCompanyRoles = ['quota_user']

        return (roles.value ?? []).filter((role: Role) => {
          return AllowedNoPartnerCompanyRoles.includes(role.name)
        })
      }

      return (roles.value ?? []).filter((role: Role) => {
        return role.resourceType === 'PartnerCompany' && role.resourceId == currentPartnerCompanyId.value
      })
    })

    const rawMe = ref<MeQuery['me'] | undefined>()
    const userFirstName = ref('')
    const userLastName = ref('')
    const userEmail = ref('')
    const userId = ref('')
    const userProfileImagePath = ref('')
    const userName = computed(() => [userFirstName.value, userLastName.value].filter((name) => name != '').join(' '))
    const profileScopes = ref<ProfileScopeEnum[]>([])
    const hasAcceptedReviewsTerms = ref(false)

    const userProfileImage = computed(() => {
      if (userProfileImagePath.value) {
        if (userProfileImagePath.value.startsWith('http')) {
          return userProfileImagePath.value
        } else {
          return `${authority}${userProfileImagePath.value}`
        }
      } else {
        return null
      }
    })

    const leadGenLimit = computed(() => {
      return (eventSlug: string) => {
        return rawMe.value?.partnerCompany?.leadGenLimitConfigs.find((l) => l.event.slug == eventSlug)?.limit
      }
    })

    function setCurrentPartnerCompanyGlobalId(id: ID) {
      currentPartnerCompanyGlobalId.value = id
    }

    function setupSentryUser(me: NonNullable<MeQuery['me']>) {
      Sentry.setUser({ id: me.id })
    }

    function setupCurrentUser(data: MeQuery) {
      if (data?.me) {
        rawMe.value = data.me
        if (!isProfileComplete(data.me.profileScopes)) {
          redirectToLogin(uiStore.locale || 'de')
          return
        }

        profileScopes.value = data.me.profileScopes
        hasAcceptedReviewsTerms.value = profileScopes.value.includes(ProfileScopeEnum.ReviewsTerms)

        roles.value = data.me.roles
        if (data.me.partnerCompany?.id) setCurrentPartnerCompanyGlobalId(data.me.partnerCompany.id)
        userFirstName.value = data.me.firstName || ''
        userLastName.value = data.me.lastName || ''
        userEmail.value = data.me.email || ''
        userProfileImagePath.value = data.me.profileImage || ''
        userId.value = data.me.id

        setupSentryUser(data.me)
      }
      if (data?.booths?.nodes?.length) {
        boothsVisible.value = true
        setHasPremiumBooths(data.booths.nodes)
      }
    }
    function setHasPremiumBooths(boothList: MeQuery['booths']['nodes']) {
      const FIRST_VALID_DATE = new Date('11.29.2022.')
      const validBooths = boothList.filter((booth: MeQuery['booths']['nodes'][number]) => {
        return booth.premium === true && FIRST_VALID_DATE <= new Date(booth.createdAt as string)
      })
      if (validBooths.length) hasPremiumBooths.value = true
    }

    return {
      boothsVisible,
      currentPartnerCompanyGlobalId,
      currentPartnerCompanyId,
      currentRoles,
      hasAcceptedReviewsTerms,
      hasPremiumBooths,
      profileScopes,
      leadGenLimit,
      roles,
      setCurrentPartnerCompanyGlobalId,
      setupCurrentUser,
      me: rawMe,
      userEmail,
      userName,
      userProfileImage,
    }
  },
  {
    persist: {
      key: persistKey,
      pick: ['boothsVisible', 'currentPartnerCompanyGlobalId'],
      storage: sessionStorage,
    },
  },
)
