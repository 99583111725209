<template>
  <div>
    <AlertBox v-if="!canViewSpeakers" type="warning" class="mt-5">
      {{ t('PartnerCompany.noPartnerCompany') }}
    </AlertBox>
    <div v-if="canViewSpeakers">
      <div class="pt-2 pb-5">
        <h1>{{ t('Speakers.assignEvent') }}</h1>

        <BackLink class="mt-4" :to="{ name: 'speakerList' }">{{ t('backToOverview') }}</BackLink>

        <div>
          <SpeakerEventsForm
            :show-confirm-modal="true"
            :title="t('SpeakerEventsForm.headline')"
            :speaker="speaker"
            :is-uploading="loading"
            @submit-form="save"
          >
            <template #navHeader>
              <div class="relative pt-6">
                <div class="flex align-start">
                  <img :src="speaker.photoUrl || ''" class="mr-6 h-[160px] mb-8" />
                  <div>
                    <p class="text-headline-m">{{ speaker.name }}</p>
                    <p class="text-body-s text-grey-800 mt-2 mb-4">
                      {{ speaker.partnerCompanyName }} <span v-if="speaker.title">· {{ speaker.title }}</span>
                    </p>
                    <div>
                      <div v-for="interest in speaker.interests" :key="interest.id" class="chip bg-black mr-2">{{ interest.text }}</div>
                    </div>
                    <div class="mt-6">
                      <a v-if="speaker.linkedinUrl" target="_blank" class="inline-block mr-5" :href="speaker.linkedinUrl"
                        ><img :src="`/assets/images/linkedin-icon.svg`"
                      /></a>
                      <a v-if="speaker.twitterUrl" target="_blank" class="inline-block mr-5" :href="speaker.twitterUrl"
                        ><img :src="`/assets/images/twitter-icon.svg`"
                      /></a>
                      <a v-if="speaker.instagramUrl" target="_blank" class="inline-block mr-5" :href="speaker.instagramUrl"
                        ><img :src="`/assets/images/instagram-icon.svg`"
                      /></a>
                      <a v-if="speaker.websiteUrl" target="_blank" class="inline-block mr-5" :href="speaker.websiteUrl"
                        ><img :src="`/assets/images/web-icon.svg`"
                      /></a>
                    </div>
                    <p class="mt-5 pb-8">{{ speakerDescription }}</p>
                  </div>
                </div>
                <div class="absolute right-0 top-6 text-right">
                  <p>{{ t('Speaker.falseInfo') }}</p>
                  <a href="mailto:exhibitors@omr.com">{{ t('Speaker.contactUs') }}</a>
                </div>
              </div>
            </template>
          </SpeakerEventsForm>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { type Speaker, useCreateEventSpeakerMutation, useDeleteEventSpeakerMutation } from '@/gql/myomr'
import { showNotification } from '@/helpers/notificationHelper'
import { useRoleRedirect } from '@/services/roleRedirect'
import { $can } from '@/services/roles'

useRoleRedirect('viewSpeakers')

const router = useRouter()

const { t, locale } = useI18n()

const canViewSpeakers = computed(() => {
  return $can('viewSpeakers', { for: 'User' })
})

const props = defineProps<{
  speaker: Speaker
}>()

const emit = defineEmits(['refetch'])

const speakerDescription = computed(() => {
  return locale.value === 'de' ? props.speaker.descriptionDe : props.speaker.descriptionEn
})

const { mutate: createEventSpeaker, loading: createEventSpeakerLoading } = useCreateEventSpeakerMutation()
const { mutate: deleteEventSpeaker, loading: deleteEventSpeakerLoading } = useDeleteEventSpeakerMutation()
const loading = computed(() => createEventSpeakerLoading.value || deleteEventSpeakerLoading.value)

async function save(eventSpeakerData: { eventsToAdd: Array<string>; eventSpeakersToRemove: Array<string> }) {
  let hasErrors = false
  try {
    await Promise.all(
      eventSpeakerData.eventsToAdd.map((eventId) =>
        createEventSpeaker({
          eventId: eventId,
          speakerId: props.speaker.id,
        }),
      ),
    )
  } catch (_err) {
    hasErrors = true
    showNotification(t('Speakers.notifications.eventsCreationIssue'), 'error')
  }

  try {
    await Promise.all(
      eventSpeakerData.eventSpeakersToRemove.map((eventSpeakerId) =>
        deleteEventSpeaker({
          eventSpeakerId,
        }),
      ),
    )
  } catch (_err) {
    hasErrors = true
    showNotification(t('Speakers.notifications.eventsRemovalIssue'), 'error')
  }

  if (!hasErrors) showNotification(t('Speaker.successfullySaved'), 'success')
  window.scrollTo(0, 0)
  emit('refetch')
  router.push({ name: 'speakerList' })
}
</script>
