<template>
  <ConfirmModal persist :approve="t('ProspectsCatalog.createListModal.title')" wrapper-class="w-[100%] max-w-xl" @close-dialog="handleForm">
    <template #title>
      <h1 class="text-headline-m p-6 pb-5">{{ t('ProspectsCatalog.createListModal.title') }}</h1>
    </template>
    <template #content>
      <div class="p-6">
        <TextInput
          v-model="formData.listName"
          :label="t('ProspectsCatalog.createListModal.listNameLabel') + ' *'"
          :error="getValidationErrors($validations.listName)"
        />
        <TextInput v-model="formData.event.name" label="Event" disabled />
        <SelectInput
          v-model="formData.contactReason"
          :disabled="isDmcEvent"
          :error="getValidationErrors($validations.contactReason)"
          :label="t('ProspectsCatalog.createListModal.contactReasonLabel') + ' *'"
        >
          <option v-for="(value, key) in contactReasons" :key="`select_${key}`" :value="key">{{ value }}</option>
        </SelectInput>

        <p class="my-5">{{ t('ProspectsCatalog.createListModal.description') }}</p>
        <TextInput
          v-model="formData.contactName"
          :error="getValidationErrors($validations.contactName)"
          :label="t('ProspectsCatalog.createListModal.contactNameLabel') + ' *'"
        />
        <TextInput
          v-model="formData.contactEmail"
          type="email"
          :error="getValidationErrors($validations.contactEmail)"
          class="mt-6"
          :label="t('ProspectsCatalog.createListModal.contactEmailLabel') + ' *'"
        />
      </div>
    </template>
  </ConfirmModal>
</template>

<script setup lang="ts">
import { TextInput, SelectInput } from '@ramp106/omrjs-core-ui'
import { useVuelidate } from '@vuelidate/core'
import { reactive, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { useCreateProspectListMutation } from '@/gql/myomr'
import type { MeQuery, ProspectListsQuery } from '@/gql/myomr'
import { toPlainId } from '@/helpers/globalIdHelper'
import { showNotification } from '@/helpers/notificationHelper'
import { getValidationErrors } from '@/helpers/validationHelper'
import { required, email } from '@/services/validators'

const { t } = useI18n()

const router = useRouter()

const { mutate: createProspectListMutation } = useCreateProspectListMutation()

const props = defineProps<{
  me: NonNullable<MeQuery['me']>
  event: NonNullable<ProspectListsQuery['prospectLists']>['nodes'][number]['event']
}>()

const isDmcEvent = computed(() => props.event?.slug?.startsWith('dmc'))

const formData = reactive({
  listName: '',
  event: props.event,
  contactName: '',
  contactEmail: '',
  contactReason: isDmcEvent.value ? 'invitation' : '',
})

const contactReasons = computed(() => {
  if (isDmcEvent.value) {
    return { invitation: t('ProspectsCatalog.createListModal.contactReasons.Invitation') }
  } else {
    return {
      ticket_invitation: t('ProspectsCatalog.createListModal.contactReasons.TicketInvitation'),
      make_an_appointment: t('ProspectsCatalog.createListModal.contactReasons.MakeAnAppointment'),
    }
  }
})

const emit = defineEmits<{
  (e: 'refetchProspectLists'): void
}>()

const formRules = {
  listName: { required },
  event: { required },
  contactName: { required },
  contactEmail: { required, email },
  contactReason: { required },
}

const $validations = useVuelidate(formRules, formData)

function handleError() {
  showNotification(t('ProspectsCatalog.createListModal.failedNotification'), 'error', 3000)
  router.push({ name: 'prospectLists' })
}

async function handleForm(shouldSubmit: boolean) {
  if (!shouldSubmit) return router.push({ query: { modal: undefined } })

  const validForm = await $validations.value.$validate()
  if (!validForm) return
  if (!props.me?.partnerCompany?.id) return

  try {
    const result = await createProspectListMutation({
      eventId: formData.event.id,
      partnerCompanyId: props.me.partnerCompany.id,
      contactName: formData.contactName,
      contactEmail: formData.contactEmail,
      contactReason: formData.contactReason,
      title: formData.listName,
    })
    if (result?.data?.createProspectList?.prospectList?.id) {
      showNotification(t('ProspectsCatalog.createListModal.successfulNotification'), 'success', 3000)
      const listId = toPlainId(result.data.createProspectList.prospectList.id) as string
      router.push({ name: 'prospectList', params: { listId } })
    } else {
      handleError()
    }
  } catch (_e) {
    handleError()
  }
  emit('refetchProspectLists')
}
</script>
