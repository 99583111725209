<template>
  <TooltipWrapper>
    <template #trigger>
      <ReviewInteractionStatusIcon :type="type" />
    </template>
    <template v-if="text" #content>
      <div class="max-w-xs">{{ text }}</div>
    </template>
  </TooltipWrapper>
</template>

<script setup lang="ts">
import { TooltipWrapper } from '@ramp106/omrjs-core-ui'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import ReviewInteractionStatusIcon from './ReviewInteractionStatusIcon.vue'
import { ReviewInteractionType } from './types'

const props = defineProps<{
  type: ReviewInteractionType
  tooltipText?: string
}>()

const { t } = useI18n()

const text = computed(() => {
  switch (props.type) {
    case ReviewInteractionType.REMINDER:
      return t('Reviews.product.review.noRemindersStatusHint')
    case ReviewInteractionType.REMINDER_CHECK:
      return props.tooltipText || ''
    case ReviewInteractionType.REPLY:
      return t('Reviews.product.review.noRepliesStatusHint')
    case ReviewInteractionType.REPLY_CHECK:
      return props.tooltipText || t('Reviews.product.review.replyStatusHint')
    default:
      return ''
  }
})
</script>
