<template>
  <tr>
    <td>
      <div class="flex gap-1">
        <div class="chip bg-grey-300 text-black">
          {{ eventName }}
        </div>
      </div>
    </td>
    <td>{{ props.company.name }}</td>
    <td>{{ props.company.position }}</td>
    <td>{{ props.company.department }}</td>
    <td>{{ companyJobLevel }}</td>
    <td>{{ companyField }}</td>
    <td>{{ companyIndustry }}</td>
    <td>{{ companySize }}</td>
    <td>{{ companyCountry }}</td>
  </tr>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { ProspectFacetItems } from '@/components/Prospects/prospectFacetItems'
import type { ProspectCompany } from '@/gql/myomr'

import type { ProspectItem } from './types'

const props = defineProps<{
  company: ProspectCompany
  facetItems: ProspectFacetItems
  prospect: ProspectItem
}>()

const companyFieldMapper = function (facets: Array<{ text: string; value: string }>, value: string | undefined | null) {
  return computed(() => {
    return (
      facets.find((facet) => {
        return facet.value == value
      })?.text || value
    )
  })
}

const companyField = companyFieldMapper(props.facetItems.companyFieldItems, props.company.field)
const companyIndustry = companyFieldMapper(props.facetItems.companyIndustryItems, props.company.industry)
const companyJobLevel = companyFieldMapper(props.facetItems.companyJobLevelItems, props.company.jobLevel)
const companySize = companyFieldMapper(props.facetItems.companySizeItems, props.company.size)
const companyCountry = companyFieldMapper(props.facetItems.companyCountryItems, props.company.country)
const eventName = computed(() => props.prospect.visitedEvents.find((event) => event.slug === props.company.eventSlug)?.name)
</script>
