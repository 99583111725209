<template>
  <div class="flex justify-between p-4 border-b border-grey-300">
    <div>
      <RouterLink class="text-headline-s text-black visited:text-black" :to="{ name: 'boothDetails', params: { boothId: boothId } }">
        {{ label }}
      </RouterLink>
      <BoothItemData
        v-if="props.booth"
        class="opacity-75 mt-4"
        :booth-number="props.booth.boothNumber"
        :type-display-name="boothDisplayName"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import type { BoothsQuery } from '@/gql/myomr'
import { toTypeAndId } from '@/helpers/globalIdHelper'
import { getLocalizedAttribute } from '@/helpers/localizedAttribute'

import BoothItemData from './BoothItemData.vue'

const i18n = useI18n()

const props = defineProps<{
  booth: BoothsQuery['booths']['nodes'][number]
}>()

const boothTitle = computed(() =>
  getLocalizedAttribute({
    de: props.booth.titleDe,
    en: props.booth.titleEn,
    fallback: '',
    locale: i18n.locale.value,
  }),
)

const boothDisplayName = computed(() => {
  if (props.booth.typeDisplayName == 'Branded Room') return 'Exclusive Room'
  return props.booth.typeDisplayName
})

const label = computed(() => {
  if (props.booth?.partnerCompany?.name) {
    return props.booth?.partnerCompany.displayName || props.booth?.partnerCompany.name
  } else if (boothTitle.value) {
    return boothTitle.value
  } else if (props.booth.boothNumber && props.booth.boothNumber.length > 0) {
    return props.booth.boothNumber
  } else {
    return boothId.value
  }
})

const boothId = computed(() => {
  const boothId = toTypeAndId(props.booth.id)['id']
  if (boothId) {
    return boothId
  } else {
    return ''
  }
})
</script>
