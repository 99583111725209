<template>
  <div>
    <div class="pt-2 pb-5">
      <h1>{{ t('Speakers.editSpeaker') }}</h1>

      <BackLink class="mt-4" :to="{ name: 'speakerList' }">{{ t('backToOverview') }}</BackLink>
    </div>

    <SpeakerEventsForm :speaker="props.speaker" :is-uploading="loading" @submit-form="save">
      <template #navHeader>
        <TabBar>
          <NavTabBarItem :is-selected="route.name === 'editSpeaker'" :to="{ name: 'editSpeaker' }">
            {{ t('Speaker.editSpeaker') }}
          </NavTabBarItem>
          <NavTabBarItem :is-selected="route.name === 'editSpeakerEvents'" :to="{ name: 'editSpeakerEvents' }">
            {{ t('Speaker.editSpeakerEvents') }}
          </NavTabBarItem>
        </TabBar>
        <ProgressBar v-if="loading" color="primary" />
      </template>
    </SpeakerEventsForm>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import type { Speaker } from '@/gql/myomr'
import { useCreateEventSpeakerMutation, useDeleteEventSpeakerMutation } from '@/gql/myomr'
import { showNotification } from '@/helpers/notificationHelper'
import BackLink from '@/ui/BackLink.vue'

import getSpeakerConfig from './getSpeakerConfig'
import SpeakerEventsForm from './SpeakerEventsForm.vue'

const { t } = useI18n()
const router = useRouter()
const route = useRoute()

const props = defineProps<{
  speaker: Speaker
}>()
const emit = defineEmits(['refetch'])

onMounted(() => {
  if (!getSpeakerConfig['enableEventAssigning']) router.push({ name: 'speakerList' })
})

const { mutate: createEventSpeaker, loading: createEventSpeakerLoading } = useCreateEventSpeakerMutation()
const { mutate: deleteEventSpeaker, loading: deleteEventSpeakerLoading } = useDeleteEventSpeakerMutation()
const loading = computed(() => createEventSpeakerLoading.value || deleteEventSpeakerLoading.value)

async function save(eventSpeakerData: { eventsToAdd: Array<string>; eventSpeakersToRemove: Array<string> }) {
  let hasErrors = false
  try {
    await Promise.all(
      eventSpeakerData.eventsToAdd.map((eventId) =>
        createEventSpeaker({
          eventId: eventId,
          speakerId: props.speaker.id,
        }),
      ),
    )
  } catch (_err) {
    hasErrors = true
    showNotification(t('Speakers.notifications.eventsIssue'), 'error')
  }

  try {
    await Promise.all(
      eventSpeakerData.eventSpeakersToRemove.map((eventSpeakerId) =>
        deleteEventSpeaker({
          eventSpeakerId,
        }),
      ),
    )
  } catch (_err) {
    hasErrors = true
    showNotification(t('Speakers.notifications.eventsRemovalIssue'), 'error')
  }

  if (!hasErrors) showNotification(t('Speaker.successfullySaved'), 'success')
  window.scrollTo(0, 0)
  emit('refetch')
  router.push({ name: 'speakerList' })
}
</script>
