<template>
  <svg width="30" height="33" viewBox="0 0 30 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14" cy="19" r="13.5" fill="#FEFBE8" stroke="#FDE88B" />
    <path
      d="M7.3335 25.6666V13.6666C7.3335 13.3 7.46405 12.9861 7.72516 12.725C7.98627 12.4639 8.30016 12.3333 8.66683 12.3333H19.3335C19.7002 12.3333 20.0141 12.4639 20.2752 12.725C20.5363 12.9861 20.6668 13.3 20.6668 13.6666V21.6666C20.6668 22.0333 20.5363 22.3472 20.2752 22.6083C20.0141 22.8694 19.7002 23 19.3335 23H10.0002L7.3335 25.6666ZM9.4335 21.6666H19.3335V13.6666H8.66683V22.4166L9.4335 21.6666Z"
      fill="#F5C41C"
    />
    <path
      d="M24.5 6.5H23.5V3.5H24.5V6.5ZM24 8.65C23.8276 8.65 23.6623 8.58152 23.5404 8.45962C23.4185 8.33772 23.35 8.17239 23.35 8C23.35 7.82761 23.4185 7.66228 23.5404 7.54038C23.6623 7.41848 23.8276 7.35 24 7.35C24.1724 7.35 24.3377 7.41848 24.4596 7.54038C24.5815 7.66228 24.65 7.82761 24.65 8C24.65 8.17239 24.5815 8.33772 24.4596 8.45962C24.3377 8.58152 24.1724 8.65 24 8.65ZM25.865 1.5H22.135L19.5 4.135V7.865L22.135 10.5H25.865L28.5 7.865V4.135L25.865 1.5Z"
      fill="#DD9407"
    />
  </svg>
</template>
