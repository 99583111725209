<template>
  <div>
    <AlertBox v-if="$cannot('manageUsers', { for: 'User' })" type="warning" class="mt-5">
      {{ t('PartnerCompany.noPartnerCompany') }}
    </AlertBox>
    <template v-else>
      <h1>{{ t('UserManagement.userManagementTitle') }}</h1>
      <p class="my-4 text-body-m">{{ t('UserManagement.userManagementDesc') }}</p>

      <RouterView v-if="result" :users="filteredUsers" :loading="loading" @reload-users="refetch" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useUsersQuery, type MeQuery, RoleNameEnum } from '@/gql/myomr'
import { useRoleRedirect } from '@/services/roleRedirect'
import { $cannot } from '@/services/roles'
import { useMeStore } from '@/stores'

const { t } = useI18n()

useRoleRedirect('manageUsers')

const { result, loading, refetch } = useUsersQuery()

defineProps<{
  me: MeQuery['me']
}>()

const filteredUsers = computed(() => {
  const meStore = useMeStore()
  const partnerCompanyId = meStore.currentPartnerCompanyId

  return result?.value?.users?.nodes?.filter((user) => {
    return user.roles.some((role) => role.resourceId == partnerCompanyId && role.name !== RoleNameEnum.LeadScanner)
  })
})
</script>
