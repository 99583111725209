import { setContext } from '@apollo/client/link/context'

import { useMeStore } from '@/stores'

const partnerCompanyLink = setContext((_, { headers }) => {
  const meStore = useMeStore()
  const getId = () => {
    const id = meStore.currentPartnerCompanyId
    return id ?? ''
  }

  return {
    headers: {
      ...headers,
      'Partner-Company': getId(),
    },
  }
})

export default partnerCompanyLink
