<template>
  <div>
    <h1 class="text-headline-l">{{ t('Reviews.toolOverview.title') }}</h1>
    <p class="my-4 text-headline-m mb-8">{{ t('Reviews.toolOverview.subtitle', { companyName: me.partnerCompany?.displayName }) }}</p>
    <div class="grid grid-cols-3 gap-6">
      <ProductCard
        v-for="product in props.products"
        :key="product.id"
        :product="mapProduct(product)"
        :data-testid="'product-card-' + product.id"
        @click="openProduct(product.id)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProductCard, type ProductCardTypes } from '@ramp106/omrjs-reviews-ui'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import type { MeQuery } from '@/gql/myomr'
import type { ProductBasicDataFragment } from '@/gql/reviews'
import { ReviewsRouteName } from '@/router/types'

const { t } = useI18n()
const router = useRouter()

const props = defineProps<{
  me: NonNullable<MeQuery['me']>
  products: ProductBasicDataFragment[]
  loading: boolean
}>()

const emit = defineEmits(['reloadProducts'])

function mapProduct(product: ProductBasicDataFragment): ProductCardTypes.Product {
  return {
    name: product.title,
    isSuite: product.isSuite,
    link: undefined,
    logoUrl: product.logo?.url,
    reviewCount: product.reviewCount,
    rating: product.averageRecommendationRating ?? undefined,
    badges: product.productBadges.map((badge) => ({
      name: badge.badgeName,
      fullName: badge.badgeName,
      timePeriodLabel: badge.timeframe ?? '',
    })),
  }
}

onMounted(() => emit('reloadProducts'))

function openProduct(productId: string) {
  router.push({ name: ReviewsRouteName.ReviewsProductView, params: { productId } })
}
</script>
