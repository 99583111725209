import type { LocationQueryValue } from 'vue-router'

import type {
  RecommendationRatingKeyEnum,
  ReviewsProductSurveyResponseDetailsQuery,
  ReviewsProductSurveyResponsesQuery,
  SurveyResponsesStateEnum,
} from '@/gql/reviews'

export type SurveyResponse = NonNullable<NonNullable<ReviewsProductSurveyResponsesQuery['manage']>['surveyResponses']>['results'][number]
export type SurveyResponseDetails = NonNullable<NonNullable<ReviewsProductSurveyResponseDetailsQuery['manage']>['surveyResponse']>
export type SurveyResponseReminder = SurveyResponseDetails['reminders'][number]

export type SurveyResponseReviewReply = NonNullable<SurveyResponseDetails['review']>['reviewReply']

export enum ReviewInteractionType {
  NONE = 'none',
  REMINDER = 'reminder',
  REMINDER_CHECK = 'reminderCheck',
  REPLY = 'reply',
  REPLY_CHECK = 'replyCheck',
  REPLY_OUTDATED = 'replyOutdated',
}

export enum TimeFrameOptionsEnum {
  NONE = '0',
  LAST_30_DAYS = '1',
  LAST_3_MONTH = '2',
  LAST_6_MONTH = '3',
  LAST_12_MONTH = '4',
  OLDER_THAN_12_MONTH = '5',
}

export interface ReviewsSortAndFilter {
  timeframe: LocationQueryValue | null
  ratings: RecommendationRatingKeyEnum[] | undefined
  states: SurveyResponsesStateEnum[] | undefined
  interaction: LocationQueryValue | null
}
