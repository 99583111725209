import { isNotNull } from '@ramp106/omrjs-common'

export const getQueryStringArrayValue = (value?: string | null | (string | null)[]): string[] => {
  if (typeof value === 'string') {
    return value.includes(',') ? value.split(',') : [value]
  }
  if (Array.isArray(value)) {
    return value.filter(isNotNull)
  }
  return []
}

export const getQueryStringValue = (value?: string | null | (string | null)[]): string | null => {
  if (typeof value === 'string') {
    return value.includes(',') ? value.split(',')[0] || null : value
  }
  if (Array.isArray(value)) {
    return value.filter(isNotNull)[0] || null
  }
  return null
}
