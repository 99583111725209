<template>
  <div :style="{ background: '#EFF1F3' }">
    <template v-if="me">
      <SideBar :me="me" />

      <div class="block flex-auto flex-grow flex-shrink laptop:pl-64">
        <div class="mt-24 laptop:mt-8 mb-16 mx-auto px-10 max-w-6xl">
          <UiNotifications />

          <AccountSwitchModal v-if="me && showAccountSwitcher" :me="me" />

          <RouterView :me="me" />
        </div>
      </div>
    </template>
    <template v-else>
      <RouterView />
    </template>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'

import { UiNotifications, SideBar } from '@/components'
import { useUiStore, useMeStore } from '@/stores'

import AccountSwitchModal from './components/AccountSwitchModal.vue'

const { me } = storeToRefs(useMeStore())

const uiStore = useUiStore()
const { showAccountSwitcher } = storeToRefs(uiStore)
</script>

<style>
html,
body {
  overflow-x: auto !important;
  min-width: 960px !important;
}
</style>
