<template>
  <ConfirmModal persist hide-actions @close-dialog="closeModal">
    <template #title>
      <h1 class="text-headline-m p-6 pb-5">{{ typed_t('ticketForm.createTicket') }}</h1>
    </template>
    <template #content>
      <TicketForm
        :invitation-quota="props.invitationQuota"
        :error-message="errorMessage"
        @close-dialog="closeModal"
        @submit-form="submitForm"
      />
    </template>
  </ConfirmModal>
</template>

<script setup lang="ts">
import { captureException } from '@sentry/vue'
import type { Required } from 'utility-types'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import {
  useCreateTicketMutation,
  CompanySizeEnum,
  CompanyDepartmentEnum,
  type InvitationQuotaQuery,
  type DirectTicketAttributes,
} from '@/gql/myomr'
import { injectStrict } from '@/helpers/injectStrict'
import { showNotification } from '@/helpers/notificationHelper'
import typed_translation from '@/helpers/typed_translation'
import { reloadQuotaKey } from '@/symbols/reloadQuotaKey'
import { reloadTicketsKey } from '@/symbols/reloadTicketsKey'

import useQuotaConfig, { type InternalQuotaTypeEnum } from './quotaConfig'

const props = defineProps<{
  invitationQuota: InvitationQuotaQuery['invitationQuota']
  isLimitReached: boolean
}>()

const { mutate: createTicket } = useCreateTicketMutation()

const reloadQuota = injectStrict(reloadQuotaKey)
const reloadTickets = injectStrict(reloadTicketsKey)

const { t } = useI18n()
const typed_t = typed_translation('InvitationQuota', props.invitationQuota.kind)
const route = useRoute()
const router = useRouter()

const errorMessage = ref('')

type FormData = Omit<Required<DirectTicketAttributes>, 'companyFieldId' | 'companyIndustryId' | 'companySize' | 'companyDepartment'> & {
  companyDepartment: undefined | CompanyDepartmentEnum
  companyFieldId: undefined | number
  companyIndustryId: undefined | number
  companySize: undefined | CompanySizeEnum
}

const quotaConfig = useQuotaConfig(props.invitationQuota.kind as InternalQuotaTypeEnum)

const isDeadlineReached = computed(() => {
  if (!props.invitationQuota.expirationDate) return false
  return new Date(props.invitationQuota.expirationDate).getTime() < new Date().getTime()
})

function closeModal() {
  const itemsDisplayed = route.query.first || route.query.last || undefined
  router.push({
    name: 'invitationQuota',
    query: {
      ...route.query,
      first: itemsDisplayed,
      last: undefined,
      after: undefined,
      before: undefined,
    },
  })
}

const submitForm = async function (formData: FormData) {
  createTicket({
    invitationQuotaId: props.invitationQuota.id,
    ticketCreateAttributes: formData,
  })
    .then((response) => {
      if (response?.data?.createDirectTicket?.errors?.length) {
        errorMessage.value = response.data.createDirectTicket.errors.join(', ')
        showNotification(t('InvitationQuota.CrewQuota.unsuccessfulUpdateNotification'), 'error', 3000)
      } else {
        errorMessage.value = ''
        showNotification(t('InvitationQuota.CrewQuota.successfulTicketCreate'), 'success', 3000)
        reloadQuota()
        reloadTickets()
        closeModal()
      }
    })
    .catch((e) => {
      captureException(e)
      showNotification(t('InvitationQuota.CrewQuota.unsuccessfulUpdateNotification'), 'error', 3000)
    })
}

onMounted(() => {
  if (props.isLimitReached || (isDeadlineReached.value && quotaConfig.value.respectDeadline)) {
    closeModal()
  }
})
</script>
