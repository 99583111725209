<template>
  <div class="w-full flex bg-white items-center rounded-[3px] border border-grey-700 relative">
    <SvgIcon :path="mdiMagnify" :size="25" class="ml-3 text-grey-800" />
    <input
      v-model="speakerSearch"
      class="w-full bg-white px-1 py-2 focus:outline-none"
      type="text"
      :placeholder="t('Speakers.search.title')"
      @input="searchSpeakers"
    />
    <SvgIcon
      v-if="speakerSearch.length > 2"
      :path="mdiClose"
      class="text-grey-800 pr-2 cursor-pointer"
      :size="22"
      @click="speakerSearch = ''"
    />
    <div v-if="speakerSearch.length > 2" class="absolute w-full bg-white top-11 shadow-lg">
      <div v-if="speakers.length" class="h-auto">
        <RouterLink
          v-for="speaker in speakers"
          :key="speaker.id"
          :to="{ name: 'viewSpeaker', params: { speakerId: toTypeAndId(speaker.id)['id'] || '' } }"
          class="flex align-middle py-2 px-4 cursor-pointer hover:bg-grey-200"
        >
          <img width="40" height="40" :src="speaker.photoUrl || ''" />
          <div class="ml-4">
            <p class="text-body-s">{{ speaker.name }}</p>
            <p class="text-label-s text-grey-800">
              <span v-if="speaker.title">{{ speaker.title }} · </span>{{ speaker.partnerCompanyName }}
            </p>
          </div>
        </RouterLink>
      </div>
      <div v-if="speakers.length === 0 && !loading" class="my-4 ml-4 text-grey-700">
        <p>{{ t('Speakers.search.noSpeakersFound') }}</p>
      </div>
      <template v-if="loading">
        <ProgressBar class="mb-4" />
        <div class="mb-4 ml-4 text-grey-700">
          <p>{{ t('Speakers.search.loading') }}</p>
        </div>
      </template>
      <RouterLink
        :to="{ name: 'createSpeaker' }"
        class="px-4 border-t border-t-grey-300 flex items-center text-body-s py-4 text-victoria cursor-pointer hover:bg-grey-200"
      >
        <SvgIcon :path="mdiPlusCircleOutline" :size="22" />
        <p class="ml-1">
          {{ t('Speakers.search.createNewProfile') }} "<span class="font-semibold">{{ speakerSearch }}</span
          >"
        </p>
      </RouterLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { mdiMagnify, mdiPlusCircleOutline, mdiClose } from '@mdi/js'
import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router'

import { useSpeakersLazyQuery } from '@/gql/myomr'
import { toTypeAndId } from '@/helpers/globalIdHelper'

const { t } = useI18n()

const SEARCH_RESULT_LIMIT = 10

const { load, refetch, result, loading } = useSpeakersLazyQuery()

const speakers = computed(() => result.value?.speakers.nodes || [])

const speakerSearch = ref('')

let debounce: ReturnType<typeof setTimeout>

watch(speakerSearch, async (newValue) => {
  if (newValue.length >= 3) {
    const vars = { searchTerm: newValue, first: SEARCH_RESULT_LIMIT, forCurrentPartnerCompany: false }

    if (!load(null, vars)) {
      refetch(vars)
    }
  }
})

function searchSpeakers(input: Event) {
  clearTimeout(debounce)
  debounce = setTimeout(() => {
    speakerSearch.value = (input.target as HTMLInputElement).value
  }, 600)
}
</script>
