<template>
  <div v-if="prospects?.prospects">
    <div class="mt-4 flex gap-2 items-start">
      <ComboSearch
        :model-value="filters.companyNames.value"
        class="basis-0 grow shrink-0"
        :placeholder="t('ProspectsCatalog.companyNameSearch')"
        @update:model-value="updateFilters('companyNames', $event)"
      />
      <ComboSearch
        :model-value="filters.positions.value"
        class="basis-0 grow shrink-0"
        :placeholder="t('ProspectsCatalog.positionSearch')"
        @update:model-value="updateFilters('positions', $event)"
      />
    </div>

    <div class="mt-4 flex gap-2 flex-wrap">
      <MultiSelect
        v-if="facetItems.visitedEventsItems.length"
        :model-value="filters.visitedEvents.value"
        :label="t('ProspectsCatalog.visitedEvents')"
        :options="facetItems.visitedEventsItems"
        @update:model-value="updateFilters('visitedEvents', $event)"
      />
      <MultiSelect
        v-if="facetItems.companyJobLevelItems.length"
        :model-value="filters.companyJobLevels.value"
        :label="t('ProspectsCatalog.companyJobLevel')"
        :options="facetItems.companyJobLevelItems"
        @update:model-value="updateFilters('companyJobLevels', $event)"
      />
      <MultiSelect
        v-if="facetItems.companySizeItems.length"
        :model-value="filters.companySizes.value"
        :label="t('ProspectsCatalog.companySize')"
        :options="facetItems.companySizeItems"
        @update:model-value="updateFilters('companySizes', $event)"
      />
      <MultiSelect
        v-if="facetItems.companyIndustryItems.length"
        :model-value="filters.companyIndustries.value"
        :label="t('ProspectsCatalog.companyIndustry')"
        :options="facetItems.companyIndustryItems"
        @update:model-value="updateFilters('companyIndustries', $event)"
      />
      <MultiSelect
        v-if="facetItems.companyFieldItems.length"
        :model-value="filters.companyFields.value"
        :label="t('ProspectsCatalog.companyField')"
        :options="facetItems.companyFieldItems"
        @update:model-value="updateFilters('companyFields', $event)"
      />
      <MultiSelect
        v-if="facetItems.companyDepartmentItems.length"
        :model-value="filters.companyDepartments.value"
        :label="t('ProspectsCatalog.companyDepartment')"
        :options="facetItems.companyDepartmentItems"
        @update:model-value="updateFilters('companyDepartments', $event)"
      />
      <MultiSelect
        v-if="filters.available"
        :model-value="filters.available.value"
        :label="t('ProspectsCatalog.availabilityFilter.title')"
        :options="availableFilterOptions"
        @update:model-value="updateFilters('available', $event)"
      />
      <MultiSelect
        v-if="facetItems.companyCountryItems.length"
        :model-value="filters.companyCountries.value"
        :label="t('ProspectsCatalog.companyCountry')"
        :options="facetItems.companyCountryItems"
        @update:model-value="updateFilters('companyCountries', $event)"
      />
      <button @click.stop="emit('resetFilters')">
        {{ t('ProspectsCatalog.resetFilters') }}
      </button>
    </div>

    <MessageBanner
      v-if="leadAddConfig.visible"
      class="my-4"
      :headline="leadAddConfig.title"
      :message="leadAddConfig.copy"
      :type="leadAddConfig.type"
    />

    <TableLight v-if="prospects.prospectsCount.total" class="mt-6 mb-6 w-full">
      <thead>
        <tr>
          <th class="text-start">{{ t('ProspectsCatalog.companyName') }}</th>
          <th class="text-start">{{ t('ProspectsCatalog.position') }}</th>
          <th class="text-start">{{ t('ProspectsCatalog.visitedEvents') }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ProspectRow
          v-for="prospect in prospects.prospects"
          :key="prospect.id"
          :facet-items="facetItems"
          :prospect-list-id="getProspectListId(prospect.id)"
          :prospect-lists="props.prospectLists"
          :prospect="prospect"
          :disabled="leadAddConfig.addDisabled"
          @create-prospect-item="emit('createProspectItem', $event)"
          @delete-prospect-item="emit('deleteProspectItem', $event)"
        >
          <button v-if="getProspectListId(prospect.id)" class="h-6 cursor-default" :title="`${t('ProspectsCatalog.prospectAlreadyAdded')}`">
            <SvgIcon :path="mdiCheck" :size="20" />
          </button>

          <DropDownSelect v-else-if="prospect.available && !leadAddConfig.addDisabled" inner-class="min-w-[150px]" direction="right">
            <template #activator="{ open }">
              <button class="h-6" @click.stop="open">
                <SvgIcon :path="mdiPlaylistPlus" :size="20" />
              </button>
            </template>

            <template #default="{ close }">
              <ul class="list-none p-0 m-0">
                <template v-if="prospectLists.length">
                  <li v-for="list in props.prospectLists" :key="`list_${list.id}`" class="separate-rows">
                    <label class="block" :class="isListChangeable(list) ? 'cursor-pointer' : 'text-grey-500'">
                      <div
                        class="hover:bg-purple-100 peer-checked:bg-purple-100 py-2 px-3"
                        @click.stop="isListChangeable(list) && addProspectToList(prospect.id, list.id, close)"
                      >
                        {{ list.title }}
                      </div>
                    </label>
                  </li>
                </template>
                <template v-else>
                  <li class="separate-rows">
                    <label class="block cursor-pointer">
                      <div class="hover:bg-purple-100 peer-checked:bg-purple-100 py-2 px-3">
                        {{ $t('ProspectsCatalog.addToListNoListFound') }}
                      </div>
                    </label>
                  </li>
                </template>
              </ul>
            </template>
          </DropDownSelect>

          <button
            v-else-if="!leadAddConfig.addDisabled"
            v-tooltip="t('ProspectsCatalog.unavailableTooltip')"
            disabled
            class="h-6 text-grey-500"
          >
            <SvgIcon :path="mdiPlaylistPlus" :size="20" />
          </button>
        </ProspectRow>
      </tbody>
    </TableLight>

    <div v-else class="text-center py-10">
      {{ t('ProspectsCatalog.noResults') }}
    </div>

    <div class="mx-auto mt-6 mb-4">
      <PaginationControl
        :page="props.page"
        :per-page="props.perPage"
        :total="prospects.prospectsCount.total"
        :show-range="false"
        :show-total="false"
        @update:page="emit('update:page', $event)"
        @update:per-page="emit('update:perPage', $event)"
      />
    </div>
    <div class="mx-auto mt-4 mb-4">
      {{ paginationHint }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { mdiCheck, mdiPlaylistPlus } from '@mdi/js'
import { MessageBanner } from '@ramp106/omrjs-core-ui'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import type { ProspectFacetItems } from '@/components/Prospects/prospectFacetItems'
import type { ProspectsQuery } from '@/gql/myomr'
import { useMeStore } from '@/stores/me'
import { TableLight } from '@/ui/TableLight'

import { isListChangeable } from './helpers'
import ProspectRow from './ProspectRow.vue'
import type { Filters, ProspectSearchItemWithListId, ProspectList } from './types'

const { t } = useI18n()
const { leadGenLimit } = useMeStore()
const route = useRoute()

const props = defineProps<{
  facetItems: ProspectFacetItems
  filters: Filters
  page: number
  perPage: number
  prospectListItems: ProspectSearchItemWithListId[]
  prospectLists: ProspectList[]
  prospects: ProspectsQuery['prospects']
}>()

const emit = defineEmits<{
  (e: 'createProspectItem', value: { prospectId: string; prospectListId: string }): void
  (e: 'deleteProspectItem', value: { prospectId: string; prospectListId: string }): void
  (e: 'resetFilters'): void
  (e: 'updateFilters', value: { filterName: string; values: string[] }): void
  (e: 'update:page', value: number): void
  (e: 'update:perPage', value: number): void
}>()

const availableFilterOptions = [
  { text: t('ProspectsCatalog.availabilityFilter.available'), value: 'available' },
  { text: t('ProspectsCatalog.availabilityFilter.unavailable'), value: 'unavailable' },
]

function updateFilters(filterName: string, values: string[]) {
  emit('updateFilters', { filterName: filterName, values: values })
}

const paginationHint = computed(() => {
  return props.prospects?.prospectsCount.relation === 'gte' ? t('ProspectsCatalog.paginationHint') : ''
})

const maxLeadNumber = computed(() => {
  return leadGenLimit(route.params.slug as string)
})

const currLeadNumber = computed(() => {
  return props.prospectLists.map((pl) => pl.prospects.length).reduce((sum, l) => sum + l, 0)
})

const enrichLeadMessage = (msg: string) => {
  return maxLeadNumber.value
    ? msg
        .replace('<<remainingleads>>', Math.max(maxLeadNumber.value - currLeadNumber.value, 0).toString())
        .replace('<<maxleads>>', maxLeadNumber.value.toString())
    : ''
}

const leadAddConfig = computed(() => {
  const type = (maxLeadNumber.value && currLeadNumber.value >= maxLeadNumber.value ? 'error' : 'success') as
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
  const addDisabled = !!maxLeadNumber.value && type == 'error'
  return {
    addDisabled,
    visible: !!maxLeadNumber.value,
    title: !addDisabled
      ? enrichLeadMessage(t('ProspectsCatalog.leadRemainingTitle'))
      : enrichLeadMessage(t('ProspectsCatalog.noMoreLeadsTitle')),
    copy: !addDisabled ? '' : enrichLeadMessage(t('ProspectsCatalog.noMoreLeadsMessage')),
    type,
  }
})

function addProspectToList(prospectId: string, prospectListId: string, callback: () => void) {
  emit('createProspectItem', { prospectId: prospectId, prospectListId: prospectListId })
  callback()
}

function getProspectListId(prospectId: string) {
  return props.prospectListItems.find((item) => item.id === prospectId)?.prospectListId
}
</script>
