<template>
  <div>
    <FileInput
      :accept="props.acceptedFileTypes?.join(',') || undefined"
      :label="t('Reviews.product.edit.documents.file.label')"
      :helper-text="fileHint"
      :file-name="formData.filename"
      :error="getValidationErrors(v$.file)"
      @input="onFilePicked"
    />
    <FileInput
      :accept="props.acceptedThumbnailTypes?.join(',') || undefined"
      deletable
      :label="t('Reviews.product.edit.documents.thumbnail.label')"
      :helper-text="thumbnailHint"
      :file-name="formData.previewFile?.name || formData.previewFilename"
      :error="getValidationErrors(v$.previewFile)"
      data-testid="thumbnail-upload-input"
      @input="onThumbnailPicked"
    />
    <TextInput
      v-for="lang in ALLOWED_LOCALES"
      :key="lang"
      v-model="v$.name[lang].$model"
      :max-length="NAME_MAX_LENGTH"
      :error="getValidationErrors(v$.name[lang])"
      :data-testid="`document-title-${lang}`"
      @change="onNameChange"
    >
      <template #label>
        <div class="flex justify-between items-end">
          <span>{{ t('Reviews.product.edit.documents.name.label') }}</span>
          <ChipBadge class="uppercase" rounded>{{ lang }}</ChipBadge>
        </div>
      </template>
    </TextInput>
  </div>
</template>

<script setup lang="ts">
import { FileInput, TextInput, ChipBadge } from '@ramp106/omrjs-core-ui'
import { useVuelidate, type ValidationArgs } from '@vuelidate/core'
import { computed, watch, reactive, onMounted, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'

import type { ReviewsProductDocumentEditData } from '@/components/ReviewsManagement/types'
import { ALLOWED_LOCALES } from '@/helpers/translationsHelper'
import { buildTranslationFieldsRules, fileMaxSizeRule, fileMimeTypeRule, getValidationErrors } from '@/helpers/validationHelper'
import { maxLength, minLength } from '@/services/validators'

const NAME_MIN_LENGTH = 3
const NAME_MAX_LENGTH = 80
const MAX_FILE_MB_SIZE = 10
const MAX_THUMBNAIL_FILE_MB_SIZE = 10

const props = defineProps<{
  value: ReviewsProductDocumentEditData
  index: number
  acceptedFileTypes: string[]
  acceptedThumbnailTypes?: string[]
}>()
const emit = defineEmits<{
  (e: 'input', value: ReviewsProductDocumentEditData): void
}>()

const { t } = useI18n()

const fileHint = computed(() => {
  const acceptedMimeTypes = props.acceptedFileTypes?.map((type) => `${type.split('/')[1]}`).join(', ')
  return `${acceptedMimeTypes} ・ Max. ${MAX_FILE_MB_SIZE} MB`
})

const thumbnailHint = computed(() => {
  const acceptedMimeTypes = props.acceptedThumbnailTypes?.map((type) => `${type.split('/')[1]}`).join(', ')
  return `${acceptedMimeTypes} ・ Max. ${MAX_THUMBNAIL_FILE_MB_SIZE} MB`
})

const rules = computed<ValidationArgs<Pick<ReviewsProductDocumentEditData, 'name' | 'file' | 'previewFile'>>>(() => ({
  name: buildTranslationFieldsRules({
    minLength: minLength(NAME_MIN_LENGTH),
    maxLength: maxLength(NAME_MAX_LENGTH),
  }),
  file: {
    fileSize: fileMaxSizeRule(t, { megaBytes: MAX_FILE_MB_SIZE }),
    fileType: fileMimeTypeRule(t, { mimeTypes: props.acceptedFileTypes || [] }),
  },
  previewFile: {
    fileSize: fileMaxSizeRule(t, { megaBytes: MAX_FILE_MB_SIZE }),
    fileType: fileMimeTypeRule(t, { mimeTypes: props.acceptedThumbnailTypes || [] }),
  },
}))

const formData = reactive<ReviewsProductDocumentEditData>({
  ...props.value,
})

const v$ = useVuelidate(rules, formData, { $registerAs: `document-${props.index}` })

function onNameChange() {
  formData.updated = true
}

function onFilePicked(file: File | null) {
  if (!file) {
    return
  }
  formData.file = file
  formData.size = file.size
  formData.contentType = file.type
  formData.filename = file.name
  formData.updated = true
  v$.value.file.$touch()
}

function onThumbnailPicked(file: File | null) {
  if (file) {
    formData.previewFile = file
    formData.updated = true
    formData.previewDeleted = false
  } else {
    formData.previewFile = null
    formData.previewDeleted = true
    formData.updated = true
  }
  v$.value.previewFile.$touch()
}

onMounted(() => {
  nextTick(() => {
    v$.value.file.$touch()
    ALLOWED_LOCALES.forEach((lang) => v$.value.name[lang].$touch())
  })
})

watch(
  () => formData,
  () => {
    emit('input', formData)
  },
  { deep: true },
)
</script>
