<template>
  <div>
    <label
      class="cursor-pointer flex w-full mt-2 relative"
      :class="{
        'text-amaranth-800 form-input-error': error,
        'text-grey-900': isHighlighted && !error,
        '-top-2 left-2 bg-white px-1 text-xs': isHighlighted,
        'left-5 top-4': !isHighlighted,
      }"
    >
      <SvgIcon v-if="props.icon" :path="props.icon" :size="25" class="opacity-60" />
      <span class="w-full text-body-m ml-2 pb-1 border-b border-black relative">
        <p class="absolute" :class="{ '-top-3 opacity-60 text-label-s': props.modelValue }">{{ props.label || 'Upload file' }}</p>
        <p v-if="props.modelValue" class="text-body-m">{{ props.modelValue.file.name }}</p>
      </span>
      <input
        ref="fileInput"
        class="hidden"
        type="file"
        :accept="props.accept"
        :test-data-error="error || null"
        @focus="focus"
        @blur="blur"
        @change="handleInputChange"
      />
      <div v-if="uploading" class="mt-3">
        <CircleProgress :progress="uploadProgress" size="40" />
      </div>
    </label>
    <p v-if="props.hint" :class="{ 'ml-8': props.icon, 'text-amaranth-800': error }" class="text-label-s mt-2 opacity-60">{{ hint }}</p>
  </div>
</template>

<script setup lang="ts">
import { FileChecksum } from '@rails/activestorage/src/file_checksum'
import { CircleProgress } from '@ramp106/omrjs-core-ui'
import { computed, ref } from 'vue'

import { useCreateDirectUploadMutation } from '@/gql/myomr'
import type { DirectUploadFile } from '@/types'

const props = defineProps<{
  label?: string
  icon?: string
  modelValue: DirectUploadFile
  accept?: string
  hint?: string
  error?: boolean
}>()

const fileInput = ref()
const uploading = ref(false)
const uploadProgress = ref(0)

const emit = defineEmits<{
  (e: 'update:modelValue', value: DirectUploadFile): void
  (e: 'blur'): void
  (e: 'focus'): void
}>()

const isHighlighted = computed(() => {
  return isInputFocused.value || fileInput.value !== ''
})

const isInputFocused = ref(false)

function blur() {
  emit('blur')
  isInputFocused.value = false
}

function focus() {
  isInputFocused.value = true
  emit('focus')
}

const { mutate: createDirectUpload } = useCreateDirectUploadMutation({})

function calculateChecksum(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    FileChecksum.create(file, (error, checksum) => {
      if (error) {
        reject(error)
        return
      }

      resolve(checksum)
    })
  })
}

const handleInputChange = async () => {
  if (!fileInput.value?.files || !fileInput.value.files[0]) {
    return
  }

  const file = fileInput.value.files[0]
  // emit('update:modelValue', file)

  try {
    uploading.value = true
    uploadProgress.value = 0

    const checksum = await calculateChecksum(file)

    const response = await createDirectUpload({
      byteSize: file.size,
      checksum,
      filename: file.name,
      contentType: file.type || 'application/octet-stream',
    })

    if (!response?.data?.createDirectUpload?.directUpload) {
      throw new Error('Failed to create direct upload')
    }
    const { url, signedBlobId: blobId } = response.data.createDirectUpload.directUpload

    await uploadFileToURL(file, url)

    emit('update:modelValue', { file: file, blobId: blobId })

    // console.log('File uploaded. signedBlobId:', blobId)
  } catch (error) {
    console.error('Error uploading file:', error)
  } finally {
    uploading.value = false
  }
}

const uploadFileToURL = (file: File, url: string): Promise<void> => {
  const parsedUrl = new URL(url)
  parsedUrl.protocol = 'https:' // Ensure we're using HTTPS

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('PUT', parsedUrl.toString())

    xhr.setRequestHeader('Content-Type', file.type || 'application/octet-stream')
    // xhr.setRequestHeader('Content-MD5', checksum)

    xhr.upload.addEventListener('progress', (evt) => {
      if (evt.lengthComputable) {
        uploadProgress.value = Math.round((evt.loaded / evt.total) * 100)
      }
    })

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve()
      } else {
        reject(new Error(`Upload failed with status ${xhr.status}`))
      }
    }
    xhr.onerror = () => reject(new Error('XHR error during file upload'))

    xhr.send(file)
  })
}
</script>
