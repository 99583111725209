import { ALLOWED_LOCALES } from '@/helpers/translationsHelper'

import type { ReviewsProductDocumentEditData } from '../types'

export const splitDocumentsForMutations = (documents: ReviewsProductDocumentEditData[]) => {
  const documentsToCreate = documents
    .filter((document) => document.id === null && !document.deleted)
    .map((document) => ({
      position: document.position,
      nameTranslations: ALLOWED_LOCALES.map((lang) => ({ locale: lang, value: document.name[lang] })),
      file: document.file,
      preview: document.previewFile || undefined,
    }))

  const documentsToUpdate = documents
    .filter((document) => document.id !== null && document.updated && !document.deleted)
    .map((document) => ({
      id: document.id!,
      nameTranslations: ALLOWED_LOCALES.map((lang) => ({ locale: lang, value: document.name[lang] })),
      position: document.position,
      file: document.file || undefined,
      preview: document.previewFile || undefined,
      deleteCustomPreview: document.previewDeleted,
    }))

  const documentsToDelete = documents.filter((document) => document.id !== null && document.deleted).map((document) => document.id!)

  return {
    documentsToCreate,
    documentsToUpdate,
    documentsToDelete,
  }
}
