import type { ValuesType } from 'utility-types'

import type { InvitationQuota } from '@/gql/myomr'
import { shopUrl } from '@/helpers/externalLinksHelper'

const headerFields = ['nr', 'code', 'event', 'category', 'ticketshop link', 'used?', 'user name', 'company name', 'discount']

const arrayToCsvRow = (items: (string | number | boolean | undefined | null)[]) => {
  return encodeURIComponent(items.join(';') + '\r\n')
}

const headerRow = arrayToCsvRow(headerFields)

type PromoCode = ValuesType<NonNullable<InvitationQuota['promoCodes']>>

const formatAsCSV = (invitationQuota: InvitationQuota) => {
  return invitationQuota.promoCodes?.reduce((csvString: string, current: PromoCode, index: number) => {
    return (csvString += formatSingleCodeAsCSV(current, invitationQuota, index + 1))
  }, headerRow)
}

const formatSingleCodeAsCSV = (promoCode: PromoCode, invitationQuota: InvitationQuota, lineNumber: number) => {
  const url = invitationQuota.event?.slug ? shopUrl(invitationQuota.event.slug, promoCode.code) : null

  const items: (string | number | boolean | undefined | null)[] = [
    lineNumber,
    promoCode.code,
    `="${invitationQuota.event?.nameEn}"`, // hack to prevent Apple Numbers from converting "OMR25" to number
    invitationQuota.ticketCategory?.titleEn,
    url,
    promoCode.usable ? 'no' : 'yes',
    promoCode.userName,
    promoCode.companyName,
    `${invitationQuota.discount}%`,
  ]
  return arrayToCsvRow(items)
}

export { formatAsCSV }
