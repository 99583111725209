<template>
  <div class="py-6">
    <LabelValue :label="t('Reviews.product.view.screenshots.label').toString()" data-testid="product-screenshots">
      <div v-if="product.screenshots && product.screenshots.length > 0" class="grid grid-cols-3 gap-6">
        <AssetPreview
          v-for="(screenshot, index) in product.screenshots"
          :key="screenshot.url"
          :content-type="screenshot.contentType"
          :name="screenshot.name"
          :url="screenshot.url"
          :data-testid="`screenshot-${index}`"
        />
      </div>
      <span v-else>{{ t('Reviews.product.view.screenshots.missing') }}</span>
    </LabelValue>
    <LabelValue :label="t('Reviews.product.view.documents.label').toString()" data-testid="product-documents">
      <div v-if="productDocuments.length > 0" class="grid grid-cols-4 gap-6">
        <DocumentCard
          v-for="doc in productDocuments"
          :key="doc.url"
          :content-type="doc.contentType"
          :name="doc.name"
          :size="doc.size"
          :preview-url="doc.previewUrl || undefined"
          data-testid="document-card"
          @click="downloadFile(doc.url)"
        />
      </div>
      <span v-else>{{ t('Reviews.product.view.documents.missing') }}</span>
    </LabelValue>
  </div>
</template>

<script setup lang="ts">
import { DocumentCard } from '@ramp106/omrjs-reviews-ui'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import type { ProductData } from '@/gql/reviews'
import { ALLOWED_LOCALES, mapTranslationsArrToObj } from '@/helpers/translationsHelper'
import AssetPreview from '@/ui/AssetPreview.vue'
import LabelValue from '@/ui/LabelValue.vue'

const props = defineProps<{
  product: ProductData
}>()

const { t, locale } = useI18n()

const productDocuments = computed(() =>
  props.product.documents.map((doc) => {
    const nameTranslationsMap = mapTranslationsArrToObj(doc.nameTranslations)
    const name = nameTranslationsMap[locale.value] || nameTranslationsMap[ALLOWED_LOCALES[0]]
    return { ...doc, name }
  }),
)

function downloadFile(url: string) {
  window.open(url, '_blank')
}
</script>
