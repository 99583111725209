<template>
  <a :href="fullHref">
    <slot></slot>
  </a>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { authority } from '@/config/env'
const props = defineProps<{
  href: string
}>()

const cleanedHref = computed(() => props.href.replace(/^\/+/, ''))
const fullHref = computed(() => authority + cleanedHref.value)
</script>
