<template>
  <div>
    <AlertBox v-if="!canViewEvents" type="warning" class="mt-5">
      {{ t('PartnerCompany.noPartnerCompany') }}
    </AlertBox>
    <RouterView
      v-if="canViewEvents && result?.eventById"
      :event="result.eventById"
      :loading="loading"
      :attendee-csv-update-values="attendeeCsvUpdateValues"
      @update-csv-attendee-notification="handleCsvUpdate"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { useEventQuery } from '@/gql/myomr'
import { toGlobalId } from '@/helpers/globalIdHelper'
import { useRoleRedirect } from '@/services/roleRedirect'
import { $can } from '@/services/roles'

import { type UpdateCsvAttendeeValue } from '../components/EventApplications/AttendeeUpload.vue'

useRoleRedirect('viewEvents')

const { t } = useI18n()

const canViewEvents = computed(() => $can('viewEvents', { for: 'User' }))

const route = useRoute()
const { result, loading } = useEventQuery({ id: toGlobalId('Event', route.params.eventId as string) })

const attendeeCsvUpdateValues = ref<null | UpdateCsvAttendeeValue>(null)

function handleCsvUpdate(data: UpdateCsvAttendeeValue) {
  attendeeCsvUpdateValues.value = data
}
</script>
