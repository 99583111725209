import { ApolloClient } from '@apollo/client/core'

import { apolloClientOptions } from './apolloClientOptions'
import localeLink from './localeLink'
import myomrErrorLink from './myomrErrorLink'
import myomrHttpLink from './myomrHttpLink'
import partnerCompanyLink from './partnerCompanyLink'
import retryLink from './retryLink'

export const myomrApolloClient = new ApolloClient({
  ...apolloClientOptions,
  link: localeLink.concat(partnerCompanyLink).concat(retryLink).concat(myomrErrorLink).concat(myomrHttpLink),
  devtools: {
    enabled: process.env.NODE_ENV === 'development',
  },
})
