<template>
  <ConfirmModal
    v-if="showConfirmationModal"
    wrapper-class="max-w-4xl"
    :title="t('Reviews.reviewReply.delete.confirmModal.title')"
    :description="t('Reviews.reviewReply.delete.confirmModal.description')"
    @close-dialog="emit('canceled')"
  >
    <template #actions>
      <div class="flex justify-end mt-6 px-6 pb-6">
        <button class="btn-secondary-purple-m ml-4" data-test-id="accept-button" @click="emit('canceled')">
          {{ t('Reviews.reviewReply.delete.confirmModal.abort') }}
        </button>
        <button class="btn-primary-purple-m ml-4" @click="emit('confirmed')">
          {{ t('Reviews.reviewReply.delete.confirmModal.confirm') }}
        </button>
      </div>
    </template>
  </ConfirmModal>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { ConfirmModal } from '@/components'

defineProps<{
  showConfirmationModal: boolean
}>()

const emit = defineEmits(['canceled', 'confirmed'])

const { t } = useI18n()
</script>
