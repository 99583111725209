<template>
  <TableLight class="mt-6 w-full border-b border-grey-300">
    <thead>
      <tr class="text-label-s font-bold text-grey-700 border-b border-grey-300">
        <th class="py-4 text-start">
          <div class="flex items-center">
            <SemiCheckBox
              :disabled="!props.tickets?.length"
              :checked="allSelected"
              :use-mid-state="partiallySelected"
              class="text-grey-800"
              data-test-id="select-all-checkbox"
              @change="toggleAllSelected"
            />
          </div>
        </th>
        <th class="py-4 text-start">{{ t('email') }}</th>
        <th class="py-4 text-start">{{ t('firstName') }}</th>
        <th class="py-4 text-start">{{ t('lastName') }}</th>
        <th class="py-4 text-start">{{ t('company') }}</th>
        <th v-if="props.showStatus" class="py-4 text-start flex align-middle mt-[2px]">
          <span class="mt-[1px]">{{ t('status') }}</span
          ><SvgIcon class="ml-1 cursor-pointer" :path="mdiRefresh" :size="20" @click="emit('refetch', true)" />
        </th>
        <th class="py-4 text-start">{{ t('createdAt') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="!props.tickets?.length">
        <td colspan="9" class="text-center text-grey-700 py-6">{{ t('EmailInvitationQuota.noDataText') }}</td>
      </tr>
      <template v-for="ticket in props.tickets" :key="`ticket_${ticket?.id}`">
        <InternalQuotaTicketsTableRow
          :selected-ticket-ids="props.selectedTicketIds"
          :show-status="props.showStatus"
          :ticket="ticket"
          @toggle-ticket-checked="toggleTicketChecked"
        />
      </template>
    </tbody>
  </TableLight>
</template>

<script setup lang="ts">
import { mdiRefresh } from '@mdi/js'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { type TicketConnection } from '@/gql/myomr'

import type { TabEnum } from './activeTab'

const { t } = useI18n()

const props = defineProps<{
  currentActiveTab: TabEnum
  showStatus: boolean
  selectedTicketIds: string[]
  tickets?: TicketConnection['nodes']
}>()

const emit = defineEmits<{
  (e: 'changeTicketSelection', value: string[]): void
  (e: 'openHintModal'): void
  (e: 'refetch', value: boolean): void
}>()

const selectableTickets = computed(() => (props.tickets?.length ? props.tickets : []))

const allSelected = computed(() => !!(props.selectedTicketIds.length && props.selectedTicketIds.length === selectableTickets.value.length))

const partiallySelected = computed(() => !!(props.selectedTicketIds.length && !allSelected.value))

function toggleTicketChecked(id: string) {
  let newSelection: string[]
  if (props.selectedTicketIds.includes(id)) {
    newSelection = props.selectedTicketIds.filter((e) => e !== id)
  } else {
    newSelection = props.selectedTicketIds.concat(id)
  }

  emit('changeTicketSelection', newSelection)
}

function toggleAllSelected() {
  const newSelection = props.selectedTicketIds.length
    ? []
    : selectableTickets.value.map((ticket) => {
        return ticket.id
      })
  emit('changeTicketSelection', newSelection)
}
</script>
