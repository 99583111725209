import { onError } from '@apollo/client/link/error'

import errorHandler from '@/helpers/errorHandlerUtility'

const link = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      errorHandler.report(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    })
  }

  if (networkError) {
    errorHandler.report(networkError.message)
  }
})

export default link
