<template>
  <component :is="icon" />
</template>
<script setup lang="ts">
import { computed, type Component } from 'vue'

import { ReminderIcon, ReminderCheckIcon, ReplyOutdated, ReplyIcon, ReplyCheckIcon } from '@/components/ReviewsManagement/Reviews/icons'
import { ReviewInteractionType } from '@/components/ReviewsManagement/Reviews/types'

const props = defineProps<{
  type: ReviewInteractionType
}>()

const icon = computed<Component | null>(() => {
  switch (props.type) {
    case ReviewInteractionType.REMINDER:
      return ReminderIcon
    case ReviewInteractionType.REMINDER_CHECK:
      return ReminderCheckIcon
    case ReviewInteractionType.REPLY:
      return ReplyIcon
    case ReviewInteractionType.REPLY_CHECK:
      return ReplyCheckIcon
    case ReviewInteractionType.REPLY_OUTDATED:
      return ReplyOutdated
    default:
      return null
  }
})
</script>
