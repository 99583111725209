<template>
  <SideBarSubMenu :label="t('SideBar.reviews.root')" :to="{ name: 'reviewsProducts' }">
    <SideBarSubMenuItem :to="{ name: 'reviewsProducts' }" data-testid="product-reviews-overview">
      {{ t('SideBar.reviews.toolOverview') }}
    </SideBarSubMenuItem>
    <SideBarSubMenuItem
      v-for="productRoute in productRoutes"
      :key="productRoute.label"
      :to="{ name: productRoute.routeName, params: productRoute.routeParams }"
      :data-testid="'product-reviews-' + productRoute.routeParams.productId"
    >
      {{ productRoute.label }}
    </SideBarSubMenuItem>
    <SideBarSubMenuItem :to="buyerIntentDataUrl">
      <span class="pr-1">{{ t('SideBar.reviews.buyerIntentData') }}</span>
      <SvgIcon :path="mdiArrowTopRight" :size="16" />
    </SideBarSubMenuItem>
  </SideBarSubMenu>
</template>

<script setup lang="ts">
import { mdiArrowTopRight } from '@mdi/js'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { buyerIntentDataUrl } from '@/config/env'
import { useReviewsProductsQuery } from '@/gql/reviews'
import { ReviewsRouteName } from '@/router/types'

const { t } = useI18n()
const { result } = useReviewsProductsQuery({ clientId: 'reviews' })

const productRoutes = computed(() => {
  if (result.value?.manage?.products) {
    return result.value.manage.products.map((product) => ({
      label: product.title,
      routeName: ReviewsRouteName.ReviewsProductView,
      routeParams: { productId: product.id },
    }))
  }

  return []
})
</script>
