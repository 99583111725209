<template>
  <div class="flex flex-col items-center">
    <div class="flex justify-between pt-16 pb-4 w-80">
      <p class="text-body-m-bold text-grey-800">{{ t('Reviews.product.edit.pricing.preview.title') }}</p>
      <ChipBadge class="uppercase" rounded>{{ lang }}</ChipBadge>
    </div>
    <PricingPlanCard class="w-80 h-fit bg-white" :pricing-plan="pricingPlan" />
  </div>
</template>
<script setup lang="ts">
import { ChipBadge } from '@ramp106/omrjs-core-ui'
import { PricingPlanCard, type PricingPlanCardTypes } from '@ramp106/omrjs-reviews-ui'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { translatedOptionName } from '@/components/ReviewsManagement/Pricing/translatedOptionName'
import { type Currency } from '@/gql/reviews'
import { ALLOWED_LOCALES } from '@/helpers/translationsHelper'

import type { TranslatedOption } from './types'
import type { PricingPlanFormData } from '../types'

const props = defineProps<{
  lang: string
  previewName: string
  pricing: PricingPlanFormData
  pricingTextOptions: TranslatedOption[]
  subscriptionModes: TranslatedOption[]
  currenciesOptions: Currency[]
}>()

const { t } = useI18n()
const fallbackLang = ALLOWED_LOCALES[0]

const pricingTextValue = computed(() => {
  if (props.pricing.pricingTextOptionId) {
    const option = props.pricingTextOptions.find((option) => option.id === props.pricing.pricingTextOptionId)

    return option ? translatedOptionName(option, props.lang) : undefined
  }

  return undefined
})

const billingPeriod = computed(() => {
  if (props.pricing.pricingSubscriptionModeId) {
    const option = props.subscriptionModes.find((mode) => mode.id === props.pricing.pricingSubscriptionModeId)

    return option ? translatedOptionName(option, props.lang) : undefined
  }

  return undefined
})

const priceCurrencySymbol = computed(() => {
  if (props.pricing.priceCurrency) {
    return props.currenciesOptions.find((currency) => currency.code === props.pricing.priceCurrency)?.symbol
  }

  return undefined
})

const perks = computed<string[]>(() => {
  return props.pricing.perkList.map((perk) => perk[props.lang].name || perk[fallbackLang].name).filter(Boolean)
})

const pricingPlan = computed<PricingPlanCardTypes.PricingPlan>(() => {
  return {
    name: props.pricing.name[props.lang] || props.pricing.name[fallbackLang] || props.previewName,
    description: props.pricing.description[props.lang] || props.pricing.description[fallbackLang] || undefined,
    priceCents: props.pricing.priceCents || undefined,
    startingPrice: props.pricing.startingPrice,
    pricingTextValue: pricingTextValue.value,
    billingPeriod: billingPeriod.value,
    priceCurrencySymbol: priceCurrencySymbol.value,
    perks: perks.value,
  }
})
</script>
