<template>
  <div>
    <div class="text-headline-xs mb-4">{{ t('Reviews.product.review.history.title') }}</div>
    <ReminderItem v-for="reminder in reminders" :key="reminder.createdAt" :author-name="authorName" class="mb-4" :reminder="reminder" />
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import ReminderItem from './ReminderItem.vue'
import type { SurveyResponseReminder } from './types'

defineProps<{
  authorName?: string
  reminders: SurveyResponseReminder[]
}>()

const { t } = useI18n()
</script>
