<template>
  <div class="flex justify-between items-center pt-3 pb-3 border-b border-grey-200">
    <div>
      <div v-if="props.showChip" class="chip mb-2" :class="getBgColor(event.type)">{{ event.type }}</div>
      <div v-else class="mb-4"></div>
      <h2 class="mb-4">{{ eventName }}</h2>

      <div class="flex space-x-4">
        <div v-if="event.location" class="inline-flex items-center text-label-m pr-2">
          <SvgIcon :path="mdiMapMarkerOutline" :size="24" class="opacity-70" />
          <span>{{ event.location.name }}</span>
        </div>
        <div v-if="event.startsAt && event.endsAt" class="inline-flex items-center text-label-m pr-2">
          <SvgIcon :path="mdiCalendar" :size="24" class="opacity-70" />
          <span>{{ getDateFormat(new Date(event.startsAt), new Date(event.endsAt)) }}</span>
        </div>
        <div v-if="event.startsAt && event.endsAt" class="inline-flex items-center text-label-m pr-2">
          <SvgIcon :path="mdiAlarm" :size="24" class="opacity-70" />
          <span>
            {{ toGermanTime(event.startsAt) }} -
            {{ toGermanTime(event.endsAt) }}
          </span>
        </div>
        <div v-if="event.language" class="mt-1 text-label-m pr-2">
          <flag :iso="eventLanguageCode" :squared="false" />
          {{ languageLabel }}
        </div>
        <slot name="additionalEventProperties"></slot>
        <div v-if="event && event.livestreamUrlWithToken && event.type == 'DigitalMasterclass'" class="text-label-m pr-2">
          <a class="text-decoration-none text-black" target="_blank" :href="event.livestreamUrlWithToken"
            ><SvgIcon :path="mdiEarth" class="opacity-60" :size="22"
          /></a>
        </div>
      </div>
    </div>
    <div class="flex justify-end">
      <slot v-if="hasCustomButton" name="customButton"> </slot>
      <DropDownSelect v-if="!hasCustomButton && canManageListing && !isMainEvent && showDropdown" direction="right">
        <template #activator="{ open }">
          <button class="flex p-2 rounded items-center" data-test-id="test-dropdown" @click.prevent="open">
            <SvgIcon :path="mdiDotsVertical" :size="24" />
          </button>
        </template>
        <template #default>
          <ul class="list-none p-0 m-0 min-w-[150px]">
            <li v-if="canManageListing && !isMainEvent" class="text-left py-2 px-2 cursor-pointer hover:bg-grey-100" @click="routeToEdit()">
              {{ t('editButtonLabel') }}
            </li>
            <li v-if="showAttendeeButtons" class="text-left py-2 px-2 cursor-pointer hover:bg-grey-100" @click="routeToApplicants()">
              {{ t('Applicants.applicantCTA') }}
            </li>
            <li
              v-if="hasInvitationQuotas"
              class="text-left py-2 px-2 cursor-pointer hover:bg-grey-100"
              @click.stop="routeToInvitationQuotas()"
            >
              {{ t('EventHeader.inviteGuestsCTA') }}
            </li>
          </ul>
        </template>
      </DropDownSelect>
      <button v-if="hasInvitationQuotas && props.showMainButton" class="btn-primary-purple-m" @click="routeToInvitationQuotas()">
        {{ t('EventHeader.inviteGuestsCTA') }}
      </button>
    </div>
    <ConfirmModal
      v-if="eventCloseConfirmationVisible"
      :title="t('EventCloseConfirmation.headline')"
      :description="t('EventCloseConfirmation.body')"
      wrapper-class="max-w-4xl"
      @close-dialog="handleCloseModal"
    />
  </div>
</template>

<script lang="ts">
export default {
  name: 'EventItem',
  inheritAttrs: false,
}
</script>

<script setup lang="ts">
import { mdiMapMarkerOutline, mdiCalendar, mdiAlarm, mdiDotsVertical, mdiEarth } from '@mdi/js'
import { computed, ref, useSlots } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import type { EventOverviewFragment } from '@/gql/myomr'
import { useCloseAttendeeSelectionMutation, EventTypeEnum } from '@/gql/myomr'
import { formatDateSpan } from '@/helpers/formatDateSpan'
import { toTypeAndId } from '@/helpers/globalIdHelper'
import { languageCode } from '@/helpers/languageCode'
import { getLocalizedAttribute } from '@/helpers/localizedAttribute'
import { $can } from '@/services/roles'

const i18n = useI18n()
const t = i18n.t
const router = useRouter()

const props = defineProps<{
  event: EventOverviewFragment
  showChip: boolean
  showMainButton: boolean
  showDropdown: boolean
}>()

const slots = useSlots()

const eventCloseConfirmationVisible = ref(false)

const { mutate } = useCloseAttendeeSelectionMutation()

const hasCustomButton = computed(() => {
  return !!slots['customButton']
})

function closeSelection() {
  mutate({ eventId: props.event.id })
}

const isMainEvent = computed(() => props.event.type === 'Event')

function handleCloseModal(accepted: boolean) {
  if (!accepted) return (eventCloseConfirmationVisible.value = false)
  closeSelection()
}

function routeToEdit() {
  router.push({
    name: 'eventEdit',
    params: { eventId: plainEventId.value },
  })
}

function routeToApplicants() {
  router.push({
    name: 'EventAttendees',
    params: { eventId: plainEventId.value },
  })
}

function getBgColor(eventType: EventTypeEnum | null | undefined): string {
  switch (eventType) {
    case EventTypeEnum.Event:
      return 'bg-victoria'
    case EventTypeEnum.BreakEvent:
      return 'bg-victoria'
    case EventTypeEnum.StageSlot:
      return 'bg-tailwind'
    case EventTypeEnum.Masterclass:
      return 'bg-black'
    case EventTypeEnum.SideEvent:
      return 'bg-ripe-lemon'
    case EventTypeEnum.GuidedTour:
      return 'bg-amaranth'
    default:
      return 'bg-black'
  }
}

const showAttendeeButtons = computed(() => {
  const validEventTypes: EventTypeEnum[] = [
    EventTypeEnum.Masterclass,
    EventTypeEnum.SideEvent,
    EventTypeEnum.GuidedTour,
    EventTypeEnum.DigitalMasterclass,
  ]
  return validEventTypes.includes(props.event.type as EventTypeEnum)
})

function getDateFormat(beginningDate: Date, endDate: Date): string {
  return formatDateSpan({ beginning: beginningDate, end: endDate, monthNames: true })
}

function routeToInvitationQuotas() {
  router.push({
    name: 'invitations',
    params: { eventId: plainEventId.value },
  })
}

function toGermanTime(date: string) {
  return new Date(date).toLocaleTimeString('de-DE', { timeZone: 'Europe/Berlin' }).slice(0, -3)
}

const canManageListing = computed(() => $can('manage', { for: props.event }))
const plainEventId = computed(() => (props.event && toTypeAndId(props.event.id)['id']) || '')
const hasInvitationQuotas = computed(() => !!props.event.invitationQuotasCount)
const eventName = computed(() =>
  getLocalizedAttribute({
    de: props.event.nameDe,
    en: props.event.nameEn,
    fallback: String(t('EventItem.emptyTitle', { eventType: props.event.type })),
    locale: i18n.locale.value,
  }),
)

const eventLanguageCode = computed(() => {
  if (props.event.language) {
    return languageCode(props.event.language)
  } else {
    return ''
  }
})

const languageLabel = computed(() => t(eventLanguageCode.value))
</script>

<style scoped>
.small-chip {
  padding: 0px 10px !important;
  height: 15px;
}

/* Stylizing tag text colors */
.purple,
.black {
  color: #fff !important;
}

.text-decoration-none {
  text-decoration: none !important;
}
</style>
