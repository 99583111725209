<template>
  <div>
    <BackLink class="mt-4 mb-2" :to="{ name: 'tickets' }">{{ t('backToOverview') }}</BackLink>

    <ContentCard>
      <template #header>
        <EventItem :event="props.event" :show-main-button="false" :show-chip="false" :show-dropdown="false" />
      </template>

      <div v-for="quota in props.event.invitationQuotas" :key="quota.id" :quota="quota" class="separate-rows">
        <InviteQuotaItem :quota="quota" />
      </div>
    </ContentCard>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import type { EventQuery } from '@/gql/myomr'
import BackLink from '@/ui/BackLink.vue'

import { default as EventItem } from './EventItem.vue'
import { default as InviteQuotaItem } from './InviteQuotaItem.vue'

const { t } = useI18n()

const props = defineProps<{
  event: EventQuery['eventById']
}>()
</script>
