<template>
  <ConfirmModal
    persist
    wrapper-class="w-full max-w-xl"
    :approve="t('ProspectsCatalog.sendTestEmailModal.approveButton')"
    @close-dialog="submitForm"
  >
    <template #title>
      <h1 class="text-headline-m p-6 pb-5">{{ t('ProspectsCatalog.sendTestEmailModal.title') }}</h1>
    </template>
    <template #content>
      <div class="px-6">
        <p>
          {{ t('ProspectsCatalog.sendTestEmailModal.description') }}
        </p>
        <TextInput
          v-model="formData.email"
          :label="t('ProspectsCatalog.sendTestEmailModal.emailLabel')"
          :error="getValidationErrors($validations.email)"
          class="mt-4"
        />
      </div>
    </template>
  </ConfirmModal>
</template>
<script setup lang="ts">
import { TextInput } from '@ramp106/omrjs-core-ui'
import { useVuelidate } from '@vuelidate/core'
import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter, useRoute } from 'vue-router'

import { useSendTestProspectInvitationMutation } from '@/gql/myomr'
import { toGlobalId } from '@/helpers/globalIdHelper'
import { showNotification } from '@/helpers/notificationHelper'
import { getValidationErrors } from '@/helpers/validationHelper'
import { required, email } from '@/services/validators'

const { mutate } = useSendTestProspectInvitationMutation()

const { t } = useI18n()

const router = useRouter()
const route = useRoute()

const formData = reactive({
  email: '',
})
const formRules = {
  email: { required, email },
}

const $validations = useVuelidate(formRules, formData)

async function closeModal() {
  router.push({ name: 'prospectList', params: { listId: route.params.listId } })
}

async function submitForm(shouldSubmit: boolean) {
  if (!shouldSubmit) return closeModal()

  const validForm = await $validations.value.$validate()
  if (!validForm) return

  try {
    const result = await mutate({
      prospectListId: toGlobalId('ProspectList', route.params.listId as string),
      email: formData.email,
    })

    if (result?.data?.sendTestProspectInvitation?.success) {
      showNotification(t('ProspectsCatalog.sendTestEmailModal.successNotification', { email: formData.email }), 'success', 3000)
    } else {
      showNotification(t('ProspectsCatalog.sendTestEmailModal.errorNotification'), 'error', 3000)
    }
  } catch (_e) {
    showNotification(t('ProspectsCatalog.sendTestEmailModal.errorNotification'), 'error', 3000)
  } finally {
    closeModal()
  }
}
</script>
